<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据管理</el-breadcrumb-item>
        <el-breadcrumb-item>期初数据管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="8">
            <el-button type="success" @click="toAddInitialData()" style="margin-right: 10px;">
              <el-icon><Plus /></el-icon><span class="icon-right">新建</span>
            </el-button>
            <el-dropdown>
              <el-button type="primary" >
                <i class="iconfont icon-daoru" style="font-size: 14px"></i><span>导入</span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-link :href="href" target="_blank" type="primary" :underline="false" >
                      <el-icon><Download /></el-icon>下载模板
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-upload
                        ref="uploadRef"
                        :action="uploadAction"
                        name="uploadfile"
                        :before-upload="beforeUpload"
                        :on-progress="uploadProgress"
                        :on-success="uploadSuccess"
                        :file-list="fileList"
                        :show-file-list="false"
                        :with-credentials="true"
                        :disabled="showProgress">
                      <div>
                        <el-tooltip effect="light" content="只能上传Excel(xls/xlsx)文件，且不超过100MB" placement="right">
                          <el-link target="_blank" type="warning" :underline="false" >
                            <el-icon><Upload /></el-icon>导入数据
                          </el-link>
                        </el-tooltip>
                      </div>
                    </el-upload>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button type="primary" @click="doExport" :loading="exportTip !== ''" style="margin-left: 10px;">
              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-select v-model="search.subjectType" placeholder="请选择科目类别" @change="loadInitialData" clearable>
              <el-option
                  v-for="item in subjectTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择科目"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                @change="loadInitialData($event)"
                :options="treeData" >
            </el-cascader>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-input v-model="search.subjectDetail" placeholder="请输入明细科目" @keyup.enter="loadInitialData" @clear="loadInitialData" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadInitialData">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="light" content="全部删除" placement="top">
              <el-link :underline="false" @click="toDelAll()">
                <i class="iconfont icon-quanbushanchu" style="font-size: 20px; color: red"></i>
              </el-link>
            </el-tooltip>
            <el-tooltip effect="light" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <div style="line-height: 20px; margin-top: 5px">
          <div v-if="elProgress" style="display: flex;flex-direction: row; align-items: center">
            <div style="width: 80px">
              <span style="font-size: 12px;">{{ progressInfo.text }}</span>
            </div>
            <div style="width: 90%">
              <el-progress :percentage="progressPercent"/>
            </div>
          </div>
          <div v-if="exportTip !== ''">
            <span style="font-size: 12px; margin-left: 80px">{{ exportTip }}</span>
          </div>
        </div>
        <div>
          <el-table
            :height="tableHeight"
            :data="InitialList"
            v-loading="loading">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
          <el-table-column
              prop="ABSTRACT"
              label="摘要"
              show-overflow-tooltip
              align="center"
              min-width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_NAME"
              label="会计科目"
              show-overflow-tooltip
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_DETAIL"
              label="明细科目"
              show-overflow-tooltip
              align="center"
              min-width="120px"
          >
          </el-table-column>
          <el-table-column
              prop="MONEY"
              label="金额"
              show-overflow-tooltip
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_TYPE_NAME"
              label="科目类别"
              show-overflow-tooltip
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="NUMBER"
              label="数量"
              show-overflow-tooltip
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="UNIT_PRICE"
              label="单价"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="UNIT_NAME"
              label="单位"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="USE_TYPE_NAME"
              label="规格名称"
              show-overflow-tooltip
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="ENABLE_TIME"
              label="启用时间"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              min-width="130px"
              align="center">
            <template #default="scope">
              <el-button class="table-button"
                         size="small"
                         type="primary"
                         @click="toEdit(scope.row)">编辑</el-button>
              <el-button class="table-button"
                         size="small"
                         type="danger"
                         @click="toDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        </div>
        <el-pagination
            background
            v-model:current-page="search.page"
            v-model:page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadInitialData"
            @size-change="loadInitialData"
            :page-sizes="[20, 50, 100, 200]"
            :total="total"
            style="margin-top: 10px;"
        >
        </el-pagination>

        <el-dialog
            width="1000px"
            :title="initialDataTitle"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <div style="height: calc(100% - 100px); overflow: auto;">
            <el-form
                :inline="true"
                :model="initialData"
                :rules="rules"
                ref="initialDataForm"
                label-width="130px"
            >
              <el-form-item label="摘要:" prop="abstract" class="form-col">
                <el-input v-model="initialData.abstract" />
              </el-form-item>
              <el-form-item label="会计科目" prop="subjectSort" class="form-col">
                <el-cascader
                    placeholder="请选择科目"
                    style="width: 100%;"
                    v-model="initialData.subjectSort"
                    :props="props"
                    clearable
                    :disabled="disabled"
                    :options="treeData" >
                </el-cascader>
              </el-form-item>
              <el-form-item label="科目明细:" prop="subjectDetail" class="form-col">
                <el-input v-model="initialData.subjectDetail" :disabled="disabled"/>
              </el-form-item>
              <el-form-item label="金额:" prop="money" class="form-col">
                <el-input v-model="initialData.money" :precision="2"/>
              </el-form-item>
              <el-form-item label="数量:" prop="number" class="form-col">
                <el-input v-model="initialData.number" />
              </el-form-item>
              <el-form-item label="单价:" prop="unitPrice" class="form-col" >
                <el-input v-model="initialData.unitPrice" />
              </el-form-item>
              <el-form-item label="单位:" prop="unit" class="form-col" >
                <el-select v-model="initialData.unit" placeholder="请选择单位">
                  <el-option
                      v-for="item in UnitData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="规格名称:" prop="useType" class="form-col" >
                <el-select v-model="initialData.useType" placeholder="请选择规格名称" :disabled="disabled">
                  <el-option
                      v-for="item in specsTypeData"
                      :key="item.CODE"
                      :label="item.CODE_NAME"
                      :value="item.CODE">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="启用时间:" prop="enableTime" class="form-col" >
                <el-date-picker
                    v-model="initialData.enableTime"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="选择启用时间"
                    style="width: 100%"
                />
              </el-form-item>
            </el-form>
            <div class="div-button">
              <el-button type="primary" @click="toSave">保 存</el-button>
            </div>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "InitialDataMge",
  data () {
    return {
      href: apiURL + "/api/initial/downtowntemplate",
      uploadAction: apiURL +"/api/initial/importinitialdata",
      tableHeight: 0,
      search: {
        subjectType:"",
        subjectDetail: "",
        subjectSort: "",
        page: 1,
        pagesize: 20,
      },
      initialDataTitle: "",
      dailVisi: false,
      InitialList: [],
      total: 0,
      treeData:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      specsTypeData:[],
      initialData: {
        initialdataid: "",
        abstract: "",
        subjectSort: "",
        subjectDetail: "",
        money: "",
        number: "",
        unitPrice: "",
        unit: "",
        useType: "",
        enableTime:"",
      },
      fileList: [],
      showProgress: false,
      UnitData:[],
      progressPercent: 0,
      elProgress: false,
      progressInfo: {
        state: "1",
        text: "",
      },
      exportTip: "",
      subjectTypeData: [],
      disabled:false,
      loading:false,
      rules: {
        abstract: [{required: true, message: "请填写摘要", trigger: "blur"}],
        subjectSort: [{required: true, message: "请选择会计科目", trigger: "blur"}],
        subjectDetail: [{required: true, message: "请填写科目明细", trigger: "blur"}],
        money: [{required: true, message: "请填写金额", trigger: "blur"}],
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.loadSubjectData()
      this.loadInitialData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: ["UNIT", "SUBJECT_TYPE",  "SPECS_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.UnitData = data.result.UNIT
              this.subjectTypeData = data.result.SUBJECT_TYPE
              this.specsTypeData = data.result.SPECS_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
    },
    loadSubjectData() {
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })

    },
    toRefresh() {
      this.search.subjectDetail = ""
      this.search.subjectSort = ""
      this.loadInitialData("refresh")
    },
    loadInitialData(d) {
      this.loading = true
      this.$axios.post("/api/initial/loadInitialData", this.search)
          .then((response) => {
            if (response.data.success) {
              this.loading = false
              this.total = response.data.result.totalrecords
              this.InitialList = response.data.result.datalist
              if(d === "refresh"){
                this.$message({message: "刷新成功", type: "success"})
              }
              if(d === "upload"){
                this.$message({message: "上传成功", type: "success"})
              }
            }
            else {
              this.$alert(response.data.result, "获取期初数据列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取期初数据列表出错")
          })
    },
    toReset() {
      this.search.subjectDetail = ""
      this.search.subjectName = ""
      this.loadInitialData()
    },

    toAddInitialData () {
      this.initialDataTitle = "期初数据新增"
      this.dailVisi = true
      this.disabled = false
      this.initialData.initialdataid = ""
      this.initialData.abstract = "期初余额结转"
      this.initialData.subjectSort = ""
      this.initialData.subjectDetail = ""
      this.initialData.money = ""
      this.initialData.number = ""
      this.initialData.unitPrice = ""
      this.initialData.unit = ""
      this.initialData.useType = ""
      var date = new Date()
      this.initialData.enableTime = date.getFullYear() + "-01" + "-01";
      if (this.$refs.initialDataForm)
        this.$refs.initialDataForm.clearValidate()
    },

    toEdit (data){
      this.disabled = true
      this.initialData.initialdataid = data.INITIAL_DATA_ID
      this.initialData.abstract = data.ABSTRACT
      this.initialData.subjectSort = data.SUBJECT_SORT.toString()
      this.initialData.subjectDetail = data.SUBJECT_DETAIL
      this.initialData.money = data.MONEY
      this.initialData.number = data.NUMBER
      this.initialData.unitPrice = data.UNIT_PRICE
      this.initialData.unit = data.UNIT
      this.initialData.useType = data.USE_TYPE
      this.initialData.enableTime = data.ENABLE_TIME
      this.initialDataTitle = "期初数据编辑"
      this.dailVisi = true
      if (this.$refs.initialDataForm)
        this.$refs.initialDataForm.clearValidate()
    },

    toSave() {
      this.$refs["initialDataForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/initial/saveinitialdata", this.initialData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "期初数据保存成功", type: "success"})
                  this.dailVisi = false
                  this.loadInitialData()
                } else {
                  this.$alert(response.data.result, "期初数据保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "期初数据保存出错")
              })
        }
        else
          return false
      })
    },
    //删除
    toDel (data) {
      this.$confirm("确认删除此期初数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true
        this.$axios.post("/api/initial/delinitialdata", data)
            .then((response) => {
              if (response.data.success) {
                this.loading = false
                this.$message({message: "删除成功", type: "success"})
                this.loadInitialData()

              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
      })
    },
    toDelAll () {
      this.$confirm("注意:确认需要删除全部期初数据码?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.loading = true
        this.$axios.post("/api/initial/delallinitialdata")
            .then((response) => {
              if (response.data.success) {
                this.loading = false
                this.$message({message: "删除全部成功", type: "success"})
                this.loadInitialData()
              }
              else {
                this.$alert(response.data.result, "删除全部出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除全部出错")
            })
      })
    },
    //上传前判断是否超过大小限制
    beforeUpload (file) {
      const isOut = file.size / 1024 / 1024 < 100
      if (!isOut) {
        this.$message.error("所选文件大小超过了100MB")
        return false
      }
      const suffix = file.name.indexOf(".") > -1 ? file.name.substring(file.name.lastIndexOf(".")).toLowerCase() : ""
      if (suffix !== ".xls" && suffix !== ".xlsx") {
        this.$message.error("只能上传Excel文件")
        return false
      }
      this.showProgress = true
      this.progressPercent = 0
      this.elProgress = true
      this.timer = setInterval(this.getProgress, 1500)
    },
    //显示上传进度
    uploadProgress (event) {
      console.log(event)
    },

    //上传成功
    uploadSuccess (res) {
      if (res.success) {
        this.progressPercent = 100
        this.fileList = []
        this.loadInitialData("upload")
      }else {
        if (typeof res.result === "string")
          this.$alert(res.result, "上传期初数据出错")
        else {
          var _this = this
          res.result.forEach( item =>{
            _this.$message({message: item, type: "error", showClose: true, duration:8000, position: "bottom-right"})
          })
        }
      }
      clearInterval(this.timer)
      this.elProgress = false
      this.showProgress = false
    },

    //定时获取进度
    getProgress () {
      this.$axios.get("/api/initial/getimportprogress")
          .then((response) => {
            const data = response.data
            if (data.success) {
              if (data.result.state != null) {
                this.progressPercent = data.result.cur
                this.progressInfo.state = data.result.state
                this.progressInfo.text = data.result.state === "2" ? "导入完成" : data.result.msg
                if (data.result.state === "2") {
                  if (this.timer != null)
                    clearInterval(this.timer)
                  this.showProgress = false
                  this.progressPercent = 0
                  this.progressInfo.text = ""
                }
              }
            }
            else {
              this.$alert(data.result, "获取导入进度出错")
              if (this.timer != null)
                clearInterval(this.timer)
              this.showProgress = false
              this.progressInfo.state = ""
              this.progressInfo.text = ""
            }
          })
          .catch((error) => {
            this.$alert(error, "获取导入进度出错")
            this.showProgress = false
            this.progressInfo.state = ""
            this.progressInfo.text = ""
          })
    },

    //导出
    doExport () {
      this.exportTip = "期初数据导出Excel中..."
      this.$axios.post("/api/initial/exportinitialdata", this.search, {responseType: "blob"})
          .then((response) => {
            this.exportTip = ""
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "期初数据导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "期初数据导出出错")
          })
    },
  }
}
</script>

<style scoped>

</style>
