<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计报表</el-breadcrumb-item>
        <el-breadcrumb-item>资产负债表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row style="margin-bottom: 10px">
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadData()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="7">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <i class="iconfont icon-daochu" style="font-size: 15px"></i><span style="margin-right: 5px">导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="10" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <div style="display: flex; flex-direction: row ">
          <div style="width: 50%;  overflow:hidden;">
            <el-table
                :height="tableHeight"
                :data="leftData"
                :summary-method="getSummaries"
                show-summary
                border
                :cell-style="cellStyleMethod">
              <el-table-column
                  prop="zc"
                  label="资产"
                  show-overflow-tooltip
                  align="center"
                  min-width="120px"
              >
              </el-table-column>
              <el-table-column
                  prop="hc"
                  label="行次"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="qm"
                  label="期末数"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  {{ formatNumber(scope.row.qm) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="nc"
                  label="年初数"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  {{ formatNumber(Math.abs(scope.row.nc)) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="width: 50%;  overflow:hidden;">
            <el-table
                :height="tableHeight"
                :data="rightData"
                :summary-method="getSummaries1"
                show-summary
                border
                :cell-style="cellStyleMethod">
              <el-table-column
                  prop="zc"
                  label="负债和所有者（或股东）权益"
                  show-overflow-tooltip
                  align="center"
                  min-width="120px"
              >
              </el-table-column>
              <el-table-column
                  prop="hc"
                  label="行次"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>

              <el-table-column
                  prop="qm"
                  label="期末数"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  {{ formatNumber(scope.row.qm) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="nc"
                  label="年初数"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  {{ formatNumber(scope.row.nc) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "balanceReport",
  data () {
    return {
      tableHeight: 0,
      search: {
        recordMonth: "",
        organOrDep: "",
        s_organid: "",
        s_depid: "",
      },
      exportTip: "",
      OrganTreeData: [],
      organprops: {
        label: 'text', value: 'value', children: 'children',
        checkStrictly: true,
        emitPath: false,
      },
      leftData:[],
      rightData:[],
      zczj: 0,
      fzzj: 0,
      pdffile:"",
      drawerVisi:false,
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 90
    },
    toRefresh() {
      this.loadData("refresh")
    },
    formatNumber(value) {
      if(value === 0){
        return ""
      }else{
        if (value !== "" && value !== null)
          return new Intl.NumberFormat().format(value);
      }
    },
    loadCode() {
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/balance/loadsummarydata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.leftData = response.data.result.leftData
              this.rightData = response.data.result.rightData
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取资产负债数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取资产负债数据出错")
          })
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) { // 只找第一列放合计
          sums[index] = '资产总计：'
          return
        }
        if (column.property === 'qm') {
          const values = data.map(item => {
                if(item["zc"].includes("合计")){
                  return  item[column.property]
                }
            }
          )
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return Number(prev) + Number(curr)
            } else {
              return Number(prev)
            }
          }, 0)
          sums[index] = this.formatNumber(sums[index]) +"元"
        }
        if (column.property === 'nc') {
          const values = data.map(item => {
                if(item["zc"].includes("合计")){
                  return  item[column.property]
                }
              }
          )
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return Number(prev) + Number(curr)
            } else {
              return Number(prev)
            }
          }, 0)
          sums[index] = this.formatNumber(sums[index]) +"元"
        }
      })
      return sums
    },

    getSummaries1(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) { // 只找第一列放合计
          sums[index] = '负债和所有者权益（或股东权益）总计：'
          return
        }
        if (column.property === 'qm') {
          const values = data.map(item => {
                if(item["zc"].includes("合计")){
                  return  item[column.property]
                }
              }
          )
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return Number(prev) + Number(curr)
            } else {
              return Number(prev)
            }
          }, 0)
          sums[index] = this.formatNumber(sums[index]) +"元"
        }
        if (column.property === 'nc') {
          const values = data.map(item => {
                if(item["zc"].includes("合计")){
                  return  item[column.property]
                }
              }
          )
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return Number(prev) + Number(curr)
            } else {
              return Number(prev)
            }
          }, 0)
          sums[index] = this.formatNumber(sums[index]) +"元"
        }
      })
      return sums
    },
    cellStyleMethod({ row }) {
      if (row["zc"].includes("合计")) {
        return { 'background':'#f0f9eb' };
      }
      if (row["zc"] === "流动资产：" || row["zc"] === "非流动资产：" || row["zc"] === "流动负债：" || row["zc"] === "非流动负债：" || row["zc"] === "所有者权益（或股东权益）：") {
        return {'font-weight': '600' };
      }
    },

    //导出
    doExport () {
      this.exportTip = "资产负债表导出Excel中..."
      this.$axios.post("/api/balance/exportbalancedata",{leftData: this.leftData, rightData: this.rightData, recordMonth:this.search.recordMonth}, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 3000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "资产负债表导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "资产负债表导出出错")
          })

    },

    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/balance/printbalancedata?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid
    },
  }
}
</script>

<style scoped>

</style>
