<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>凭证管理</el-breadcrumb-item>
        <el-breadcrumb-item>凭证录入</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="4">
            <div>
              <el-button type="success" @click="toAddVoucher()">
                <el-icon><Plus /></el-icon><span class="icon-right">录入凭证</span>
              </el-button>
              <el-button type="primary" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
                <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
              </el-button>
            </div>
            <div>
              <a ref="expLink" target="_blank" style="display: none;"></a>
              <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
            </div>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadVoucher()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadVoucher($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择会计科目"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="loadVoucher($event)"
                :options="subjectTree" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择收支类别"
                style="width: 100%;"
                v-model="search.revExpType"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="loadVoucher($event)"
                :options="subjectAllTree" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="search.num" placeholder="请输入凭证号" @keyup.enter="loadVoucher" @clear="loadVoucher" clearable size="default"></el-input>
          </el-col>
          <el-col :span="3">
            <el-button
                type="primary"
                size="default"
                @click="loadVoucher">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
          </el-col>
          <el-col :span="1" >
            <el-button-group class="ml-4">
              <el-tooltip effect="dark" content="刷新" placement="top">
                <el-button @click="toRefresh()">
                  <el-icon :size="16"><Refresh /></el-icon>
                </el-button>
              </el-tooltip>
              <el-tooltip effect="dark" content="列筛选" placement="top">
                <el-button>
                  <table-screen :datas="tableList"></table-screen>
                </el-button>
              </el-tooltip>
            </el-button-group>
          </el-col>
        </el-row>
        <div>
          <el-table
              :height="tableHeight"
              :data="voucherDataList">
            <template v-slot:empty>
              <el-empty description="暂无数据" :image-size="120"></el-empty>
            </template>
            <el-table-column
                prop="RECORD_TIME"
                label="记录日期"
                show-overflow-tooltip
                align="center"
                width="120px"
                v-if="tableList[0].isTrue"
            >
            </el-table-column>
            <el-table-column
                prop="VOUCHER_NUM"
                label="凭证号"
                align="center"
                show-overflow-tooltip
                width="70px"
                v-if="tableList[1].isTrue"
            >
            </el-table-column>
            <el-table-column
                prop="ABSTRACT"
                label="摘要"
                align="center"
                show-overflow-tooltip
                min-width="100px"
                v-if="tableList[2].isTrue"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_NAME"
                label="会计科目"
                show-overflow-tooltip
                align="center"
                min-width="100px"
                v-if="tableList[3].isTrue"
            >
            </el-table-column>
            <el-table-column
                prop="SUBJECT_DETAIL"
                label="明细科目"
                show-overflow-tooltip
                align="center"
                min-width="100px"
                v-if="tableList[4].isTrue"
            >
            </el-table-column>
            <el-table-column
                prop="REV_EXP_TYPE_NAME"
                label="收支类别"
                show-overflow-tooltip
                align="center"
                min-width="100px"
                v-if="tableList[5].isTrue"
            >
            </el-table-column>
            <el-table-column
                prop="REV_EXP_DETAIL"
                label="收支明细"
                show-overflow-tooltip
                align="center"
                min-width="100px"
                v-if="tableList[6].isTrue"
            >
            </el-table-column>
            <el-table-column
                label="收入金额"
                show-overflow-tooltip
                align="center"
                min-width="100px"
                v-if="tableList[7].isTrue"
            >
              <template #default="scope">
                <span v-if="scope.row.REVENUE_MONEY !== null && scope.row.REVENUE_MONEY !== ''" class="disable">
                  {{ scope.row.REVENUE_MONEY }}元
                </span>
              </template>
            </el-table-column>
            <el-table-column
                label="支出金额"
                show-overflow-tooltip
                align="center"
                min-width="100px"
                v-if="tableList[8].isTrue"
            >
              <template #default="scope">
                <span v-if="scope.row.EXPENDITURE_MONEY !== null && scope.row.EXPENDITURE_MONEY !== ''" class="disable">
                  {{ scope.row.EXPENDITURE_MONEY }}元
                </span>
              </template>
            </el-table-column>
            <el-table-column
                prop="DOCUMENT"
                label="单据"
                show-overflow-tooltip
                align="center"
                min-width="60px"
                v-if="tableList[9].isTrue"
            >
            </el-table-column>
            <el-table-column
                label="税率"
                show-overflow-tooltip
                align="center"
                min-width="80px"
                v-if="tableList[10].isTrue"
            >
              <template #default="scope">
                <span v-if="scope.row.TAX_RATE !== null && scope.row.TAX_RATE !== ''" class="disable">
                  {{ scope.row.TAX_RATE }}%
                </span>
              </template>
            </el-table-column>
            <el-table-column
                label="凭证图片"
                min-width="90px"
                v-if="tableList[11].isTrue"
            >
              <template #default="scope">
                <div style="display: flex; flex-direction: row;">
                  <div v-for="it in scope.row.voucherImage" :key="it.VOUCHER_IMAGE_ID" class="div_img">
                    <img :src="it.RODE" :title="it.VOUCHER_IMAGE_NAME+'.'+it.VOUCHER_IMAGE_SUFFIX" class="img" @click="handleSee(it.RODE, it.VOUCHER_IMAGE_NAME+'.'+it.VOUCHER_IMAGE_SUFFIX)">
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                label="计税标志"
                align="center"
                min-width="80px"
                v-if="tableList[12].isTrue"
            >
              <template #default="scope">
                <el-tag size="small" type="warning" effect="plain" v-if="scope.row.TAXATION === '0'">否</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="USER_NAME"
                label="录入人"
                show-overflow-tooltip
                align="center"
                min-width="80px"
                v-if="tableList[13].isTrue"
            >
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="200px"
                align="center">
              <template #default="scope">
                <el-button class="table-button"
                           size="small"
                           type="primary"
                           v-if='scope.row.ORGAN_ID.toString() === this.organ_organid.toString()
                            && scope.row.ABSTRACT !== "计提当期资产折旧摊销费用"
                            && scope.row.ABSTRACT !== "计提当期应付利息费用"
                            && scope.row.ABSTRACT !== "计提当期员工工资"
                            && scope.row.IS_EXAMINE !== "1"'
                           @click="toEdit(scope.row, scope.$index)">编辑</el-button>
                <el-button class="table-button"
                           size="small"
                           type="danger"
                           v-if='scope.row.ORGAN_ID.toString() === this.organ_organid.toString()
                            && scope.row.IS_EXAMINE !== "1"'
                           @click="toDel(scope.row)">删除</el-button>
                <el-button class="table-button"
                           size="small"
                           type="success"
                           v-if='scope.row.IS_EXAMINE !== "1" && this.IsVoucher ==="1"'
                           @click="toExamine(scope.row)">审核</el-button>
                <el-button class="table-button"
                           size="small"
                           type="warning"
                           v-if='scope.row.IS_EXAMINE === "1" && this.IsVoucher ==="1"'
                           @click="toRemoveExamine(scope.row)">取消审核</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
            background
            v-model:current-page="search.page"
            v-model:page-size="search.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadVoucher"
            @size-change="loadVoucher"
            :page-sizes="[20, 100, 500, 1000]"
            :total="total"
            style="margin-top: 10px;"
        >
        </el-pagination>

        <el-dialog
            width="1000px"
            :title="voucherTitle"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            draggable>
          <div>
            <div style="display: inline-flex; vertical-align: middle; margin-bottom: 20px">
              <div style="width: 120px; padding: 0 12px 0px 0; display: flex; align-items: center;justify-content: right" >业务类型(选填)</div>
              <div style="width: 500px;">
                <el-cascader
                    placeholder="请选择业务类型"
                    style="width: 100%;"
                    v-model="businessType"
                    :props="props"
                    clearable
                    filterable
                    @change="getBusinessData"
                    :options="businessTree" >
                </el-cascader>
              </div>
            </div>
            <el-scrollbar height="400px">
            <el-form
                :inline="true"
                :model="voucherData"
                :rules="rules"
                ref="voucherDataForm"
                label-width="130px"
            >
                <el-form-item label="摘要:" prop="abstract" style="width: calc(70% - 85px);">
                  <el-input v-model="voucherData.abstract" />
                </el-form-item>
                <el-form-item label="凭证号:" prop="num" style="width: calc(30% - 40px);">
                  <el-input-number v-model="voucherData.num" :min="1" style="width: 100%"/>
                </el-form-item>
                <el-form-item label="会计科目" prop="subjectSort" class="form-col45">
                  <el-cascader
                      placeholder="请选择科目"
                      style="width: 100%;"
                      v-model="voucherData.subjectSort"
                      :props="props"
                      clearable
                      filterable
                      @change="getSubjectName()"
                      :options="subjectTree" >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="科目明细:" prop="subjectDetail" style="width: 300px;">
                  <el-select v-model="voucherData.subjectDetail" :placeholder="pla_subjectDetail" filterable style="width: 100%;" @change=getBalanceMoney()>
                    <template #header>
                      <el-button type="success" @click="toDetail('1')" style="width: 100%" size="small">
                        <el-icon><Plus /></el-icon><span class="icon-right">添加明细</span>
                      </el-button>
                    </template>
                    <el-option
                        v-for="item in backType"
                        :key="item.SUBJECT_DETAIL"
                        :label="item.SUBJECT_DETAIL"
                        :value="item.SUBJECT_DETAIL">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item style="width: calc(55% - 350px);">
                  <span>结余金额: {{ this.balanceMoney }} 元</span>
                </el-form-item>

                <el-form-item label="收支类别:" prop="revExpType" class="form-col45">
                  <el-cascader
                      placeholder="请选择收支类别"
                      style="width: 100%;"
                      v-model="voucherData.revExpType"
                      :props="props"
                      clearable
                      filterable
                      @change="getSubjectName()"
                      :options="subjectAllTree" >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="收支明细:" prop="revExpDetail" style="width: 300px;">
                  <el-select v-model="voucherData.revExpDetail" :placeholder="pla_revExpDetail" filterable style="width: 100%;" @change=getBalanceMoney1()>
                    <template #header>
                      <el-button type="success" @click="toDetail('2')" style="width: 100%" size="small">
                        <el-icon><Plus /></el-icon><span class="icon-right">添加明细</span>
                      </el-button>
                    </template>
                    <el-option
                        v-for="item in paymentType"
                        :key="item.SUBJECT_DETAIL"
                        :label="item.SUBJECT_DETAIL"
                        :value="item.SUBJECT_DETAIL">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item style="width: calc(55% - 350px);">
                  <span>结余金额: {{ this.balanceMoney1 }} 元</span>
                </el-form-item>

                <el-form-item label="收入金额:" prop="revenueMoney" class="form-col45">
                  <el-input v-model="voucherData.revenueMoney"
                            :placeholder="pla_revenueMoney"
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                            @change="getShowPage()" :disabled="dis_revmoney">
                    <template #append>元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="支出金额:" prop="expenditureMoney" class="form-col55" >
                  <el-input v-model="voucherData.expenditureMoney"
                            :placeholder="pla_expenditureMoney"
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                            @change="getShowPage()" :disabled="dis_expmoney">
                    <template #append>元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="单据:" prop="document" class="form-col45" >
                  <el-input v-model="voucherData.document" />
                </el-form-item>
                <el-form-item label="税率:" prop="taxRate"   class="form-col55" >
                  <el-select v-model="voucherData.taxRate" clearable :placeholder="pla_taxRate"  style="width: 100%;" @change="changeTaxRate">
                    <el-option value="" label="无"></el-option>
                    <el-option
                        v-for="item in taxRate"
                        :key="item.CODE_NAME"
                        :label="item.CODE_NAME+'%'"
                        :value="item.CODE_NAME">
                    </el-option>
                    <template #tag>%</template>
                  </el-select>
                </el-form-item>
                <el-form-item label="记录时间:" prop="recordTime" class="form-col45" >
                  <el-date-picker
                      v-model="voucherData.recordTime"
                      type="date"
                      placeholder="选择记录时间"
                      style="width: 100%"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                  />
                </el-form-item>
                <el-form-item label="计税标致:" prop="taxation" class="form-col55" >
                  <el-switch
                      v-model="voucherData.taxation"
                      active-value="1"
                      inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      inline-prompt
                      active-text="是"
                      inactive-text="否">
                  </el-switch>
                </el-form-item>
                <el-form-item label="备注:" prop="remark" class="form-row">
                  <el-input v-model="voucherData.remark" type="textarea" />
                </el-form-item>
                <el-form-item label="凭证图片:" prop="voucherImage" class="form-row">
                  <div>
                    <el-upload
                        v-model:file-list="voucherData.voucherImage"
                        class="upload"
                        name="uploadfile"
                        list-type="picture-card"
                        method="post"
                        :file-list="fileList"
                        :action="uploadAction"
                        :with-credentials="true"
                        :headers="headerObj"
                        :before-upload="handlebe"
                        :limit="3"
                        :on-error="error"
                        :on-success="uploadSuccess"
                        :disabled="ifup"
                        accept="image/jpg, image/jpeg, image/png">
                      <el-icon><Plus /></el-icon>
                      <template #file="{ file }">
                        <div>
                          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                          <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)">
                            <el-icon><zoom-in /></el-icon>
                          </span>
                          <span class="el-upload-list__item-delete" @click="onRemove(file)">
                            <el-icon><Delete /></el-icon>
                          </span>
                        </span>
                      </div>
                      </template>
                      <template #tip>
                        <div class="el-upload__tip">
                          仅限数量3个以内、大小10MB以内jpg/png/jpeg图片,
                        </div>
                      </template>
                    </el-upload>
                    <el-dialog v-model="dialogVisible" :title="tfjname" style="width:80%; height: calc(100% - 184px);min-height: 550px; overflow: auto">
                      <div  style="height:500px;">
                        <el-image w-full :src="dialogImageUrl" alt="预览" v-if="picsee" />
                      </div>
                    </el-dialog>
                    <div style="display: flex;" v-if="this.fileList.length !== 0">
                      <div v-for="it in this.fileList" :key="it.VOUCHER_IMAGE_ID" style="width: 77px; height:77px; margin: 0 9px 8px 0;">
                        <img :src="it.RODE" :title="it.VOUCHER_IMAGE_NAME+'.'+it.VOUCHER_IMAGE_SUFFIX" @click="handleSee(it.RODE, it.VOUCHER_IMAGE_NAME+'.'+it.VOUCHER_IMAGE_SUFFIX)" style="width: 100%;height: 100%; border: 1px solid #cdd0d6; border-radius: 6px; cursor: pointer">
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <div v-if="showInStorage">
                <el-divider style="margin: 15px 0;">入库</el-divider>
                <el-form
                    :inline="true"
                    :model="InStorageData"
                    :rules="in_rules"
                    ref="inStorageDataForm"
                    label-width="130px"
                >
                <el-form-item label="规格名称:" prop="specsName" class="form-col">
                  <el-select v-model="InStorageData.specsName" placeholder="请选择规格名称">
                    <el-option
                        v-for="item in SpecsType"
                        :key="item.CODE"
                        :label="item.CODE_NAME"
                        :value="item.CODE">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="单位:" prop="unit" class="form-col" >
                  <el-select v-model="InStorageData.unit" placeholder="请选择单位">
                    <el-option
                        v-for="item in UnitData"
                        :key="item.CODE"
                        :label="item.CODE_NAME"
                        :value="item.CODE">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="入库数量:" prop="number" class="form-col">
                  <el-input v-model="InStorageData.number" @blur="getMoney()"/>
                </el-form-item>
                <el-form-item label="入库单价:" prop="unitPrice" class="form-col" >
                  <el-input v-model="InStorageData.unitPrice" @blur="getMoney()">
                    <template #append>元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="入库金额:" prop="money" class="form-col" >
                  <el-input v-model="InStorageData.money"
                            disabled
                            :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                    <template #append>元</template>
                  </el-input>
                </el-form-item>
                </el-form>
              </div>
              <div v-if="showOutStorage">
                <el-divider style="margin: 15px 0;">出库</el-divider>
                <el-form
                    :inline="true"
                    :model="OutStorageData"
                    :rules="out_rules"
                    ref="OutStorageDataForm"
                    label-width="130px"
                >
                  <el-form-item label="规格名称:" prop="specsName" class="form-col">
                    <el-select v-model="OutStorageData.specsName" placeholder="请选择规格名称" disabled>
                      <el-option
                          v-for="item in SpecsType"
                          :key="item.CODE"
                          :label="item.CODE_NAME"
                          :value="item.CODE">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="单位:" prop="unit" class="form-col" >
                    <el-select v-model="OutStorageData.unit" placeholder="请选择单位" disabled>
                      <el-option
                          v-for="item in UnitData"
                          :key="item.CODE"
                          :label="item.CODE_NAME"
                          :value="item.CODE">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="实时库存:" prop="realTimeNum" class="form-col">
                    <el-input v-model="OutStorageData.realTimeNum" disabled/>
                  </el-form-item>
                  <el-form-item label="移动单价:" prop="moveUnit" class="form-col" >
                    <el-input v-model="OutStorageData.moveUnit" disabled>
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="结余金额:" prop="balanceMoney" class="form-col" >
                    <el-input v-model="OutStorageData.balanceMoney"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')" disabled>
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="出库数量:" prop="number" class="form-col">
                    <el-input v-model="OutStorageData.number"  @blur="getMoney1()"/>
                  </el-form-item>
                  <el-form-item label="出库单价:" prop="unitPrice" class="form-col" >
                    <el-input v-model="OutStorageData.unitPrice" @blur="getMoney1()">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="出库金额:" prop="money" class="form-col" >
                    <el-input v-model="OutStorageData.money"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div v-if="showAsset">
                <el-divider style="margin: 15px 0;">资产折旧</el-divider>
                <el-form
                    :inline="true"
                    :model="AssetData"
                    :rules="asset_rules"
                    ref="AssetDataForm"
                    label-width="130px"
                >
                  <el-form-item label="资产类型:" prop="assetType" class="form-col">
                    <el-select v-model="AssetData.assetType" placeholder="请选择资产类型" @change="getProperthResidual()">
                      <el-option
                          v-for="item in assetTypeData"
                          :key="item.CODE"
                          :label="item.CODE_NAME"
                          :value="item.CODE">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="取得年月:" prop="obtainYearMonth" class="form-col" >
                    <el-date-picker
                        v-model="AssetData.obtainYearMonth"
                        type="date"
                        placeholder="选择取得年月"
                        style="width: 100%"
                        format="YYYY-MM"
                        value-format="YYYY-MM"
                        @change="getProperthResidual()"
                    />
                  </el-form-item>
                  <el-form-item label="使用部门:" prop="useDepartment" class="form-col">
                    <el-select v-model="AssetData.useDepartment" placeholder="请选择使用部门">
                      <el-option
                          v-for="item in usingDepartmentData"
                          :key="item.CODE"
                          :label="item.CODE_NAME"
                          :value="item.CODE">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="资产名称:" prop="assetName" class="form-col" >
                    <el-input v-model="AssetData.assetName" />
                  </el-form-item>
                  <el-form-item label="单位:" prop="unit" class="form-col">
                    <el-select v-model="AssetData.unit" placeholder="请选择单位">
                      <el-option
                          v-for="item in UnitData"
                          :key="item.CODE"
                          :label="item.CODE_NAME"
                          :value="item.CODE">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="数量:" prop="num" class="form-col" >
                    <el-input v-model="AssetData.num" @change="getProperthResidual()"/>
                  </el-form-item>
                  <el-form-item label="单价:" prop="unitPrice" class="form-col" >
                    <el-input v-model="AssetData.unitPrice"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                              @change="getProperthResidual()"/>
                  </el-form-item>
                  <el-form-item label="资产原值:" prop="properthOriginal" class="form-col" >
                    <el-input v-model="AssetData.properthOriginal"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                              @change="getProperthResidual()"/>
                  </el-form-item>
                  <el-form-item label="使用年限（年）或工作量:" prop="life" class="form-col" >
                    <el-input v-model="AssetData.life" @change="getProperthResidual()"/>
                  </el-form-item>
                  <el-form-item label="残值率:" prop="residualRate" class="form-col" >
                    <el-input v-model="AssetData.residualRate" @change="getProperthResidual()">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="折旧方式:" prop="depreciationType" class="form-col" >
                    <el-select v-model="AssetData.depreciationType" placeholder="请选择折旧方式" @change="getProperthResidual()">
                      <el-option
                          v-for="item in depreciationTypeData"
                          :key="item.CODE"
                          :label="item.CODE_NAME"
                          :value="item.CODE">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年折旧率:" prop="yearDepreciationRate" class="form-col" >
                    <el-input v-model="AssetData.yearDepreciationRate" disabled>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="资产残值:" prop="properthResidual" class="form-col" >
                    <el-input v-model="AssetData.properthResidual" disabled/>
                  </el-form-item>
                  <el-form-item label="累计计提折旧额:" prop="provisionTotal" class="form-col" >
                    <el-input v-model="AssetData.provisionTotal" disabled/>
                  </el-form-item>
                  <el-form-item label="资产账面余额:" prop="properthBalance" class="form-col" >
                    <el-input v-model="AssetData.properthBalance" disabled/>
                  </el-form-item>
                  <el-form-item label="当月计提金额:" prop="provisionMonth" class="form-col" >
                    <el-input v-model="AssetData.provisionMonth" />
                  </el-form-item>
                  <el-form-item label="工作量:"  class="form-col" v-if="this.AssetData.depreciationType ==='2'">
                    <el-input v-model="AssetData.gzl" @change="getProperthResidual()" />
                  </el-form-item>
                </el-form>
              </div>
              <div v-if="showLiabilities">
                <el-divider style="margin: 15px 0;">负债</el-divider>
                <el-form
                    :inline="true"
                    :model="liabilitiesData"
                    :rules="lia_rules"
                    ref="liabilitiesDataForm"
                    label-width="130px"
                >
                  <el-form-item label="借款本金:" prop="loan_money" class="form-col">
                    <el-input v-model="liabilitiesData.loan_money"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                              @blur="getResidueMoney">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="归还本金:" prop="return_money" class="form-col" >
                    <el-input v-model="liabilitiesData.return_money"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                              @blur="getResidueMoney">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="剩余本金:" prop="residue_money" class="form-col" >
                    <el-input v-model="liabilitiesData.residue_money"
                              :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                      <template #append>元</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="年利率:" prop="annual_interest_rate" class="form-col" >
                    <el-input v-model="liabilitiesData.annual_interest_rate">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>


            <div class="div-button">
              <el-button type="danger" @click="toPage('last')" style="margin-right: 20px" v-if="this.voucherTitle ==='编辑凭证'">上一页</el-button>
              <el-button type="primary" @click="toSave" style="margin-right: 20px">保 存</el-button>
              <el-button type="success" @click="toPage('next')" v-if="this.voucherTitle ==='编辑凭证'">下一页</el-button>
            </div>
            </el-scrollbar>
          </div>
        </el-dialog>

        <el-dialog v-model="CSdialog" :title="fjname" width="1000px">
          <div style="height: 500px; overflow: auto;">
            <el-image w-full :src="CSpicfile" style="width: calc(100% - 10px);" />
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {ElMessage} from "element-plus";
import apiURL from "../apiUrl/api.js";
import tableScreen from "@/common/TableScreen";
export default {
  name: "voucherEnter",
  components: {
    tableScreen
  },
  data () {
    return {
      tableList:[
        {
          value: '记录日期',
          isTrue:true,
        },
        {
          value: '凭证号',
          isTrue:true,
        },
        {
          value: '摘要',
          isTrue:true,
        },
        {
          value: '会计科目',
          isTrue:true,
        },
        {
          value: '明细',
          isTrue:true,
        },
        {
          value: '收支类别',
          isTrue:true,
        },
        {
          value: '收支明细',
          isTrue:true,
        },
        {
          value: '收入金额',
          isTrue:true,
        },
        {
          value: '支出金额',
          isTrue:true,
        },
        {
          value: '单据',
          isTrue:true,
        },
        {
          value: '税率',
          isTrue:true,
        },
        {
          value: '图片',
          isTrue:true,
        },
        {
          value: '计税标致',
          isTrue:true,
        },
        {
          value: '录入人',
          isTrue:true,
        },
      ],


      organ_organid: sessionStorage.getItem("organ-organid"),
      IsVoucher: sessionStorage.getItem("IsVoucher"),
      tableHeight: 0,
      exportTip: "",
      search: {
        recordMonth: "",
        revExpType:"",
        subjectSort:"",
        num:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
        page: 1,
        pagesize: 20,
      },
      subjectTree:[],
      subjectAllTree:[],
      businessTree:[],
      OrganTreeData:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      voucherDataList:[],
      voucherTitle: "",
      dailVisi: false,
      total: 0,
      voucherData: {
        voucherId:"",
        abstract:"",
        num:"",
        subjectSort:"",
        subjectName:"",
        subjectDetail: "",
        revExpType:"",
        revExpName:"",
        revExpDetail:"",
        revenueMoney:"",
        expenditureMoney:"",
        document:"",
        taxRate:"",
        recordTime:"",
        taxation:"",
        voucherImage:[],
        remark:"",
      },
      detailBalanceMoney:[],
      balanceMoney:"",
      balanceMoney1:"",
      businessType:"",
      pla_subjectDetail:"",
      pla_revExpDetail:"",
      pla_taxRate:"",
      pla_revenueMoney:"",
      pla_expenditureMoney:"",
      currentRow:"",
      backType:[],
      paymentType:[],
      taxRate:[],
      UnitData:[],
      SpecsType:[],
      rules: {
        abstract: [{required: true, message: "请填写摘要", trigger: "blur"}],
        num: [{required: true, message: "请填写凭证号", trigger: "blur"}],
        subjectSort: [{required: true, message: "请选择科目类别", trigger: "blur"}],
        revExpType: [{required: true, message: "请选择收支类别", trigger: "blur"}],
        recordTime: [{required: true, message: "请选择记录时间", trigger: "blur"}],
      },
      sub_nature:"",
      rev_nature:"",
      //入库
      showInStorage: false,
      InStorageData: {
        specsName:"",
        unit:"",
        number:"",
        unitPrice:"",
        money:"",
      },
      in_rules: {
        specsName: [{required: true, message: "请选择规格名称", trigger: "blur"}],
        unit: [{required: true, message: "请选择单位", trigger: "blur"}],
        number: [{required: true, message: "请填写入库数量", trigger: "blur"}],
        unitPrice: [{required: true, message: "请填写入库单价", trigger: "blur"}],
        money: [{required: true, message: "请填写入库金额", trigger: "blur"}],
      },
      //出库
      showOutStorage: false,
      OutStorageData: {
        specsName:"",
        unit:"",
        realTimeNum:"",
        moveUnit:"",
        balanceMoney:"",
        number:"",
        unitPrice:"",
        money:"",
      },
      GoodsList:[],
      OutStorageList:[],
      out_rules:{
        number: [{required: true, message: "请填写出库数量", trigger: "blur"}],
        unitPrice: [{required: true, message: "请填写出库单价", trigger: "blur"}],
        money: [{required: true, message: "请填写出库金额", trigger: "blur"}],
      },
      //资产
      showAsset: false,
      usingDepartmentData:[],
      assetTypeData:[],
      depreciationTypeData:[],
      AssetData:{
        assetType:"",
        obtainYearMonth:"",
        useDepartment:"",
        assetName:"",
        unit:"",
        num:"",
        unitPrice:"",
        properthOriginal: "",
        life:"",
        residualRate:"",
        properthResidual:"",
        depreciationType:"",
        provisionTotal:"",
        properthBalance:"",
        provisionMonth:"",
        yearDepreciationRate:"",
        gzl:"",
      },
      getMonth: 0,
      asset_rules:{
        assetType: [{required: true, message: "请选择资产类型", trigger: "blur"}],
        obtainYearMonth: [{required: true, message: "请选择取得年月", trigger: "blur"}],
        useDepartment: [{required: true, message: "请选择使用部门", trigger: "blur"}],
        assetName: [{required: true, message: "请填写资产名称", trigger: "blur"}],
        unit: [{required: true, message: "请选择单位", trigger: "blur"}],
        depreciationType: [{required: true, message: "请选择折旧方式", trigger: "blur"}],
      },
      //负债
      showLiabilities:false,
      liabilitiesData:{
        loan_money:"",
        return_money:"",
        residue_money: "",
        annual_interest_rate:"",
      },
      lia_rules:{
        loan_money: [{required: true, message: "请填写借款本金", trigger: "blur"}],
        annual_interest_rate: [{required: true, message: "请填写年率利", trigger: "blur"}],
      },
      CSdialog: false,
      fjname:"",
      CSpicfile: "",

      uploadAction: "",
      headerObj: {
        "Authorization":""
      },
      dialogVisible: false,
      dialogImageUrl:"",
      picsee: false,
      ifup:false,
      tfjname:"",
      fileList:[],
      dis_revmoney:false,
      dis_expmoney:false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadSubjectData()
      this.loadVoucher()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },

  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 130
    },
    current(d) {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if(d === "month")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if(d === "day")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    handleSee (url,fname){
      this.fjname = fname
      this.CSpicfile = ""
      this.CSdialog = true
      this.CSpicfile = url
    },
    toDetail(data){
      var subjectName = ""
      this.subjectAllTree.forEach(item => {
        if (item.children != null) {
          item.children.forEach(ch => {
            ch.children.forEach(c => {
              if(data === "1"){
                if (c.id === this.voucherData.subjectSort) {
                  subjectName = c.label
                }
              }else{
                if (c.id === this.voucherData.revExpType) {
                  subjectName = c.label
                }
              }
            })
          })
        }
      })
      this.$router.replace({name: "subject-mge", query:{name:subjectName}})
    },
    loadSubjectData() {
      this.$axios.post("/api/common/getcodelists", {codes: ["TAX_RATE", "UNIT", "SPECS_TYPE", "USING_DEPARTMNET", "ASSET_TYPE", "DEPRECIATION_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.taxRate = data.result.TAX_RATE
              this.UnitData = data.result.UNIT
              this.SpecsType = data.result.SPECS_TYPE
              this.usingDepartmentData = data.result.USING_DEPARTMNET
              this.assetTypeData = data.result.ASSET_TYPE
              this.depreciationTypeData = data.result.DEPRECIATION_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })

      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.subjectAllTree = response.data.result
              var d = []
              this.subjectAllTree.forEach( item =>{
                if(item.label === "资产" || item.label === "负债" || item.label === "权益" || item.label === "成本"){
                  d.push(item)
                }
              })
              this.subjectTree = d
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.get("/api/common/getbusinesstree")
          .then((response) => {
            if (response.data.success) {
              this.businessTree = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取业务类型列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取业务类型列表出错")
          })

      this.$axios.post("/api/voucher/loadAssetOutStorageData", this.search)
          .then((response) => {
            if (response.data.success) {
              this.OutStorageList = response.data.result
            } else {
              this.$alert(response.data.result, "获取物资出库记录列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取物资出库记录列表出错")
          })

      this.$axios.post("/api/voucher/loaddetaildata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.detailBalanceMoney = response.data.result
            } else {
              this.$alert(response.data.result, "获取科目明细的结余金额出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目明细的结余金额出错")
          })

      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    toRefresh() {
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.search.subjectSort = ""
      this.search.revExpType = ""
      this.search.num = ""
      this.search.s_organid = ""
      this.search.s_depid = ""
      this.loadVoucher("refresh")
    },
    toReset() {
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.search.subjectSort = ""
      this.search.revExpType = ""
      this.search.num = ""
      this.search.s_organid = ""
      this.search.s_depid = ""
      this.loadVoucher()
    },
    loadVoucher(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/voucher/loadvoucherinfo", this.search)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.voucherDataList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取凭证列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取凭证列表出错")
          })
    },
    toAddVoucher() {
      this.voucherTitle = "新增凭证"
      this.voucherData.maketype = ""
      this.businessType = ""
      this.voucherData.voucherId = ""
      this.voucherData.abstract = ""
      this.voucherData.subjectSort = ""
      this.voucherData.subjectName = ""
      this.voucherData.subjectDetail = ""
      this.voucherData.revExpType = ""
      this.voucherData.revExpName = ""
      this.voucherData.revExpDetail = ""
      this.voucherData.revenueMoney = ""
      this.voucherData.expenditureMoney = ""
      this.voucherData.document = ""
      this.voucherData.taxRate = ""
      this.voucherData.recordTime = this.current("day")
      this.voucherData.taxation = "1"
      this.voucherData.remark = ""
      this.voucherData.voucherImage = []
      this.fileList = []
      this.currentRow = ""
      this.balanceMoney = ""
      this.balanceMoney1 = ""
      this.pla_subjectDetail = ""
      this.pla_revExpDetail = ""
      this.pla_revenueMoney = ""
      this.getMaxVoucherNum('add')
      this.showInStorage = false
      this.showOutStorage = false
      this.showAsset = false
      if (this.$refs.voucherDataForm)
        this.$refs.voucherDataForm.clearValidate()
      if (this.$refs.inStorageDataForm)
        this.$refs.inStorageDataForm.clearValidate()
      if (this.$refs.OutStorageDataForm)
        this.$refs.OutStorageDataForm.clearValidate()
      if (this.$refs.AssetDataForm)
        this.$refs.AssetDataForm.clearValidate()
      if (this.$refs.liabilitiesDataForm)
        this.$refs.liabilitiesDataForm.clearValidate()
    },
    getMaxVoucherNum(type){
      this.$axios.post("/api/voucher/getMaxVoucherNum", this.search)
          .then((response) => {
            if (response.data.success) {
              this.voucherData.num = response.data.result.max_num
              if(type === "add"){
                this.dailVisi = true
              }
            } else {
              this.$alert(response.data.result, "获取最大凭证号出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取最大凭证号出错")
          })
    },

    getBusinessData(){
      var info =[]
      if(this.businessType !== "" && this.businessType !== null) {
        this.businessTree.forEach(item => {
          if (item.children != null) {
            item.children.forEach(ch => {
              if (ch.children != null) {
                ch.children.forEach(c => {
                  if (c.id === this.businessType) {
                    return info = c.info
                  }
                })
              }
            })
          }
        })
        this.voucherData.abstract = info.BUSINESS_ABSTRACT
        this.voucherData.subjectSort = info.SUBJECT_SORT.toString()
        this.voucherData.subjectDetail = ""
        this.pla_subjectDetail = info.SUBJECT_DETAIL
        this.voucherData.revExpType = info.REV_EXP_TYPE.toString()
        this.voucherData.revExpDetail = ""
        this.pla_revExpDetail = info.REV_EXP_DETAIL
        this.voucherData.revenueMoney = ""
        this.pla_revenueMoney = info.REVENUE_MONEY
        this.voucherData.expenditureMoney = ""
        this.pla_expenditureMoney = info.EXPENDITURE_MONEY
        this.dis_revmoney = true
        this.dis_expmoney = true
        if(this.pla_expenditureMoney !== "" && this.pla_expenditureMoney !== null){
          this.dis_expmoney = false
        }
        if(this.pla_revenueMoney !== "" && this.pla_revenueMoney !== null){
          this.dis_revmoney = false
        }
        this.voucherData.document = ""
        this.voucherData.taxRate = ""
        if(info.TAX_RATE)
          this.pla_taxRate = info.TAX_RATE +"%"
        else
          this.pla_taxRate = ""

        //出入库数据清空
        this.voucherData.maketype =""
        this.balanceMoney = ""
        this.balanceMoney1 = ""
        //入库
        this.sub_nature =""
        this.rev_nature =""
        this.showInStorage = false
        this.InStorageData.specsName = ""
        this.InStorageData.unit = ""
        this.InStorageData.number = ""
        this.InStorageData.unitPrice = ""
        this.InStorageData.money = ""
        //出库
        this.showOutStorage = false
        this.OutStorageData.specsName = ""
        this.OutStorageData.unit = ""
        this.OutStorageData.realTimeNum = ""
        this.OutStorageData.moveUnit = ""
        this.OutStorageData.balanceMoney = ""
        this.OutStorageData.number = ""
        this.OutStorageData.money = ""
        this.OutStorageData.unitPrice = ""
        //资产
        this.showAsset = false
        this.AssetData.assetType = ""
        this.AssetData.obtainYearMonth = ""
        this.AssetData.useDepartment = ""
        this.AssetData.assetName = ""
        this.AssetData.unit = ""
        this.AssetData.num = ""
        this.AssetData.unitPrice = ""
        this.properthOriginal = ""
        this.AssetData.life = ""
        this.AssetData.residualRate = ""
        this.AssetData.properthResidual = ""
        this.AssetData.depreciationType = ""
        this.AssetData.provisionTotal = ""
        this.AssetData.properthBalance = ""
        this.AssetData.provisionMonth = ""
        this.AssetData.yearDepreciationRate = ""
        this.AssetData.gzl = ""

        //负债
        this.showLiabilities = false
        this.liabilitiesData.loan_money = ""
        this.liabilitiesData.return_money = ""
        this.liabilitiesData.residue_money = ""
        this.liabilitiesData.annual_interest_rate = ""
        this.getSubjectName("bus")
        setTimeout(this.getBalanceMoney, 500)
        setTimeout(this.getBalanceMoney1, 500)
      }
    },
    getSubjectName(type) {
      this.backType = []
      this.paymentType = []
      this.balanceMoney = ""
      this.balanceMoney1 = ""
      if(type !== "edit"){
        this.voucherData.subjectDetail = ""
        this.voucherData.revExpDetail = ""
      }
      if(type !== "bus"){
        this.pla_subjectDetail= ""
        this.pla_revExpDetail= ""
      }
      this.subjectAllTree.forEach(item => {
        if (item.children != null) {
          item.children.forEach(ch => {
            ch.children.forEach(c => {
              if (c.id === this.voucherData.subjectSort) {
                this.backType = c.detail
                this.voucherData.subjectName = c.label
                if(this.backType.length >0 && type !== "edit"){
                  this.pla_subjectDetail= this.backType[0].SUBJECT_DETAIL
                }
                this.sub_nature = c.nature
              }
              if (c.id === this.voucherData.revExpType) {
                this.paymentType = c.detail
                this.voucherData.revExpName = c.label
                if(this.paymentType.length >0 && type !== "edit"){
                  this.pla_revExpDetail= this.paymentType[0].SUBJECT_DETAIL
                }
                this.rev_nature = c.nature
              }
            })
          })
        }
      })
      this.getShowPage()
      setTimeout(this.getBalanceMoney, 500)
      setTimeout(this.getBalanceMoney1, 500)
    },
    getShowPage(){
      this.showInStorage = false
      this.showOutStorage = false
      this.showAsset = false
      this.showLiabilities = false
      if(this.sub_nature === "存货" || this.sub_nature === "物资" || this.rev_nature === "存货" || this.rev_nature === "物资"){
        if((this.sub_nature === "存货" || this.sub_nature === "物资") && (this.rev_nature === "存货" || this.rev_nature === "物资")){
          this.voucherData.maketype = "出入库"
          this.showInStorage = true
          this.$axios.get("/api/asset/getallgoodslist")
              .then((response) => {
                if (response.data.success) {
                  this.GoodsList = response.data.result
                }
                else {
                  this.$alert(response.data.result, "获取可出库库存列表出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "获取可出库库存列表出错")
              })
          this.showOutStorage = true
        }
        else if((this.sub_nature !== "存货" && this.sub_nature !== "物资") && (this.rev_nature === "存货" || this.rev_nature === "物资")){
          if(this.pla_expenditureMoney !== null && this.pla_expenditureMoney !== ""){
            this.voucherData.maketype = "入库"
            this.showInStorage = true
          }else if(this.pla_revenueMoney !== null && this.pla_revenueMoney !== ""){
            this.$axios.get("/api/asset/getallgoodslist")
                .then((response) => {
                  if (response.data.success) {
                    this.GoodsList = response.data.result
                  }
                  else {
                    this.$alert(response.data.result, "获取可出库库存列表出错")
                  }
                })
                .catch((error) => {
                  this.$alert(error, "获取可出库库存列表出错")
                })
            this.voucherData.maketype = "出库"
            this.showOutStorage = true
          }
        }
        else{
          if(this.pla_revenueMoney !== null && this.pla_revenueMoney !== ""){
            this.voucherData.maketype = "入库"
            this.showInStorage = true
          }else if(this.pla_expenditureMoney !== null && this.pla_expenditureMoney !== ""){
            this.$axios.get("/api/asset/getallgoodslist")
                .then((response) => {
                  if (response.data.success) {
                    this.GoodsList = response.data.result
                  }
                  else {
                    this.$alert(response.data.result, "获取可出库库存列表出错")
                  }
                })
                .catch((error) => {
                  this.$alert(error, "获取可出库库存列表出错")
                })
            this.voucherData.maketype = "出库"
            this.showOutStorage = true
          }
        }
      }else if(((this.sub_nature === "资产" || this.sub_nature === "摊销") && (Number(this.voucherData.revenueMoney)>0 || Number(this.voucherData.expenditureMoney)>0))
          || ((this.rev_nature === "资产"|| this.rev_nature === "摊销") && (Number(this.voucherData.revenueMoney)>0 || Number(this.voucherData.expenditureMoney)>0))){
        this.voucherData.maketype = "资产"
        this.showAsset = true
      }else if(this.voucherData.revenueMoney !== "" && (this.sub_nature === "付息" || this.rev_nature === "付息")){
        this.voucherData.maketype = "负债"
        this.showLiabilities = true
      }
    },

    getBalanceMoney(){
      this.balanceMoney = ""
      this.detailBalanceMoney.forEach(item => {
        if(this.voucherData.subjectDetail !== ""){
          if(item.SUBJECT_DETAIL === this.voucherData.subjectDetail && item.SUBJECT_SORT.toString() === this.voucherData.subjectSort) {
            this.balanceMoney = item.balanceMoney
          }
        }else{
          if(item.SUBJECT_DETAIL === this.pla_subjectDetail && item.SUBJECT_SORT.toString() === this.voucherData.subjectSort) {
            this.balanceMoney = item.balanceMoney
          }
        }
      })
      this.OutStorageList.forEach(item => {
        if(item.GOODS_NAME === this.voucherData.subjectDetail && item.GOODS_TYPE === this.voucherData.subjectSort){
          this.OutStorageData.specsName = item.SPECS_TYPE
          this.OutStorageData.unit = item.UNIT
          this.OutStorageData.realTimeNum = item.realTimeNum
          this.OutStorageData.moveUnit = item.moveUnit
          this.OutStorageData.balanceMoney = item.balanceMoney
        }
      })
    },
    getBalanceMoney1(){
      this.balanceMoney1 = ""
      this.detailBalanceMoney.forEach(item => {
        if(this.voucherData.revExpDetail !== "") {
          if (item.SUBJECT_DETAIL === this.voucherData.revExpDetail  && item.SUBJECT_SORT.toString() === this.voucherData.revExpType) {
            this.balanceMoney1 = item.balanceMoney
          }
        }else{
          if (item.SUBJECT_DETAIL === this.pla_revExpDetail && item.SUBJECT_SORT.toString() === this.voucherData.revExpType) {
            this.balanceMoney1 = item.balanceMoney
          }
        }
      })
    },

    getResidueMoney() {
      if(this.liabilitiesData.loan_money !== ""){
        if(this.liabilitiesData.return_money === ""){
          this.liabilitiesData.return_money = 0
        }
        this.liabilitiesData.residue_money = Number(this.liabilitiesData.loan_money) - Number(this.liabilitiesData.return_money)
      }
    },
    getMoney(){
      if(this.InStorageData.number !== "" && this.InStorageData.unitPrice !== ""){
        this.InStorageData.money = Number(this.InStorageData.number) * Number(this.InStorageData.unitPrice)
      }
    },
    getMoney1(){
      if(this.OutStorageData.number !== "" && this.OutStorageData.unitPrice !== ""){
        this.OutStorageData.money = Number(this.OutStorageData.number) * Number(this.OutStorageData.unitPrice)
      }
    },

    changeTaxRate(){
      this.pla_taxRate = ""
    },
    toSave() {
      if(this.voucherData.revenueMoney !== "" && this.voucherData.expenditureMoney !== ""){
        return this.$message({message: "收入金额和支出金额不能同时填写，无法保存", type: "error"})
      }
      if(this.voucherData.subjectDetail === ""){
        this.voucherData.subjectDetail = this.pla_subjectDetail
      }
      if(this.voucherData.revExpDetail === ""){
        this.voucherData.revExpDetail = this.pla_revExpDetail
      }
      if(this.voucherData.taxRate === ""){
        this.voucherData.taxRate = this.pla_taxRate.replace(/%/g, '')
      }
      if(this.voucherData.maketype === "出入库"){
        this.$refs["inStorageDataForm"].validate((valid) => {
          if (valid) {
            this.$refs["OutStorageDataForm"].validate((valid1) => {
              if (valid1) {
                if(this.voucherData.expenditureMoney !== ""){
                  this.voucherData.out_goodName = this.voucherData.subjectDetail
                  this.voucherData.out_goodType = this.voucherData.subjectSort
                  this.voucherData.in_goodName = this.voucherData.revExpDetail
                  this.voucherData.in_goodType = this.voucherData.revExpType
                }else{
                  this.voucherData.in_goodName = this.voucherData.subjectDetail
                  this.voucherData.in_goodType = this.voucherData.subjectSort
                  this.voucherData.out_goodName = this.voucherData.revExpDetail
                  this.voucherData.out_goodType = this.voucherData.revExpType
                }
                this.voucherData.in_specsName = this.InStorageData.specsName
                this.voucherData.in_unit = this.InStorageData.unit
                this.voucherData.in_number = this.InStorageData.number
                this.voucherData.in_unitPrice = this.InStorageData.unitPrice
                this.voucherData.in_money = this.InStorageData.money

                this.voucherData.out_specsName = this.OutStorageData.specsName
                this.voucherData.out_unit = this.OutStorageData.unit
                this.voucherData.out_number = this.OutStorageData.number
                this.voucherData.out_unitPrice = this.OutStorageData.unitPrice
                this.voucherData.out_money = this.OutStorageData.money
                setTimeout(this.toSaveVoucher, 500)
              }
            })
          }
        })
      }else if(this.voucherData.maketype === "入库"){
        this.$refs["inStorageDataForm"].validate((valid) => {
          if (valid) {
            if(this.sub_nature === "存货" || this.sub_nature === "物资"){
              this.voucherData.in_goodName = this.voucherData.subjectDetail
              this.voucherData.in_goodType = this.voucherData.subjectSort
            }else if(this.rev_nature === "存货" || this.rev_nature === "物资"){
              this.voucherData.in_goodName = this.voucherData.revExpDetail
              this.voucherData.in_goodType = this.voucherData.revExpType
            }
            this.voucherData.in_specsName = this.InStorageData.specsName
            this.voucherData.in_unit = this.InStorageData.unit
            this.voucherData.in_number = this.InStorageData.number
            this.voucherData.in_unitPrice = this.InStorageData.unitPrice
            this.voucherData.in_money = this.InStorageData.money
            setTimeout(this.toSaveVoucher, 500)
          }
        })
      }else if(this.voucherData.maketype ==="出库"){
        this.$refs["OutStorageDataForm"].validate((valid) => {
          if (valid) {
            if(this.sub_nature === "存货" || this.sub_nature === "物资"){
              this.voucherData.out_goodName = this.voucherData.subjectDetail
              this.voucherData.out_goodType = this.voucherData.subjectSort
            }else if(this.rev_nature === "存货" || this.rev_nature === "物资"){
              this.voucherData.out_goodName = this.voucherData.revExpDetail
              this.voucherData.out_goodType = this.voucherData.revExpType
            }
            this.voucherData.out_specsName = this.OutStorageData.specsName
            this.voucherData.out_unit = this.OutStorageData.unit
            this.voucherData.out_number = this.OutStorageData.number
            this.voucherData.out_unitPrice = this.OutStorageData.unitPrice
            this.voucherData.out_money = this.OutStorageData.money
            setTimeout(this.toSaveVoucher, 500)
          }
        })
      }else if(this.voucherData.maketype === "资产"){
        this.$refs["AssetDataForm"].validate((valid) => {
          if (valid) {
            this.voucherData.a_assetType = this.AssetData.assetType
            this.voucherData.a_obtainYearMonth = this.AssetData.obtainYearMonth
            this.voucherData.a_useDepartment = this.AssetData.useDepartment
            this.voucherData.a_assetName = this.AssetData.assetName
            this.voucherData.a_unit = this.AssetData.unit
            this.voucherData.a_num = this.AssetData.num
            this.voucherData.a_unitPrice = this.AssetData.unitPrice
            this.voucherData.a_properthOriginal = this.AssetData.properthOriginal
            this.voucherData.a_life = this.AssetData.life
            this.voucherData.a_residualRate = this.AssetData.residualRate
            this.voucherData.a_properthResidual = this.AssetData.properthResidual
            this.voucherData.a_depreciationType = this.AssetData.depreciationType
            this.voucherData.a_provisionTotal = this.AssetData.provisionTotal
            this.voucherData.a_properthBalance = this.AssetData.properthBalance
            this.voucherData.a_provisionMonth = this.AssetData.provisionMonth
            this.voucherData.a_yearDepreciationRate = this.AssetData.yearDepreciationRate
            this.voucherData.a_gzl = this.AssetData.gzl
            setTimeout(this.toSaveVoucher, 500)
          }
        })
      }else if(this.voucherData.maketype ==="负债"){
        this.$refs["liabilitiesDataForm"].validate((valid) => {
          if (valid) {
            this.voucherData.lia_loan_money = this.liabilitiesData.loan_money
            this.voucherData.lia_return_money = this.liabilitiesData.return_money
            this.voucherData.lia_residue_money = this.liabilitiesData.residue_money
            this.voucherData.lia_annual_interest_rate = this.liabilitiesData.annual_interest_rate
            setTimeout(this.toSaveVoucher, 500)
          }
        })
      }else{
        this.toSaveVoucher()
      }

    },
    toSaveVoucher(){
      this.$refs["voucherDataForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/voucher/savevoucherdata", this.voucherData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存成功", type: "success"})
                  this.loadVoucher()
                  this.loadSubjectData()
                  if(this.voucherTitle ==='新增凭证'){
                    this.getMaxVoucherNum('')
                  }
                } else {
                  this.$alert(response.data.result, "保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "保存出错")
              })
        }
        else
          return false
      })
    },
    toEdit(data, index) {
      this.voucherTitle = "编辑凭证"
      this.dailVisi = true
      this.currentRow = index
      this.pla_expenditureMoney= ""
      this.pla_revenueMoney= ""
      this.voucherData.voucherId = data.VOUCHER_ID
      this.voucherData.abstract = data.ABSTRACT
      this.voucherData.subjectSort = data.SUBJECT_SORT.toString()
      this.voucherData.subjectDetail = data.SUBJECT_DETAIL
      this.voucherData.revExpType = data.REV_EXP_TYPE.toString()
      this.voucherData.revExpName = data.REV_EXP_TYPE_NAME
      this.voucherData.revExpDetail = data.REV_EXP_DETAIL
      this.voucherData.revenueMoney = data.REVENUE_MONEY
      this.voucherData.expenditureMoney = data.EXPENDITURE_MONEY
      this.voucherData.document = data.DOCUMENT
      this.voucherData.taxRate = data.TAX_RATE
      this.voucherData.num = data.VOUCHER_NUM
      this.voucherData.recordTime = data.RECORD_TIME
      this.voucherData.taxation = data.TAXATION
      this.voucherData.voucherImage = []
      this.voucherData.remark = data.REMARK
      this.dis_expmoney = false
      this.dis_revmoney = false
      this.getSubjectName("edit")
      this.fileList = data.voucherImage
      if (this.$refs.voucherDataForm)
        this.$refs.voucherDataForm.clearValidate()
      if (this.$refs.inStorageDataForm)
        this.$refs.inStorageDataForm.clearValidate()
      if (this.$refs.OutStorageDataForm)
        this.$refs.OutStorageDataForm.clearValidate()
      if (this.$refs.AssetDataForm)
        this.$refs.AssetDataForm.clearValidate()
      this.showInStorage = false
      this.showOutStorage = false
      this.showAsset = false
      this.showLiabilities = false
      this.getBalanceMoney()
      this.getBalanceMoney1()
      if((data.SUB_NATURE_NAME !== "备抵") && (data.SUB_NATURE_NAME === "存货" || data.SUB_NATURE_NAME === "物资" || data.REV_NATURE_NAME === "存货" || data.REV_NATURE_NAME === "物资")) {
        this.getBalanceMoney()
        if((data.SUB_NATURE_NAME === "存货" || data.SUB_NATURE_NAME === "物资") && (data.REV_NATURE_NAME === "存货" || data.REV_NATURE_NAME === "物资")){
          this.showInStorage = true
          this.showOutStorage = true
          this.voucherData.maketype = "出入库"
          let s = data.InStorageData
          if(s !== null) {
            this.InStorageData.specsName = s.SPECS_TYPE
            this.InStorageData.unit = s.UNIT
            this.InStorageData.number = s.IN_STORAGE_NUM
            this.InStorageData.unitPrice = s.IN_STORAGE_UNIT
            this.InStorageData.money = s.IN_STORAGE_MONEY
          }
          let o = data.OutStorageData
          if(o !== null) {
            this.OutStorageData.specsName = o.SPECS_TYPE
            this.OutStorageData.unit = o.UNIT
            this.OutStorageData.number = o.OUT_STORAGE_NUM
            this.OutStorageData.unitPrice = o.OUT_STORAGE_UNIT
            this.OutStorageData.money = o.OUT_STORAGE_MONEY
          }
        }else if((data.SUB_NATURE_NAME !== "存货" && data.SUB_NATURE_NAME !== "物资") && (data.REV_NATURE_NAME === "存货" || data.REV_NATURE_NAME === "物资")){
          if(data.EXPENDITURE_MONEY !== null && data.EXPENDITURE_MONEY !== "") {
            this.showInStorage = true
            this.voucherData.maketype = "入库"
            let s = data.InStorageData
            if(s !== null) {
              this.InStorageData.specsName = s.SPECS_TYPE
              this.InStorageData.unit = s.UNIT
              this.InStorageData.number = s.IN_STORAGE_NUM
              this.InStorageData.unitPrice = s.IN_STORAGE_UNIT
              this.InStorageData.money = s.IN_STORAGE_MONEY
            }
          }else if(data.REVENUE_MONEY !== null && data.REVENUE_MONEY !== ""){
            this.showOutStorage = true
            this.voucherData.maketype = "出库"
            let s = data.OutStorageData
            if(s !== null) {
              this.OutStorageData.specsName = s.SPECS_TYPE
              this.OutStorageData.unit = s.UNIT
              this.OutStorageData.number = s.OUT_STORAGE_NUM
              this.OutStorageData.unitPrice = s.OUT_STORAGE_UNIT
              this.OutStorageData.money = s.OUT_STORAGE_MONEY
            }
          }
        }
        else{
          if(Number(data.EXPENDITURE_MONEY) > 0){
            this.showOutStorage = true
            this.voucherData.maketype = "出库"
            let s = data.OutStorageData
            if(s !== null) {
              this.OutStorageData.specsName = s.SPECS_TYPE
              this.OutStorageData.unit = s.UNIT
              this.OutStorageData.number = s.OUT_STORAGE_NUM
              this.OutStorageData.unitPrice = s.OUT_STORAGE_UNIT
              this.OutStorageData.money = s.OUT_STORAGE_MONEY
            }
          }else if(Number(data.REVENUE_MONEY) > 0){
            this.showInStorage = true
            this.voucherData.maketype = "入库"
            let s = data.InStorageData
            if(s !== null) {
              this.InStorageData.specsName = s.SPECS_TYPE
              this.InStorageData.unit = s.UNIT
              this.InStorageData.number = s.IN_STORAGE_NUM
              this.InStorageData.unitPrice = s.IN_STORAGE_UNIT
              this.InStorageData.money = s.IN_STORAGE_MONEY
            }
          }
        }
      }else if(((data.SUB_NATURE_NAME === "资产" || data.SUB_NATURE_NAME === "摊销") && (Number(data.REVENUE_MONEY) > 0 || Number(data.EXPENDITURE_MONEY) > 0))
          || (data.REV_NATURE_NAME === "资产" || data.REV_NATURE_NAME === "摊销") && (Number(data.REVENUE_MONEY) > 0 || Number(data.EXPENDITURE_MONEY) > 0)) {
        this.showAsset = true
        this.voucherData.maketype = "资产"
        let a = data.AssetData
        if(a != null) {
          this.AssetData.assetType = a.ASSET_TYPE
          this.AssetData.obtainYearMonth = a.OBTAIN_YEAR_MONTH
          this.AssetData.useDepartment = a.USE_DEPARTMENT
          this.AssetData.assetName = a.ASSET_NAME
          this.AssetData.unit = a.UNIT
          this.AssetData.num = a.NUMBER
          this.AssetData.unitPrice = a.UNIT_PRICE
          this.AssetData.properthOriginal = a.PROPERTH_ORIGINAL
          this.AssetData.life = a.LIFE
          this.AssetData.residualRate = a.RESIDUAL_RATE
          this.AssetData.properthResidual = a.PROPERTH_RESIDUAL
          this.AssetData.depreciationType = a.DEPRECIATION_TYPE
          this.AssetData.provisionTotal = a.PROVISION_TOTAL
          this.AssetData.properthBalance = a.PROPERTH_BALANCE
          this.AssetData.provisionMonth = a.PROVISION_MONTH
          this.AssetData.yearDepreciationRate = a.YEAR_DEPRECIATION_RATE
          this.AssetData.gzl = a.GZL
        }
      }else if(this.voucherData.revenueMoney !== "" && (data.SUB_NATURE_NAME === "付息" || data.REV_NATURE_NAME === "付息")) {
        this.showLiabilities = true
        this.voucherData.maketype = "负债"
        let a = data.LiabilitiesData
        if(a !== null) {
          this.liabilitiesData.loan_money = a.LOAN_MONEY
          this.liabilitiesData.return_money = a.RETURN_MONEY
          this.liabilitiesData.residue_money = a.RESIDUE_MONEY
          this.liabilitiesData.annual_interest_rate = a.ANNUAL_INTEREST_RATE
        }
      }
    },
    toPage(d){
      var data = []
      if(this.currentRow !== ""){
        if(d === "last"){
          if(this.currentRow === 0){
            this.$message({message: "已经是第一页了", type: "warning"})
          }else{
            this.currentRow = Number(this.currentRow) - 1
            data = this.voucherDataList[Number(this.currentRow)]
            this.toEdit(data, this.currentRow)
          }
        }else if(d === "next"){
          if(this.currentRow === this.voucherDataList.length-1){
            this.$message({message: "已经是最后一页了", type: "warning"})
          }else{
            this.currentRow = Number(this.currentRow) + 1
            data = this.voucherDataList[Number(this.currentRow)]
            this.toEdit(data, this.currentRow)
          }
        }
      }
    },
    toDel(data) {
      this.$confirm("确认删除此凭证吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/voucher/delvoucherdata", {voucherId: data.VOUCHER_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadVoucher()
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
      })
    },
    /**
     * properthResidual     资产残值
     * yearDepreciationRate 年折旧率
     * residualRate         残值率
     * life                 使用年限
     * provisionMonth       当月折旧金额
     * properthOriginal     资产原值
     * provisionTotal       累计计提折旧额
     * properthBalance      资产账面余额
     * **/
    getProperthResidual(){
      if(this.AssetData.num !== "" && this.AssetData.unitPrice !== ""){
        this.AssetData.properthOriginal = Number(this.AssetData.num) * (Number(this.AssetData.unitPrice))
      }
      if(this.AssetData.properthOriginal !== "" && this.AssetData.residualRate !== ""){
        this.AssetData.properthResidual = Number(this.AssetData.properthOriginal) * (Number(this.AssetData.residualRate)/100)
      }
      if(this.AssetData.assetType !== "" && this.AssetData.obtainYearMonth !== ""){
        const start = new Date(this.AssetData.obtainYearMonth);
        const end = new Date(); // 当前日期
        const years = (end.getFullYear() - start.getFullYear()) * 12; // 计算年份差值
        const months = years + (end.getMonth() - start.getMonth()); // 加上月份差值
        if(this.AssetData.assetType ==="1"){ // 固定资产
          this.getMonth = months
        }else if(this.AssetData.assetType ==="2" || this.AssetData.assetType ==="3"){// 无形资产
          this.getMonth = months+1
        }
      }

      if(this.getMonth !== "" && this.AssetData.properthOriginal !== ""
          && this.AssetData.properthResidual !== ""
          && this.AssetData.life !== ""
          && this.AssetData.residualRate !== ""){
        if(this.AssetData.depreciationType === "1" ){ //年限平均法
          this.AssetData.yearDepreciationRate = ((1 - (Number(this.AssetData.residualRate)/100)) / Number(this.AssetData.life)).toFixed(4) * 100
          if(this.getMonth ===  0){
            this.AssetData.provisionMonth = 0
          }else{
            this.AssetData.provisionMonth = parseInt(Number(this.AssetData.properthOriginal) * (Number(this.AssetData.yearDepreciationRate) / 100) / 12)
          }
          this.AssetData.provisionTotal = Number(this.getMonth * this.AssetData.provisionMonth)
          this.AssetData.properthBalance = Number(this.AssetData.properthOriginal) - Number(this.AssetData.provisionTotal)
        }
        else if(this.AssetData.depreciationType === "2"){ //工作量法
          if(this.AssetData.gzl !== ""){
            this.AssetData.yearDepreciationRate = ((Number(this.AssetData.properthOriginal) - Number(this.AssetData.properthResidual)) / Number(this.AssetData.life)).toFixed(4)
            if(this.getMonth ===  0){
              this.AssetData.provisionMonth = 0
            }else{
              this.AssetData.provisionMonth = parseInt((Number(this.AssetData.yearDepreciationRate)) * Number(this.AssetData.gzl))
            }
            this.AssetData.provisionTotal = Number(this.getMonth * this.AssetData.provisionMonth)
            this.AssetData.properthBalance = Number(this.AssetData.properthOriginal) - Number(this.AssetData.provisionTotal)
          }
        }
        else if(this.AssetData.depreciationType === "3"){ //双倍余额递减法
          this.AssetData.yearDepreciationRate = (2 / Number(this.AssetData.life)).toFixed(6) *100
          let k = 0 //累计折旧额
          let n = 0
          for (let i = 1; i <= parseInt((this.getMonth +11) / 12); i++){
            k = k + n
            if(Number(this.AssetData.life) - i < 2){
              n = (Number(this.AssetData.properthOriginal) - k) / 2 / 12
            }else{
              n = (Number(this.AssetData.properthOriginal) - k) * 2 / Number(this.AssetData.life)
            }
          }
          if(Number(this.AssetData.life) - parseInt((this.getMonth +11) / 12) < 2){
            this.AssetData.provisionMonth = ((Number(this.AssetData.properthOriginal) - k - Number(this.AssetData.properthResidual)) / 2 /12).toFixed(0)
          }else{
            this.AssetData.provisionMonth = ((Number(this.AssetData.properthOriginal) - k) * (Number(this.AssetData.yearDepreciationRate)/100) /12).toFixed(0)
          }
          this.AssetData.provisionTotal = parseInt(k)
          this.AssetData.properthBalance = Number(this.AssetData.properthOriginal) - Number(this.AssetData.provisionTotal)

        }else if(this.AssetData.depreciationType === "4"){ //年数总和法
          let useYear = Number(this.AssetData.life) - parseInt((this.getMonth-12) / 12)
          let yearTotal = 0
          for (let i = 0; i <= Number(this.AssetData.life); i++){
            yearTotal = yearTotal + i
          }
          this.AssetData.yearDepreciationRate = (useYear / yearTotal * 100).toFixed(4)
          if(this.getMonth ===  0){
            this.AssetData.provisionMonth = 0
          }else{
            this.AssetData.provisionMonth = ((Number(this.AssetData.properthOriginal) - Number(this.AssetData.properthResidual)) * (Number(this.AssetData.yearDepreciationRate) /100) /12).toFixed(0)
          }
          this.AssetData.provisionTotal = Number(this.getMonth * this.AssetData.provisionMonth)
          this.AssetData.properthBalance = Number(this.AssetData.properthOriginal) - Number(this.AssetData.provisionTotal)
        }else if(this.AssetData.depreciationType === "5"){ //资产摊销
          this.AssetData.yearDepreciationRate = ""
          if(this.getMonth ===  0){
            this.AssetData.provisionMonth = 0
          }else{
            this.AssetData.provisionMonth = (Number(this.AssetData.properthOriginal) / (Number(this.AssetData.life)*12)).toFixed(0)
          }
          this.AssetData.provisionTotal = ""
          this.AssetData.properthBalance = ""
        }
      }
    },

    getTreeName(list,value){
      for (let i = 0; i < list.length; i++) {
        if(list[i].value===value){
          return list[i].text
        }else if(list[i].children && list[i].children.length>0){
          let res=this.getTreeName(list[i].children,value)
          if(res){
            return res
          }
        }
      }
    },

    doExport () {
      this.search.organname= this.getTreeName(this.OrganTreeData, this.search.organOrDep)
      this.exportTip = "导出Excel中..."
      this.$axios.post("/api/voucher/exportvoucherdata", this.search, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 3000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "凭证数据导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "凭证数据导出出错")
          })
    },
//限制用户上传文件的格式和大小
    handlebe(rawFile) {
      return new Promise((resolve, reject) => {
        const filename = rawFile.name
        if (rawFile.size / 1024 / 1024 > 10) {
          ElMessage.error('文件必须小于10MB')
          this.ifup = false
          reject(false)
        }
        this.voucherData.voucherImage.forEach(function (item) {
          if (item["name"] === filename) {
            ElMessage.error('同名文件已存在')
            this.ifup = false
            reject(false)
          }
        })
        this.uploadAction = apiURL + "/api/voucher/uploadtempfile"
        this.headerObj.Authorization = localStorage.getItem('sessionId')
        resolve()
        this.ifup = true
      })
    },
    error(err){
      this.ifup = false
      this.$message({message:err, type: "false"})
    },
    uploadSuccess(){
      this.ifup = false
    },
    //预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = ""
      this.picsee = false
      var suffix = file.name.substring(file.name.lastIndexOf("."))
      this.dialogVisible = true;
      this.$axios.post("/api/voucher/loadtemppic", {fname:file.name})
          .then((response) => {
            if (response.data.success) {
              if (suffix===".jpg"||suffix===".jpeg"||suffix===".png") {
                this.picsee = true
                this.dialogImageUrl = response.data.result.url
              }
              else{
                this.$alert(response.data.result, "该格式无法预览")
              }
            } else {
              this.$alert(response.data.result, "获取预览图出错")
            }
            this.tfjname = file.name
          })
          .catch((error) => {
            this.$alert(error, "获取预览图出错")
          })
    },
    //删除
    onRemove(file) {
      return new Promise((resolve, reject) => {
        this.$axios.post("/api/voucher/deltemp", {filename: file.name})
            .then((response) => {
              if (response.data.success) {
                resolve()
                let removeId = file.uid
                this.voucherData.voucherImage.forEach((item, index) => {
                  if (item.uid === removeId) {
                    this.voucherData.voucherImage.splice(index, 1)
                  }
                })
              } else {
                this.$alert(response.data.result, "删除出错")
                reject(false)
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
              reject(false)
            })
      })
    },
    toExamine(data){
      this.$axios.post("/api/voucher/examinevoucherdata", {voucherId: data.VOUCHER_ID})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "审核成功", type: "success"})
              this.loadVoucher()
            }
            else {
              this.$alert(response.data.result, "审核出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "审核出错")
          })
    },
    toRemoveExamine(data){
      this.$axios.post("/api/voucher/removeexaminevoucher", {voucherId: data.VOUCHER_ID})
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "取消审核成功", type: "success"})
              this.loadVoucher()
            }
            else {
              this.$alert(response.data.result, "取消审核出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "取消审核出错")
          })
    },
  }
}
</script>

<style scoped>

.div_img{
  width: 20px;
  height: 20px;
  margin: 0 2px;
}
.img{
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius:5px;
}

:deep(.el-upload--picture-card) {
  width: 80px;
  height: 80px;
}
:deep(.el-upload) {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
:deep(.el-upload-list--picture-card .el-upload-list__item){
  width: 80px;
  height: 80px;
  line-height: 80px;
}
:deep(.el-upload-list--picture-card .el-upload-list__item-thumbnail){
  width: 80px;
  height: 80px;
  line-height: 80px;
}

</style>
