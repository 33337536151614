<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>资产管理</el-breadcrumb-item>
        <el-breadcrumb-item>库存查询</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row style="margin-bottom: 10px">
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadInventoryBase()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadInventoryBase($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="15">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport" :loading="exportTip !== ''">
              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
            </el-button>
<!--            <el-button type="success" @click="doInventoryTransfer" style="margin-left: 10px;">-->
<!--              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>月末库存结转</span>-->
<!--            </el-button>-->

            <el-button type="success" :loading="loading" @click="doInventoryTransfer">{{loading ? '结转中 ...' : '月末库存结转'}}</el-button>

            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="2" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="InventoryQueryList"
            border
            :cell-style="cellStyleMethod"
            :span-method="objectSpanMethod">
          <el-table-column
              prop="GOODS_TYPE_NAME"
              label="商品分类"
              align="center"
              show-overflow-tooltip
              min-width="75px"
          >
          </el-table-column>
          <el-table-column
              prop="SPECS_NAME"
              label="规格名称"
              align="center"
              show-overflow-tooltip
              min-width="75px"
          >
          </el-table-column>
          <el-table-column
              prop="GOODS_NAME"
              label="商品名称"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="UNIT_NAME"
              label="单位"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="init_num"
              label="期初库存"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="init_unit"
              label="期初单价"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="init_money"
              label="期初结余"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column label="入库" align="center">
            <el-table-column
                prop="in_num"
                label="数量"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="in_unit"
                label="单价"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="in_money"
                label="金额"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="出库" align="center">
            <el-table-column
                prop="out_num"
                label="数量"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="out_unit"
                label="单价"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="out_money"
                label="金额"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="realTimeNum"
            label="库存结余"
            show-overflow-tooltip
            align="center"
            min-width="80px"
        >
        </el-table-column>
          <el-table-column
              prop="moveUnit"
              label="加权单价"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="balanceMoney"
              label="库存金额"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
        </el-table>

        <el-drawer :size="1200"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px);">
            <iframe :src="pdffile" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";
export default {
  name: "assetStorageQuery",
  data() {
    return{
      tableHeight: 0,
      search:{
        recordMonth:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      InventoryQueryList:[],
      exportTip: "",
      pdffile:"",
      drawerVisi:false,
      loading:false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadTree()
      this.loadInventoryBase()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    current(d) {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if (d === "month")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if (d === "day")
        return date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    loadTree(){
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    loadInventoryBase(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/asset/loadInventoryQuery", this.search)
          .then((response) => {
            if (response.data.success) {
              this.InventoryQueryList = response.data.result
              this.formatData(this.InventoryQueryList)
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取商品出入库基数列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取商品出入库基数列表出错")
          })
    },

    formatData(info) {
      let data = JSON.parse(JSON.stringify(info));
      let temp = {};
      let pos = 0
      for (let i = 0; i < data.length; i++) {
        if (temp[data[i].GOODS_TYPE]) {
          temp[data[i].GOODS_TYPE]['initNum'] += parseFloat(data[i].initNum);
          temp[data[i].GOODS_TYPE]['initMoney'] += parseFloat(data[i].initMoney);
          temp[data[i].GOODS_TYPE]['inNum'] += parseFloat(data[i].inNum);
          temp[data[i].GOODS_TYPE]['inMoney'] += parseFloat(data[i].inMoney);
          temp[data[i].GOODS_TYPE]['outNum'] += parseFloat(data[i].outNum);
          temp[data[i].GOODS_TYPE]['outMoney'] += parseFloat(data[i].outMoney);
          temp[data[i].GOODS_TYPE]['realTimeNum'] += parseFloat(data[i].realTimeNum);
          temp[data[i].GOODS_TYPE]['balanceMoney'] += parseFloat(data[i].balanceMoney);
        } else {
          temp[data[i].GOODS_TYPE] = {};
          temp[data[i].GOODS_TYPE]['initNum'] = parseFloat(data[i].initNum);
          temp[data[i].GOODS_TYPE]['initMoney'] = parseFloat(data[i].initMoney);
          temp[data[i].GOODS_TYPE]['inNum'] = parseFloat(data[i].inNum);
          temp[data[i].GOODS_TYPE]['inMoney'] = parseFloat(data[i].inMoney);
          temp[data[i].GOODS_TYPE]['outNum'] = parseFloat(data[i].outNum);
          temp[data[i].GOODS_TYPE]['outMoney'] = parseFloat(data[i].outMoney);
          temp[data[i].GOODS_TYPE]['realTimeNum'] = parseFloat(data[i].realTimeNum);
          temp[data[i].GOODS_TYPE]['balanceMoney'] = parseFloat(data[i].balanceMoney);
        }
        if (i > 0 && data[i].GOODS_TYPE !== data[i - 1].GOODS_TYPE) {
          info.splice(i+pos, 0, {
            GOODS_TYPE_NAME: '合计',
            init_num: temp[data[i - 1].GOODS_TYPE]['initNum'].toFixed(2).replace(/\.00$/,''),
            init_money: temp[data[i - 1].GOODS_TYPE]['initMoney'].toFixed(2).replace(/\.00$/,''),

            in_num: temp[data[i - 1].GOODS_TYPE]['inNum'].toFixed(2).replace(/\.00$/,''),
            in_money: temp[data[i - 1].GOODS_TYPE]['inMoney'].toFixed(2).replace(/\.00$/,''),
            out_num: temp[data[i - 1].GOODS_TYPE]['outNum'].toFixed(2).replace(/\.00$/,''),
            out_money: temp[data[i - 1].GOODS_TYPE]['outMoney'].toFixed(2).replace(/\.00$/,''),
            realTimeNum: temp[data[i - 1].GOODS_TYPE]['realTimeNum'].toFixed(2).replace(/\.00$/,''),
            balanceMoney: temp[data[i - 1].GOODS_TYPE]['balanceMoney'].toFixed(2).replace(/\.00$/,''),
          });
          if(i === data.length - 1){
            info.push({
              GOODS_TYPE_NAME: '合计',
              init_num: temp[data[i].GOODS_TYPE]['initNum'].toFixed(2).replace(/\.00$/,''),
              init_money: temp[data[i].GOODS_TYPE]['initMoney'].toFixed(2).replace(/\.00$/,''),
              in_num: temp[data[i].GOODS_TYPE]['inNum'].toFixed(2).replace(/\.00$/,''),
              in_money: temp[data[i].GOODS_TYPE]['inMoney'].toFixed(2).replace(/\.00$/,''),
              out_num: temp[data[i].GOODS_TYPE]['outNum'].toFixed(2).replace(/\.00$/,''),
              out_money: temp[data[i].GOODS_TYPE]['outMoney'].toFixed(2).replace(/\.00$/,''),
              realTimeNum: temp[data[i].GOODS_TYPE]['realTimeNum'].toFixed(2).replace(/\.00$/,''),
              balanceMoney: temp[data[i].GOODS_TYPE]['balanceMoney'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
        }else if (i === data.length - 1) {
          if(i === 0){
            info.push({
              GOODS_TYPE_NAME: '合计',
              init_num: temp[data[i].GOODS_TYPE]['initNum'].toFixed(2).replace(/\.00$/,''),
              init_money: temp[data[i].GOODS_TYPE]['initMoney'].toFixed(2).replace(/\.00$/,''),
              in_num: temp[data[i].GOODS_TYPE]['inNum'].toFixed(2).replace(/\.00$/,''),
              in_money: temp[data[i].GOODS_TYPE]['inMoney'].toFixed(2).replace(/\.00$/,''),
              out_num: temp[data[i].GOODS_TYPE]['outNum'].toFixed(2).replace(/\.00$/,''),
              out_money: temp[data[i].GOODS_TYPE]['outMoney'].toFixed(2).replace(/\.00$/,''),
              realTimeNum: temp[data[i].GOODS_TYPE]['realTimeNum'].toFixed(2).replace(/\.00$/,''),
              balanceMoney: temp[data[i].GOODS_TYPE]['balanceMoney'].toFixed(2).replace(/\.00$/,''),
            });
          }else{
            info.push({
              GOODS_TYPE_NAME: '合计',
              init_num: temp[data[i - 1].GOODS_TYPE]['initNum'].toFixed(2).replace(/\.00$/,''),
              init_money: temp[data[i - 1].GOODS_TYPE]['initMoney'].toFixed(2).replace(/\.00$/,''),
              in_num: temp[data[i - 1].GOODS_TYPE]['inNum'].toFixed(2).replace(/\.00$/,''),
              in_money: temp[data[i - 1].GOODS_TYPE]['inMoney'].toFixed(2).replace(/\.00$/,''),
              out_num: temp[data[i - 1].GOODS_TYPE]['outNum'].toFixed(2).replace(/\.00$/,''),
              out_money: temp[data[i - 1].GOODS_TYPE]['outMoney'].toFixed(2).replace(/\.00$/,''),
              realTimeNum: temp[data[i - 1].GOODS_TYPE]['realTimeNum'].toFixed(2).replace(/\.00$/,''),
              balanceMoney: temp[data[i - 1].GOODS_TYPE]['balanceMoney'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
        }
      }
      let init_num = 0;
      let init_money = 0;
      let in_num = 0;
      let in_money = 0;
      let out_num = 0;
      let out_money = 0;
      let realTimeNum = 0;
      let balanceMoney = 0;
      for (let key in data) {
        init_num += parseFloat(data[key].initNum);
        init_money += parseFloat(data[key].initMoney);
        in_num += parseFloat(data[key].inNum);
        in_money += parseFloat(data[key].inMoney);
        out_num += parseFloat(data[key].outNum);
        out_money += parseFloat(data[key].outMoney);
        realTimeNum += parseFloat(data[key].realTimeNum);
        balanceMoney += parseFloat(data[key].balanceMoney);
      }
      info.push({
        GOODS_TYPE_NAME: '总计',
        init_num: init_num.toFixed(2).replace(/\.00$/,''),
        init_money: init_money.toFixed(2).replace(/\.00$/,''),
        in_num: in_num.toFixed(2).replace(/\.00$/,''),
        in_money: in_money.toFixed(2).replace(/\.00$/,''),
        out_num: out_num.toFixed(2).replace(/\.00$/,''),
        out_money: out_money.toFixed(2).replace(/\.00$/,''),
        realTimeNum: realTimeNum.toFixed(2).replace(/\.00$/,''),
        balanceMoney: balanceMoney.toFixed(2).replace(/\.00$/,''),
      });
    },

    toRefresh() {
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.search.s_organid = ""
      this.search.s_depid = ""
      this.loadInventoryBase("refresh")
    },

    doMonthTransfer(){
      this.$confirm("确认月末盘点吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/asset/domonthtransfer", {data: this.monthReportDetail, recordMonth:this.search.recordMonth})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "月末结转成功", type: "success"})
              }
              else {
                this.$alert(response.data.result, "月末结转出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "月末结转出错")
            })
      })
    },
    cellStyleMethod({ row, columnIndex }) {
      if (row.GOODS_TYPE_NAME === '总计' || row.GOODS_TYPE_NAME === '合计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f5f7fa' };
      }
      if(columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3){
        return { 'background':'#fff'};
      }else if (columnIndex === 4 || columnIndex === 5 || columnIndex === 6) {
        return { 'background':'#ebf6f9' };
      }else if (columnIndex === 7 || columnIndex === 8 || columnIndex === 9) {
        return { 'background':'#f0f9eb' };
      }else if (columnIndex === 10 || columnIndex === 11 || columnIndex === 12) {
        return { 'background':'#f9f7eb' };
      }
      else if (columnIndex === 13 || columnIndex === 14 || columnIndex === 15) {
        return { 'background':'#faf0e5' };
      }
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.GOODS_TYPE_NAME === '总计' || row.GOODS_TYPE_NAME === '合计') {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.InventoryQueryList)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
        if (columnIndex === 1) {
          let spanArr = this.getSpanArr1(this.InventoryQueryList)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.GOODS_TYPE === arr[index - 1].GOODS_TYPE) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    getSpanArr1(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SPECS_NAME === arr[index - 1].SPECS_NAME) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    doInventoryTransfer(){
      const that = this;
      this.$confirm("确认月末库存结转吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        that.loading = true
        this.$axios.post("/api/asset/doinventorytransfer", {data: this.InventoryQueryList, recordMonth:this.search.recordMonth})
            .then((response) => {
              if (response.data.success) {
                that.loading = false
                this.$message({message: "月末结转成功", type: "success"})
              }
              else {
                that.loading = false
                this.$alert(response.data.result, "月末结转出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "月末结转出错")
            })
      })
    },

    //导出
    doExport () {
      this.exportTip = "库存物资数据导出Excel中..."
      this.$axios.post("/api/asset/exportinventorydata", {data: this.InventoryQueryList, recordMonth:this.search.recordMonth}, {
        responseType: "blob"
      })
          .then((response) => {
            this.exportTip = ""
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "库存数据导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "库存数据导出出错")
          })
    },

    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/asset/printinventorydata?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid
    },
  },

}
</script>

<style scoped>

</style>
