<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>报表查询</el-breadcrumb-item>
        <el-breadcrumb-item>出纳资金月报表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row style="margin-bottom: 10px">
          <el-col :span="2" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY-MM"
                @change="loadData()"
            />
          </el-col>
          <el-col :span="2" class="input-search">
            <el-date-picker
                v-model="search.endMonth"
                type="month"
                placeholder="结束年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY-MM"
                @change="loadData()"
            />
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectType" placeholder="请选择科目类别" @change="loadData" clearable>
              <el-option
                  v-for="item in subjectTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择会计科目"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="changeDetail"
                :options="treeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectDetail" placeholder="请选择科目明细" clearable @change="loadData()" :disabled="disabled">
              <el-option
                  v-for="item in subjectDetailList"
                  :key="item.SUBJECT_DETAIL"
                  :label="item.SUBJECT_DETAIL"
                  :value="item.SUBJECT_DETAIL">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-dropdown>
              <el-button type="primary" >
                <i class="iconfont icon-print" style="font-size: 14px"></i><span style="margin-right: 5px">打印 </span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-link @click="toPrint('detail')" type="primary" :underline="false" >
                      <i class="iconfont icon-print-full"></i>打印明细
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link @click="toPrint('summary')" type="primary" :underline="false" >
                      <i class="iconfont icon-print-full"></i>打印汇总
                    </el-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-dropdown  style="margin-left: 10px;">
              <el-button type="warning" >
                <i class="iconfont icon-daochu" style="font-size: 14px"></i><span style="margin-right: 5px">导出 </span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-link @click="doExport('detail')" type="warning" :underline="false">
                      <i class="iconfont icon-daochu"></i>导出明细
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link @click="doExport('summary')" type="warning" :underline="false">
                      <i class="iconfont icon-daochu"></i>导出汇总
                    </el-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button type="success" @click="doMonthTransfer" style="margin-left: 10px;">
              <span><i class="iconfont icon-qimojiezhuan-normal" style="font-size: 18px"></i>月末结转</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-tabs type="border-card">
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <i class="iconfont icon-chaxunbaobiaomingxi" style="font-size: 22px;"></i>
                <span>明细查询</span>
              </span>
            </template>
            <el-table
                :height="tableHeight"
                border
                :span-method="objectSpanMethod1"
                :data="monthReportDetail"
                :cell-style="cellStyleMethod1">
              <el-table-column
                  prop="SUBJECT_TYPE_NAME"
                  label="科目类型"
                  show-overflow-tooltip
                  align="center"
                  width="100px"
              >
              </el-table-column>
              <el-table-column
                  prop="SUBJECT_NAME"
                  label="科目名称"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="SUBJECT_DETAIL"
                  label="科目明细"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="total_doc"
                  label="期初余额(元)"
                  align="center"
                  show-overflow-tooltip
                  min-width="60px"
              >
                <template #default="scope">
                  {{ formatNumber(scope.row.total_doc) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="total_rev"
                  label="本月收入(元)"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  <div v-if="scope.row.SUBJECT_TYPE !== '合计' && scope.row.SUBJECT_TYPE !== '总计' ">
                    <el-button type="text" size="default" @click="goDayRecord(scope.row)">{{ formatNumber(scope.row.total_rev) }}</el-button>
                  </div>
                  <div v-else>{{ formatNumber(scope.row.total_rev) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="total_exp"
                  label="本月支出(元)"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
                <template #default="scope">
                  <div v-if="scope.row.SUBJECT_TYPE !== '合计' && scope.row.SUBJECT_TYPE !== '总计' ">
                    <el-button type="text" size="default" @click="goDayRecord(scope.row)">{{ formatNumber(scope.row.total_exp) }}</el-button>
                  </div>
                  <div v-else>{{ formatNumber(scope.row.total_exp) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  label="期末余额(元)"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
                <template #default="scope">
                  {{ calculateTotal(scope.row) }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane>
            <template #label>
              <span class="custom-tabs-label">
                <i class="iconfont icon-zonghebaobiaohuizong" style="font-size: 22px;"></i>
                <span>汇总查询</span>
              </span>
            </template>
            <el-table
                :height="tableHeight"
                :span-method="objectSpanMethod"
                :data="monthReportSummary"
                border
                :cell-style="cellStyleMethod">
              <el-table-column
                  prop="SUBJECT_TYPE_NAME"
                  label="科目类型"
                  show-overflow-tooltip
                  align="center"
                  min-width="60px"
              >
              </el-table-column>
              <el-table-column
                  prop="SUBJECT_NAME"
                  label="科目名称"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="total_doc"
                  label="期初余额(元)"
                  align="center"
                  show-overflow-tooltip
                  min-width="60px"
              >
                <template #default="scope">
                  {{ formatNumber(scope.row.total_doc) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="total_rev"
                  label="本月收入(元)"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
                <template #default="scope">
                  <div v-if="scope.row.SUBJECT_TYPE !== '合计' && scope.row.SUBJECT_TYPE !== '总计' ">
                    <el-button type="text" size="default" @click="goDayRecord(scope.row)">{{ formatNumber(scope.row.total_rev) }}</el-button>
                  </div>
                  <div v-else>{{ formatNumber(scope.row.total_rev) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="total_exp"
                  label="本月支出(元)"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
                <template #default="scope">
                  <div v-if="scope.row.SUBJECT_TYPE !== '合计' && scope.row.SUBJECT_TYPE !== '总计' ">
                    <el-button type="text" size="default" @click="goDayRecord(scope.row)">{{ formatNumber(scope.row.total_exp) }}</el-button>
                  </div>
                  <div v-else>{{ formatNumber(scope.row.total_exp) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  label="期末余额(元)"
                  show-overflow-tooltip
                  align="center"
                  min-width="80px"
              >
                <template #default="scope">
                  {{ calculateTotal(scope.row) }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";
export default {
  name: "MonthFinancialReport",
  data () {
    return {
      tableHeight: 0,
      search:{
        recordMonth:"",
        endMonth:"",
        subjectType:"",
        subjectSort:"",
        subjectDetail:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      monthReportSummary:[],
      monthReportDetail:[],
      exportTip: "",
      subjectTypeData: [],
      treeData:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      subjectDetailList:"",
      disabled:true,
      allSubjectDetail:[],
      pdffile:"",
      drawerVisi:false,
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 160
    },
    toRefresh() {
      this.loadData("refresh")
    },
    calculateTotal(row) {
        if(row.SUBJECT_TYPE_NAME === "损益总计"){
          if(Number(row.total_exp) > 0){
            if(Number(row.total_rev) > 0){
              return this.formatNumber((Number(row.total_doc) + Number(row.total_rev) - Number(row.total_exp)).toFixed(2).replace(/\.00$/,''));
            }else{
              return this.formatNumber((Number(row.total_doc) + Number(row.total_rev) + Number(row.total_exp)).toFixed(2).replace(/\.00$/,''));
            }
           }else{
            if(Number(row.total_rev) > 0){
              return this.formatNumber((Number(row.total_doc) + Number(row.total_rev) + Number(row.total_exp)).toFixed(2).replace(/\.00$/,''));
            }else{
              return this.formatNumber((Number(row.total_doc) + Number(row.total_rev) - Number(row.total_exp)).toFixed(2).replace(/\.00$/,''));
            }
          }
        }else{
          return this.formatNumber((Number(row.total_doc) + Number(row.total_rev) - Number(row.total_exp)).toFixed(2).replace(/\.00$/,''));
        }

    },
    formatNumber(value) {
      // 使用 Intl.NumberFormat 来将数值格式化为千分位显示
      return new Intl.NumberFormat().format(value);
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: [ "SUBJECT_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.subjectTypeData = data.result.SUBJECT_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.post("/api/common/getallsubjectdetail", this.search)
          .then((response) => {
            if (response.data.success) {
              this.allSubjectDetail = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    changeDetail(){
      let f=[]
      if(this.search.subjectSort !== "" && this.search.subjectSort !== null ) {
        this.disabled = false
        f = this.allSubjectDetail.filter((item) => {
          return Number(item.SUBJECT_SORT) === Number(this.search.subjectSort)
        })
      }else{
        this.disabled = true
      }
      this.subjectDetailList = f
      this.search.subjectDetail = ""
      this.loadData()
    },
    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/report/loadsummarydata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.monthReportSummary = response.data.result
              this.formatData(this.monthReportSummary,'summary')
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取资金月报表汇总数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取资金月报表汇总数据出错")
          })

      this.$axios.post("/api/report/loaddetaildata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.monthReportDetail = response.data.result
              this.formatData(this.monthReportDetail,'detail')
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
              console.log(this.monthReportDetail )
            } else {
              this.$alert(response.data.result, "获取资金月报表明细数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取资金月报表明细数据出错")
          })
    },
    cellStyleMethod({ row, columnIndex }) {
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }
      if(columnIndex === 0 ){
        return { 'background':'#f5f7fa'};
      }
    },
    cellStyleMethod1({ row, columnIndex }) {
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }
      if(columnIndex === 0 || columnIndex === 1){
        return { 'background':'#f5f7fa'};
      }
    },
    formatData(info) {
      let data = JSON.parse(JSON.stringify(info));
      let temp = {};
      let pos = 0
      for (let i = 0; i < data.length; i++) {
        if(data[i].total_doc == null)
          data[i].total_doc = 0
        if(data[i].total_rev == null)
          data[i].total_rev = 0
        if(data[i].total_exp == null)
          data[i].total_exp = 0
        if (temp[data[i].SUBJECT_TYPE]) {
          temp[data[i].SUBJECT_TYPE]['total_doc'] += parseFloat(data[i].total_doc);
          temp[data[i].SUBJECT_TYPE]['total_rev'] += parseFloat(data[i].total_rev);
          temp[data[i].SUBJECT_TYPE]['total_exp'] += parseFloat(data[i].total_exp);
        } else {
          temp[data[i].SUBJECT_TYPE] = {};
          temp[data[i].SUBJECT_TYPE]['total_doc'] = parseFloat(data[i].total_doc);
          temp[data[i].SUBJECT_TYPE]['total_rev'] = parseFloat(data[i].total_rev);
          temp[data[i].SUBJECT_TYPE]['total_exp'] = parseFloat(data[i].total_exp);
        }
        if (i > 0 && data[i].SUBJECT_TYPE !== data[i - 1].SUBJECT_TYPE) {
          info.splice(i+pos, 0, {
            SUBJECT_TYPE: '合计',
            SUBJECT_TYPE_NAME: data[i - 1].SUBJECT_TYPE_NAME +'合计',
            SUBJECT_NAME: data[i - 1].SUBJECT_TYPE_NAME +'合计',
            total_doc: temp[data[i - 1].SUBJECT_TYPE]['total_doc'].toFixed(2).replace(/\.00$/,''),
            total_rev: temp[data[i - 1].SUBJECT_TYPE]['total_rev'].toFixed(2).replace(/\.00$/,''),
            total_exp: temp[data[i - 1].SUBJECT_TYPE]['total_exp'].toFixed(2).replace(/\.00$/,''),
          });
          if(i === data.length - 1){
            info.push({
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: data[i].SUBJECT_TYPE_NAME +'合计',
              SUBJECT_NAME: data[i].SUBJECT_TYPE_NAME +'合计',
              total_doc: temp[data[i].SUBJECT_TYPE]['total_doc'].toFixed(2).replace(/\.00$/,''),
              total_rev: temp[data[i].SUBJECT_TYPE]['total_rev'].toFixed(2).replace(/\.00$/,''),
              total_exp: temp[data[i].SUBJECT_TYPE]['total_exp'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
          if(data[i-1].SUBJECT_TYPE_NAME ==="权益" && data[i].SUBJECT_TYPE_NAME !=="权益"){
            let fzqy1 = 0;
            let fzqy2 = 0;
            let fzqy3 = 0;
            for (let key in data) {
              if(data[key].SUBJECT_TYPE_NAME === "负债" || data[key].SUBJECT_TYPE_NAME === "权益"){
                fzqy1 += parseFloat(data[key].total_doc);
                fzqy2 += parseFloat(data[key].total_rev);
                fzqy3 += parseFloat(data[key].total_exp);
              }
            }
            info.splice(i+pos, 0, {
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: '负债及所有者权益合计',
              SUBJECT_NAME: '负债及所有者权益合计',
              total_doc: fzqy1,
              total_rev: fzqy2,
              total_exp: fzqy3,
            });
            pos++
          }
        }else if (i === data.length - 1) {
          if(i === 0){
            info.push({
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: data[i].SUBJECT_TYPE_NAME +'合计',
              SUBJECT_NAME: data[i].SUBJECT_TYPE_NAME +'合计',
              total_doc: temp[data[i].SUBJECT_TYPE]['total_doc'].toFixed(2).replace(/\.00$/,''),
              total_rev: temp[data[i].SUBJECT_TYPE]['total_rev'].toFixed(2).replace(/\.00$/,''),
              total_exp: temp[data[i].SUBJECT_TYPE]['total_exp'].toFixed(2).replace(/\.00$/,''),
            });
          }else{
            info.push({
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: data[i - 1].SUBJECT_TYPE_NAME +'合计',
              SUBJECT_NAME: data[i - 1].SUBJECT_TYPE_NAME +'合计',
              total_doc: temp[data[i - 1].SUBJECT_TYPE]['total_doc'].toFixed(2).replace(/\.00$/,''),
              total_rev: temp[data[i - 1].SUBJECT_TYPE]['total_rev'].toFixed(2).replace(/\.00$/,''),
              total_exp: temp[data[i - 1].SUBJECT_TYPE]['total_exp'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
          if(data[i].SUBJECT_TYPE_NAME ==="权益"){
            let fzqy1 = 0;
            let fzqy2 = 0;
            let fzqy3 = 0;
            for (let key in data) {
              if(data[key].SUBJECT_TYPE_NAME === "负债" || data[key].SUBJECT_TYPE_NAME === "权益"){
                fzqy1 += parseFloat(data[key].total_doc);
                fzqy2 += parseFloat(data[key].total_rev);
                fzqy3 += parseFloat(data[key].total_exp);
              }
            }
            info.splice(i+pos+1, 0, {
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: '负债及所有者权益合计',
              SUBJECT_NAME: '负债及所有者权益合计',
              total_doc: fzqy1,
              total_rev: fzqy2,
              total_exp: fzqy3,
            });
            pos++
          }
        }
      }
      let srhj = 0;
      let cbhj = 0;
      let fyhj = 0;
      let srhj_exp = 0;
      let cbhj_exp = 0;
      let fyhj_exp= 0;

      info.forEach(em => {
        if(em.SUBJECT_TYPE_NAME === "收入合计"){
          srhj = em.total_rev
          srhj_exp = em.total_exp
        }
        if(em.SUBJECT_TYPE_NAME === "成本合计"){
          cbhj = em.total_rev
          cbhj_exp = em.total_exp
        }
        if(em.SUBJECT_TYPE_NAME === "费用合计"){
          fyhj = em.total_rev
          fyhj_exp = em.total_exp
        }
      });

      let totalAmount1 = 0;
      let totalAmount2 = 0;
      let totalAmount3 = 0;
      totalAmount2 = Number(srhj) - Number(cbhj) - Number(fyhj)
      totalAmount3 = Number(cbhj_exp) + Number(fyhj_exp) - Number(srhj_exp)
      for (let key in data) {
        if(data[key].SUBJECT_TYPE_NAME === "费用" || data[key].SUBJECT_TYPE_NAME === "成本"|| data[key].SUBJECT_TYPE_NAME === "收入"){
          totalAmount1 += parseFloat(data[key].total_doc);
        }
      }
      info.push({
        SUBJECT_TYPE: '总计',
        SUBJECT_TYPE_NAME: '损益总计',
        SUBJECT_NAME: '总计',
        total_doc: totalAmount1.toFixed(2).replace(/\.00$/,''),
        total_rev: totalAmount2.toFixed(2).replace(/\.00$/,''),
        total_exp: totalAmount3.toFixed(2).replace(/\.00$/,''),
      });
    },


    //汇总
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.monthReportSummary)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },

    //明细
    objectSpanMethod1({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        if (columnIndex === 0) {
          return [1, 3];
        } else if (columnIndex === 1 || columnIndex === 2) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.monthReportDetail)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
        if (columnIndex === 1) {
          let spanArr = this.getSpanArr1(this.monthReportDetail)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_TYPE=== arr[index - 1].SUBJECT_TYPE) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    getSpanArr1(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_NAME=== arr[index - 1].SUBJECT_NAME) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    //导出
    doExport (type) {
      if(type === "summary"){
        this.exportTip = "汇总资金月报表导出Excel中..."
        this.$axios.post("/api/report/exportsummarydata", {data: this.monthReportSummary, recordMonth:this.search.recordMonth, endMonth:this.search.endMonth}, {
          responseType: "blob"
        })
            .then((response) => {
              setTimeout(this.exportTip = "", 3000)
              const err = response.headers["error-content"]
              if (typeof err === "undefined") {
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
                let fname = response.headers["content-disposition"].split("filename=")[1]
                fname = decodeURIComponent(fname)
                const a = this.$refs.expLink
                a.href = blobUrl
                a.setAttribute("download", fname)
                a.click()
              }
              else
                this.$alert(decodeURIComponent(err), "汇总资金月报表导出出错")
            })
            .catch((error) => {
              this.exportTip = ""
              this.$alert(error, "汇总资金月报表导出出错")
            })
      }else if(type === "detail"){
        this.exportTip = "明细资金月报表导出Excel中..."
        this.$axios.post("/api/report/exportdetaildata",{data: this.monthReportDetail, recordMonth:this.search.recordMonth, endMonth:this.search.endMonth}, {
          responseType: "blob"
        })
            .then((response) => {
              setTimeout(this.exportTip = "", 3000)
              const err = response.headers["error-content"]
              if (typeof err === "undefined") {
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
                let fname = response.headers["content-disposition"].split("filename=")[1]
                fname = decodeURIComponent(fname)
                const a = this.$refs.expLink
                a.href = blobUrl
                a.setAttribute("download", fname)
                a.click()
              }
              else
                this.$alert(decodeURIComponent(err), "明细资金月报表导出出错")
            })
            .catch((error) => {
              this.exportTip = ""
              this.$alert(error, "明细资金月报表导出出错")
            })
      }
    },
    doMonthTransfer(){
      this.$confirm("确认月末结转吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/report/domonthtransfer", {data: this.monthReportDetail, recordMonth:this.search.recordMonth})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "月末结转成功", type: "success"})
              }
              else {
                this.$alert(response.data.result, "月末结转出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "月末结转出错")
            })
      })
    },
    goDayRecord(row){
      this.$router.replace({name: "day-Record-Report", query:{sort:row.SUBJECT_SORT, detail:row.SUBJECT_DETAIL}})
    },

    toPrint (type){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      if(type === "detail"){
        this.pdffile = apiURL+"/api/report/printmonthtransferdetail?recordMonth="+this.search.recordMonth +"&endMonth="+this.search.endMonth +"&s_organid="+this.search.s_organid +
            "&s_depid="+this.search.s_depid +"&subjectType="+ this.search.subjectType+
            "&subjectSort="+ this.search.subjectSort+"&subjectDetail="+ this.search.subjectDetail
      }else if(type === "summary"){
        this.pdffile = apiURL+"/api/report/printmonthtransfersummary?recordMonth="+this.search.recordMonth +"&endMonth="+this.search.endMonth +"&s_organid="+this.search.s_organid +
            "&s_depid="+this.search.s_depid +"&subjectType="+ this.search.subjectType+
            "&subjectSort="+ this.search.subjectSort+"&subjectDetail="+ this.search.subjectDetail
      }
    },
  }
}
</script>

<style scoped>
.custom-tabs-label{
  font-size: 18px;
}

.pdf-container {
  width: 100%;
  height: 500px;
  overflow: hidden;
  display: inline-block;
}

.pdf-container iframe {
  transform: rotate(90deg);
  transform-origin: top left;
  width: 100vh; /* 100% of the viewport height */
  height: 100vw; /* 100% of the viewport width */
  margin-left: -100vh; /* half of the iframe height */
  margin-top: 50%; /* center vertically */
}
</style>
