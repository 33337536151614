<!--
登录界面
最后编辑人：张鹏
最后编辑时间：2022-12-1
最后编辑细节：
-->
<template>
  <div class="login-container">
    <div class="loginform">
      <div class="logintitle">闪算云企业数字化盈利管理平台</div>
      <div >
        <el-form class="div-from" :model="loginData" :rules="rules" size="large" ref="loginForm" @submit.prevent>
          <el-form-item prop="ua">
            <el-input placeholder="用户名" v-model="loginData.ua" ref="username" @keypress.enter="$refs.pwd.focus()"></el-input>
          </el-form-item>
          <el-form-item prop="up">
            <el-input placeholder="密 码" v-model="loginData.up" type="password" ref="pwd" @keypress.enter="doLogin"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="div-button">
        <button type="submit" @click="doLogin">登 录</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "LoginPage",
  data: function() {
    return {
      loginData: {
        ua: "",
        up: "",
      },
      rules: {
        ua: [{required: true, message: "请输入用户名", trigger: "blur"}],
        up: [{required: true, message: "请输入密码", trigger: "blur"}]
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.username.focus();
    })
  },
  methods: {
    doLogin () {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/login/dologin", this.loginData)
              .then((response) => {
                if (response.data.success) {
                  this.$router.replace({name: "main-page"})
                  console.log(this.$route)
                  var sessionId = response.data.result.sessionId;
                  if (undefined !== sessionId){
                    localStorage.setItem('sessionId', sessionId);
                  }
                } else {
                  this.$alert(response.data.result, "登录出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "登录出错")
              })

        }
        else
          return false
      })
    }
  }
}
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  background: url("../../assets/img/login.jpg") fixed no-repeat;
  background-size: cover;
}
.loginform {
  position: absolute;
  top: calc(50% - 190px);
  left: calc(50% - 250px);
  width: 420px;
  height: 350px;
  padding: 15px 30px;
  border-radius: 10px;
  background: inherit;
  overflow: hidden;
  z-index: 100;
}

.loginform::before{
  content: "";
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
  background: inherit;
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.4);
  filter: blur(3px);
  z-index: -1;
  border-left: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid rgba(255, 255, 255, 0.3);
}

.loginform::after{
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  border-radius: 8px;
  pointer-events: none;
  background: linear-gradient(to bottom,rgba(255,255,255,0.5) 0%,
  rgba(255,255,255,0.2) 15%, transparent 50%, transparent 70%,
  rgba(255,255,255,0.3) 100%);
}

.logintitle {
  padding: 15px;
  letter-spacing: 10px;
  text-shadow: #6b9ac9 0 0 5px, #ced5bd 0 0 1px;
  color: #052b59;
  font-size: 26px;
  font-weight: 600;
  font-family: "PingFang SC", sans-serif;
  margin-bottom: 36px;
  text-align:center;
}
.div-button {
  text-align: center;
  padding-top: 15px;
}
.div-button button{
  height: 45px;
  border: none;
  width: 85%;
  font-size: 20px;
  margin: 10px;
  color: #fff;
  background-color: #FF5733;
  border-radius: 20px;

}
.div-from{
  margin: 0 20px;
}

:deep( .el-form-item__label){
  font-size: 16px;
}
</style>
