<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计管理</el-breadcrumb-item>
        <el-breadcrumb-item>会计分录明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="2">
            <el-button type="success" @click="toAdd()">
              <el-icon><Plus /></el-icon><span class="icon-right">新增</span>
            </el-button>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="searchDetail.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadEntryDetail()">
            </el-date-picker>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="searchDetail.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadEntryDetail($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择会计科目"
                style="width: 100%;"
                v-model="searchDetail.subjectSort"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="changeDetail"
                :options="treeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="searchDetail.subjectDetail" placeholder="请选择科目明细" clearable @change="loadEntryDetail()" :disabled="disabled">
              <el-option
                  v-for="item in subjectDetailList"
                  :key="item.SUBJECT_DETAIL"
                  :label="item.SUBJECT_DETAIL"
                  :value="item.SUBJECT_DETAIL">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="searchDetail.num" placeholder="请输入凭证号" @keyup.enter="loadEntryDetail" @clear="loadEntryDetail" clearable size="default"></el-input>
          </el-col>
          <el-col :span="6">
            <el-button
                type="primary"
                size="default"
                @click="loadEntryDetail">查询</el-button>
            <el-button
                size="default"
                @click="toReset">重置</el-button>
            <el-button
                type="success"
                size="default"
                @click="showEntry">一键分录</el-button>
            <el-button
                type="primary"
                @click="toPrint()" style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :data="entryDetailList">
          <el-table-column
              prop="RECORD_TIME"
              label="日期"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="VOUCHER_NUM"
              label="凭证号"
              align="center"
              show-overflow-tooltip
              width="70px"
          >
          </el-table-column>
          <el-table-column
              prop="ABSTRACT"
              label="摘要"
              align="center"
              show-overflow-tooltip
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_TYPE_NAME"
              label="科目类别"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_NAME"
              label="会计科目"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_DETAIL"
              label="科目明细"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="INITIAL_DEBTOR"
              label="期初借方"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
                  <span v-if="scope.row.INITIAL_DEBTOR !== null && scope.row.INITIAL_DEBTOR !== ''" class="disable">
                    {{ formatNumber(scope.row.INITIAL_DEBTOR) }}
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              label="期初贷方"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
                  <span v-if="scope.row.INITIAL_CREDIT !== null && scope.row.INITIAL_CREDIT !== ''" class="disable">
                    {{ formatNumber(scope.row.INITIAL_CREDIT) }}
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              label="借方金额"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
            <template #default="scope">
                  <span v-if="scope.row.DEBTOR_MONEY !== null && scope.row.DEBTOR_MONEY !== ''" class="disable">
                    {{ formatNumber(scope.row.DEBTOR_MONEY) }}
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              label="贷方金额"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
            <template #default="scope">
                  <span v-if="scope.row.CREDIT_MONEY !== null && scope.row.CREDIT_MONEY !== ''" class="disable">
                    {{ formatNumber(scope.row.CREDIT_MONEY) }}
                  </span>
            </template>
          </el-table-column>
          <el-table-column
              prop="DOCUMENT"
              label="单据（张）"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="VOUCHER_NUM_LIST"
              label="凭证列表"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="150px"
              align="center">
            <template #default="scope">
              <el-button class="table-button"
                         size="small"
                         type="primary"
                         @click="toEdit(scope.row, scope.$index)">编辑</el-button>
              <el-button class="table-button"
                         size="small"
                         type="danger"
                         @click="toDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            v-model:current-page="searchDetail.page"
            v-model:page-size="searchDetail.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            @current-change="loadEntryDetail"
            @size-change="loadEntryDetail"
            :page-sizes="[20, 100, 500, 1000]"
            :total="total"
            style="margin-top: 10px;"
        >
        </el-pagination>

        <el-dialog
            width="900px"
            title="新增会计分录明细"
            v-model="dailVisi1"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            draggable
            >
          <el-scrollbar>
            <el-form
                :inline="true"
                :model="entryFromData1"
                :rules="rules"
                ref="entryFrom"
                label-width="130px"
            >
              <el-form-item label="摘要:" prop="abstract" class="form-col">
                <el-input v-model="entryFromData1.abstract" />
              </el-form-item>
              <el-form-item label="凭证号:" prop="num" class="form-col">
                <el-input-number v-model="entryFromData1.num" :min="1" style="width: 100%"/>
              </el-form-item>
              <el-form-item label="单据:" prop="document" class="form-col" >
                <el-input v-model="entryFromData1.document" />
              </el-form-item>
              <el-form-item label="记录时间:" prop="recordTime" class="form-col" >
                <el-date-picker
                    v-model="entryFromData1.recordTime"
                    type="date"
                    placeholder="选择记录时间"
                    style="width: 100%"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                />
              </el-form-item>


              <div style="text-align: center; margin-bottom: 10px;">
                <el-row>
                  <el-col :span="2">
                    <el-button type="success" size="small" @click="addItem()"><el-icon><Plus /></el-icon>添加</el-button>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>会计科目</el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item>科目明细</el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item>借方金额</el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item>贷方金额</el-form-item>
                  </el-col>
                </el-row>
                <div class="textarea-row">
                  <el-row  v-for="(user, index) in entryFromData1.items" :key="index">
                    <el-col :span="7">
                      <el-form-item prop="subjectSort" style="width: auto; margin: 0 5px;">
                        <el-cascader
                            placeholder="请选择科目"
                            style="width: 100%;"
                            v-model="user.subjectSort"
                            :props="props"
                            clearable
                            @change="getSubjectName(index)"
                            :options="subjectTree" >
                        </el-cascader>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item prop="subjectDetail"  style="width: 100%">
                        <el-select v-model="user.subjectDetail" style="width: 100%; margin: 0 5px;">
                          <el-option
                              v-for="item in backType"
                              :key="item.SUBJECT_DETAIL"
                              :label="item.SUBJECT_DETAIL"
                              :value="item.SUBJECT_DETAIL">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item prop="debtorMoney"  style="width: auto; margin: 0 5px;">
                        <el-input v-model="user.debtorMoney"
                                  :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                  :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                          <template #append>元</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item prop="creditMoney"  style="width: auto; margin: 0 5px;">
                        <el-input v-model="user.creditMoney"
                                  :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                                  :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                          <template #append>元</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1" style="padding-top: 5px">
                      <div v-if="index !== 0">
                        <el-tooltip effect="light" content="删除" placement="top">
                          <el-link :underline="false" @click="removeItem(index)">
                            <el-icon size="18px" color="#f56c6c"><CircleClose /></el-icon>
                          </el-link>
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>

                <el-row style="background: #f0f9eb;height: 30px; align-items: center;">
                  <el-col :span="2">
                  </el-col>
                  <el-col :span="4">

                  </el-col>
                  <el-col :span="5">

                  </el-col>
                  <el-col :span="6">
                    借方总计：{{ calculateTotalDebtor() }}
                  </el-col>
                  <el-col :span="6">
                    贷方总计：{{ calculateTotalCredit() }}
                  </el-col>
                </el-row>
              </div>
            </el-form>
          </el-scrollbar>
          <div class="div-button">
            <el-button type="primary" @click="toSave1">保 存</el-button>
          </div>
        </el-dialog>

        <el-dialog
            width="900px"
            title="编辑会计分录明细"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            draggable
            overflow>
          <el-scrollbar>
            <el-form
                :inline="true"
                :model="entryFromData"
                :rules="rules"
                ref="entryFrom"
                label-width="130px"
            >
              <el-form-item label="摘要:" prop="abstract" class="form-col">
                <el-input v-model="entryFromData.abstract" />
              </el-form-item>
              <el-form-item label="凭证号:" prop="num" class="form-col">
                <el-input-number v-model="entryFromData.num" :min="1" style="width: 100%"/>
              </el-form-item>
              <el-form-item label="单据:" prop="document" class="form-col" >
                <el-input v-model="entryFromData.document" />
              </el-form-item>
              <el-form-item label="记录时间:" prop="recordTime" class="form-col" >
                <el-date-picker
                    v-model="entryFromData.recordTime"
                    type="date"
                    placeholder="选择记录时间"
                    style="width: 100%"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                />
              </el-form-item>
              <el-form-item label="会计科目" prop="subjectSort" class="form-col">
                <el-cascader
                    placeholder="请选择科目"
                    style="width: 100%;"
                    v-model="entryFromData.subjectSort"
                    :props="props"
                    clearable
                    @change="getSubjectName()"
                    :options="subjectTree" >
                </el-cascader>
              </el-form-item>
              <el-form-item label="科目明细:" prop="subjectDetail" class="form-col">
                <el-select v-model="entryFromData.subjectDetail" style="width: 100%;">
                  <template #header>
                    <el-button type="success" @click="toDetail('1')" style="width: 100%" size="small">
                      <el-icon><Plus /></el-icon><span class="icon-right">添加明细</span>
                    </el-button>
                  </template>
                  <el-option
                      v-for="item in backType"
                      :key="item.SUBJECT_DETAIL"
                      :label="item.SUBJECT_DETAIL"
                      :value="item.SUBJECT_DETAIL">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="借方金额:" prop="debtorMoney" class="form-col">
                <el-input v-model="entryFromData.debtorMoney"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="贷方金额:" prop="creditMoney" class="form-col" >
                <el-input v-model="entryFromData.creditMoney"
                          :formatter="(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                          :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-scrollbar>
          <div class="div-button">
            <el-button type="primary" @click="toSave">保 存</el-button>
          </div>
        </el-dialog>

        <el-dialog
            width="500px"
            title="请选择分录年月"
            v-model="dailVisiEntry"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-date-picker
              v-model="EntryMonth"
              type="month"
              placeholder="选择年月"
              style="width: 100%"
              value-format="YYYY-MM"
              format="YYYY年MM月"

          />
          <div class="div-button">
            <el-button type="primary" @click="toEntry" style="margin-right: 20px">确认分录</el-button>
          </div>
        </el-dialog>
      </el-main>

      <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
        <div style="width:100%; height: calc(100% - 10px); ">
          <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
        </div>
      </el-drawer>
    </el-container>
  </el-container>
</template>

<script>

import apiURL from "@/components/apiUrl/api";

export default {
  name: "EntryDetail",
  data () {
    return {
      tableHeight: 0,
      searchDetail:{
        subjectSort:"",
        recordMonth:"",
        subjectDetail: "",
        num:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
        page: 1,
        pagesize: 20,
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      total: 0,
      entryDetailList:[],
      treeData:[],
      subjectDetailList:"",
      disabled:true,
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      dailVisiEntry:false,
      EntryMonth:"",
      pdffile:"",
      drawerVisi:false,

      subjectTree:[],
      backType:[],
      dailVisi:false,
      dailVisi1:false,
      entryFromData:{
        entryDetailId:"",
        abstract:"",
        num:"",
        document:"",
        recordTime:"",
        subjectSort:"",
        subjectTypeName:"",
        subjectName:"",
        subjectDetail:"",
        debtorMoney:"",
        creditMoney:"",
        voucherNumList:"",
      },

      entryFromData1:{
        entryDetailId:"",
        abstract:"",
        num:"",
        document:"",
        recordTime:"",
        items: [{
          subjectSort:"",
          subjectTypeName:"",
          subjectName:"",
          subjectDetail:"",
          debtorMoney:"",
          creditMoney:"",
        }]
      },
      flag: true,
      rules: {
        abstract: [{required: true, message: "请填写摘要", trigger: "blur"}],
        num: [{required: true, message: "请填写凭证号", trigger: "blur"}],
        recordTime: [{required: true, message: "请选择记录时间", trigger: "blur"}],
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.searchDetail.recordMonth = this.current("month")
      this.searchDetail.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.EntryMonth = this.current("month")
      this.loadCode()
      this.loadSubjectData()
      this.loadEntryDetail()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    current(d) {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if(d === "month")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if(d === "day")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    calculateTotalDebtor() {
      var items = this.entryFromData1.items
      var tol = 0
      items.forEach(m => {
        if(m.debtorMoney !== ""){
          tol += Number(m.debtorMoney)
        }
      })
      return this.formatNumber(tol.toFixed(2).replace(/\.00$/,''));
    },
    calculateTotalCredit() {
      var items = this.entryFromData1.items
      var tol = 0
      items.forEach(m => {
        if(m.creditMoney !== ""){
          tol += Number(m.creditMoney)
        }
      })
      return this.formatNumber(tol.toFixed(2).replace(/\.00$/,''));
    },
    formatNumber(value) {
      if(value === 0 || value === "0" || value === "" || value === 0.0 || value === "0.0"){
        return ""
      }else{
        return new Intl.NumberFormat().format(value);
      }
    },
    changeDetail(){
      let f=[]
      if(this.searchDetail.subjectSort !== "" && this.searchDetail.subjectSort !== null ) {
        this.disabled = false
        f = this.allSubjectDetail.filter((item) => {
          return Number(item.SUBJECT_SORT) === Number(this.searchDetail.subjectSort)
        })
      }else{
        this.disabled = true
      }
      this.subjectDetailList = f
      this.searchDetail.subjectDetail = ""
      this.loadEntryDetail()
    },
    loadCode() {
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.subjectTree = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
    },
    loadSubjectData() {
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })

      this.$axios.post("/api/common/getallsubjectdetail", this.searchDetail)
          .then((response) => {
            if (response.data.success) {
              this.allSubjectDetail = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取科目明细列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取科目明细列表出错")
          })
    },
    loadEntryDetail(d) {
      if(this.searchDetail.organOrDep !== "" && this.searchDetail.organOrDep !== null){
        const parts = this.searchDetail.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.searchDetail.s_organid = parts[1]
          this.searchDetail.s_depid = ""
        }else{
          this.searchDetail.s_organid = parts[0]
          this.searchDetail.s_depid = parts[1]
        }
      }else{
        this.searchDetail.s_organid = ""
        this.searchDetail.s_depid = ""
      }
      this.$axios.post("/api/entry/loadentrydetail", this.searchDetail)
          .then((response) => {
            if (response.data.success) {
              this.total = response.data.result.totalrecords
              this.entryDetailList = response.data.result.datalist
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取会计分录明细列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计分录细列表出错")
          })
    },
    toRefresh() {
      this.searchDetail.subjectSort = ""
      this.searchDetail.subjectType = ""
      this.searchDetail.subjectDetail = ""
      this.searchDetail.num = ""
      this.searchDetail.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.searchDetail.s_organid = ""
      this.searchDetail.s_depid = ""
      this.loadEntryDetail("refresh")
    },
    toReset() {
      this.searchDetail.subjectSort = ""
      this.searchDetail.subjectType = ""
      this.searchDetail.subjectDetail = ""
      this.searchDetail.num = ""
      this.searchDetail.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.searchDetail.s_organid = ""
      this.searchDetail.s_depid = ""
      this.loadEntryDetail()
    },
    showEntry(){
      this.dailVisiEntry =true
    },
    toEntry(){
      if(this.EntryMonth !== ""){
        this.$axios.post("/api/entry/oneClickEntry", {"entryMonth":this.EntryMonth})
            .then((response) => {
              if (response.data.success) {
                this.dailVisiEntry = false
                this.$message({message: "一键分录成功", type: "success"})
                this.loadEntryDetail()
              }
              else {
                this.$alert(response.data.result, "一键分录出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "一键分录出错")
            })
      }
    },

    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.searchDetail.organOrDep !== "" && this.searchDetail.organOrDep !== null){
        const parts = this.searchDetail.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.searchDetail.s_organid = parts[1]
          this.searchDetail.s_depid = ""
        }else{
          this.searchDetail.s_organid = parts[0]
          this.searchDetail.s_depid = parts[1]
        }
      }else{
        this.searchDetail.s_organid = ""
        this.searchDetail.s_depid = ""
      }
      this.pdffile = apiURL+"/api/entry/printentrydetail?recordMonth="+this.searchDetail.recordMonth +"&s_organid="+this.searchDetail.s_organid +
          "&s_depid="+this.searchDetail.s_depid + "&subjectSort="+this.searchDetail.subjectSort +
          "&subjectDetail="+this.searchDetail.subjectDetail + "&num="+this.searchDetail.num
    },

    getSubjectName(index) {
      this.backType = []
      if(typeof index !== "undefined"){
        this.subjectTree.forEach(item => {
          if (item.children != null) {
            item.children.forEach(ch => {
              ch.children.forEach(c => {
                if (c.id === this.entryFromData1.items[index].subjectSort) {
                  this.backType = c.detail
                  this.entryFromData1.items[index].subjectName = c.label
                  this.entryFromData1.items[index].subjectTypeName= c.typename
                }
              })
            })
          }
        })
      }else{
        this.subjectTree.forEach(item => {
          if (item.children != null) {
            item.children.forEach(ch => {
              ch.children.forEach(c => {
                if (c.id === this.entryFromData.subjectSort) {
                  this.backType = c.detail
                  this.entryFromData.subjectName = c.label
                  this.entryFromData.subjectTypeName= c.typename
                }
              })
            })
          }
        })
      }

    },

    toAdd() {
      this.entryFromData1.entryDetailId = ""
      this.entryFromData1.abstract = ""
      this.entryFromData1.num = ""
      this.entryFromData1.items = [{
        subjectSort:"",
        subjectTypeName:"",
        subjectName:"",
        subjectDetail:"",
        debtorMoney:"",
        creditMoney:"",
      }]
      this.entryFromData1.document = ""
      this.entryFromData1.recordTime = this.current("day")
      this.getMaxVoucherNum()
      if (this.$refs.entryFrom)
        this.$refs.entryFrom.clearValidate()
    },
    getMaxVoucherNum(){
      this.$axios.post("/api/entry/getMaxVoucherNum", this.searchDetail)
          .then((response) => {
            if (response.data.success) {
              this.entryFromData1.num = response.data.result
              this.dailVisi1 = true
            } else {
              this.$alert(response.data.result, "获取最大凭证号出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取最大凭证号出错")
          })
    },

    toEdit(data){
      this.dailVisi = true
      this.entryFromData.entryDetailId = data.ENTRY_DETAIL_ID
      this.entryFromData.abstract = data.ABSTRACT
      this.entryFromData.num = data.VOUCHER_NUM
      this.entryFromData.subjectSort = data.SUBJECT_SORT.toString()
      this.entryFromData.subjectTypeName = data.SUBJECT_TYPE_NAME
      this.entryFromData.subjectName = data.SUBJECT_NAME
      this.entryFromData.subjectDetail = data.SUBJECT_DETAIL
      this.entryFromData.debtorMoney = data.DEBTOR_MONEY
      this.entryFromData.creditMoney = data.CREDIT_MONEY
      this.entryFromData.document = data.DOCUMENT
      this.entryFromData.recordTime = data.RECORD_TIME
      this.entryFromData.voucherNumList = data.VOUCHER_NUM_LIST
      this.getSubjectName()
      if (this.$refs.entryFrom)
        this.$refs.entryFrom.clearValidate()
    },

    toSave() {
      this.$refs["entryFrom"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/entry/saveentrydetaildata", this.entryFromData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存成功", type: "success"})
                  this.loadEntryDetail()
                  this.dailVisi = false
                } else {
                  this.$alert(response.data.result, "保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "保存出错")
              })
        }
        else
          return false
      })
    },
    toSave1() {
      var items = this.entryFromData1.items
      var tol_debtor = 0
      var tol_credit = 0
      items.forEach(m => {
        if(m.debtorMoney !== ""){
          tol_debtor += Number(m.debtorMoney)
        }
        if(m.creditMoney !== ""){
          tol_credit += Number(m.creditMoney)
        }
      })
      if(tol_debtor !== tol_credit){
        return this.$message({message: "借方金额总计和贷方金额总计不相等，无法保存", type: "error"})
      }
      this.$refs["entryFrom"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/entry/saveaddentrydetaildata", this.entryFromData1)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存成功", type: "success"})
                  this.loadEntryDetail()
                  this.dailVisi1 = false
                } else {
                  this.$alert(response.data.result, "保存出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "保存出错")
              })
        }
        else
          return false
      })
    },
    toDel (data) {
      this.$confirm("确认删除此分录明细?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/entry/delentrydetaildata", {entrydetailId: data.ENTRY_DETAIL_ID, VOUCHER_NUM_LIST: data.VOUCHER_NUM_LIST,
          subjectSort:data.SUBJECT_SORT, subjectDetail:data.SUBJECT_DETAIL , recordTime:data.RECORD_TIME})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "分录明细删除成功", type: "success"})
                this.loadEntryDetail()
              }
              else {
                this.$alert(response.data.result, "分录明细删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "分录明细删除出错")
            })
      })
    },
    addItem() {
      this.entryFromData1.items.push({
        subjectSort:"",
        subjectName:"",
        subjectDetail:"",
        debtorMoney:"",
        creditMoney:"",
      });
      this.flags()
    },
    removeItem(index) {
      if (this.entryFromData1.items.length > 1) {
        this.entryFromData1.items.splice(index, 1);
      }
      this.flags()
    },
    flags() {
      if (this.entryFromData1.items.length < 2) {
        this.flag = true
      } else {
        this.flag = true
        this.flag = false
      }
    },
  }
}
</script>

<style scoped>
.textarea-row {
  height: 150px;
  overflow-y: auto;
}
</style>
