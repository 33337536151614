<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计报表</el-breadcrumb-item>
        <el-breadcrumb-item>利润表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadData()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.taxation" placeholder="请选择是否计税" clearable @clear="loadData"  @change="loadData">
              <el-option value="">全部</el-option>
              <el-option value="0" label="否"></el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <i class="iconfont icon-daochu" style="font-size: 15px"></i><span style="margin-right: 5px">导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="7" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <div class="content">
          <div>
            <el-table
                :height="tableHeight"
                :data="subjectBalanceData"
                :cell-style="cellStyleMethod"
                border>
              <el-table-column
                  prop="szxm"
                  label="收支项目"
                  show-overflow-tooltip
                  align="center"
                  min-width="100px"
              >
              </el-table-column>
              <el-table-column
                  prop="num"
                  label="序号"
                  align="center"
                  show-overflow-tooltip
                  min-width="80px"
              >
              </el-table-column>
              <el-table-column
                  prop="by"
                  label="本月合计"
                  align="center"
                  show-overflow-tooltip
                  min-width="180px"
              >
                <template #default="scope" >
                  <div v-if="scope.row.szxm === '财务费用' && scope.row.by !== 0">{{ formatNumber(-scope.row.by) }}</div>
                  <div v-else-if="scope.row.szxm.includes('、')">{{ formatNumber(scope.row.by) }}</div>
                  <div v-else>{{ formatNumber(Math.abs(scope.row.by)) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                  prop="bn"
                  label="本年累计"
                  align="center"
                  show-overflow-tooltip
                  min-width="180px"
              >
                <template #default="scope">
                  <div v-if="scope.row.szxm === '财务费用' && scope.row.bn !== 0">{{ formatNumber(-scope.row.bn) }}</div>
                  <div v-else-if="scope.row.szxm.includes('、')">{{ formatNumber(scope.row.bn) }}</div>
                  <div v-else>{{ formatNumber(Math.abs(scope.row.bn)) }}</div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "profitReport",
  data () {
    return {
      tableHeight: 0,
      search: {
        recordMonth: "",
        organOrDep: "",
        s_organid: "",
        s_depid: "",
        taxation:"",
      },
      exportTip: "",
      OrganTreeData: [],
      organprops: {
        label: 'text', value: 'value', children: 'children',
        checkStrictly: true,
        emitPath: false,
      },
      subjectBalanceData:[],
      pdffile:"",
      drawerVisi:false,
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 100
    },
    toRefresh() {
      this.loadData("refresh")
    },
    cellStyleMethod({ row, columnIndex  }) {
      if(columnIndex === 0) {
        if (row["szxm"].includes("、")) {
          return {'text-align': 'left', 'padding': '0 12px', 'color': '#606266', 'font-weight': 'bold'};
        } else if (row["szxm"].includes("：")) {
          return {'text-align': 'left', 'padding-left': '40px', 'color': '#606266'};
        } else {
          return {'text-align': 'left', 'padding-left': '70px', 'color': '#606266'};
        }
      }else{
        if (row["szxm"].includes("、")) {
          return { 'color': '#606266', 'font-weight': 'bold'};
        }
      }
    },
    formatNumber(value) {
      if (value !== "" && value !== null)
        return new Intl.NumberFormat().format(value);
    },
    loadCode() {
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    loadData(d) {
      if (this.search.organOrDep !== "" && this.search.organOrDep !== null) {
        const parts = this.search.organOrDep.toString().split('-');
        if (parts[0] === "company") {
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        } else {
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      } else {
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/profit/loadprofitreportdata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.subjectBalanceData = response.data.result
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取利润表数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取利润表数据出错")
          })
    },
    //导出
    doExport () {
      this.exportTip = "利润表导出Excel中..."
      this.$axios.post("/api/profit/exportprofitdata",{data: this.subjectBalanceData, recordMonth:this.search.recordMonth}, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 3000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "利润表导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "利润表导出出错")
          })

    },
    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/profit/printprofitdata?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid
    },
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  margin-top: 10px;
  border-bottom: 1px solid #ebeef5;
}

</style>
