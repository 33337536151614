<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计报表</el-breadcrumb-item>
        <el-breadcrumb-item>现金流量表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main style="overflow:hidden;">
        <el-row>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                value-format="YYYY-MM"
                type="month"
                style="width: 100%;"
                @change="loadData()">
            </el-date-picker>
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="5">
            <el-button
                type="primary"
                @click="toPrint()" style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <view class="content">
          <div class="table">
            <table>
              <tr>
                <th><div class="cell1">项目</div></th>
                <th><div class="cell1" style="min-width: 60px;">行次</div></th>
                <th><div class="cell1" style="min-width: 80px;">金额（元）</div></th>
                <th><div class="cell1">补充材料</div></th>
                <th><div class="cell1" style="min-width: 60px;">行次</div></th>
                <th><div class="cell1" style="min-width: 80px;">金额（元）</div></th>
              </tr>
              <tr>
                <td><div class="cell2">一、经营活动产生的现金流量：</div></td>
                <td><div class="cell"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2">1、将净利润调节为经营活动现金流量：</div></td>
                <td><div class="cell"></div></td>
                <td><div class="cell2"></div></td>
              </tr>
              <tr>
                <td><div class="cell">销售商品、提供劳务收到的现金</div></td>
                <td><div class="cell2">1</div></td>
                <td><div class="cell2">{{ formatNumber(num1) }}</div></td>
                <td><div class="cell2">净利润</div></td>
                <td><div class="cell2">1</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">收到的税费返还</div></td>
                <td><div class="cell2">2</div></td>
                <td><div class="cell2">{{ formatNumber(num2) }}</div></td>
                <td><div class="cell">加：计提的资产减值准备</div></td>
                <td><div class="cell2">2</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">收到的其他与经营活动有关的现金</div></td>
                <td><div class="cell2">3</div></td>
                <td><div class="cell2">{{ formatNumber(num3) }}</div></td>
                <td><div class="cell">固定资产折旧</div></td>
                <td><div class="cell2">3</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell3">现金流入小计</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(tol_num1) }}</div></td>
                <td><div class="cell">无形资产摊销</div></td>
                <td><div class="cell2">4</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">购买商品接受劳务支付的现金</div></td>
                <td><div class="cell2">4</div></td>
                <td><div class="cell2">{{ formatNumber(num4) }}</div></td>
                <td><div class="cell">长期待摊费用摊销</div></td>
                <td><div class="cell2">5</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">支付给职工以及为职工支付的现金</div></td>
                <td><div class="cell2">5</div></td>
                <td><div class="cell2">{{ formatNumber(num5) }}</div></td>
                <td><div class="cell">待摊费用减少（减：增加）</div></td>
                <td><div class="cell2">6</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">支付的各项税费</div></td>
                <td><div class="cell2">6</div></td>
                <td><div class="cell2">{{ formatNumber(num6) }}</div></td>
                <td><div class="cell">预提费用增加（减：减少）</div></td>
                <td><div class="cell2">7</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">支付的其他与经营活动有关的现金</div></td>
                <td><div class="cell2">7</div></td>
                <td><div class="cell2">{{ formatNumber(num7) }}</div></td>
                <td><div class="cell">处置固定资产、无形资产和其他长期资产的损失（减：收益）</div></td>
                <td><div class="cell2">8</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell3">现金流出小计</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(tol_num2) }}</div></td>
                <td><div class="cell">固定资产报废损失</div></td>
                <td><div class="cell2">9</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell4">经营活动产生的现金流量净额</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell4">{{ formatNumber(net_num1) }}</div></td>
                <td><div class="cell">财务费用</div></td>
                <td><div class="cell2">10</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell2">二、投资活动产生的现金流量：</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell">投资损失（减：收益）</div></td>
                <td><div class="cell2">11</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">收回投资所收到的现金</div></td>
                <td><div class="cell2">8</div></td>
                <td><div class="cell2">{{ formatNumber(num8) }}</div></td>
                <td><div class="cell">递延税款贷项（减：借项）</div></td>
                <td><div class="cell2">12</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">取得投资收益所收到的现金</div></td>
                <td><div class="cell2">9</div></td>
                <td><div class="cell2">{{ formatNumber(num9) }}</div></td>
                <td><div class="cell">存货的减少（减：增加）</div></td>
                <td><div class="cell2">13</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">处置固定资产、无形资产和其他长期资产所收回的现金净额</div></td>
                <td><div class="cell2">10</div></td>
                <td><div class="cell2">{{ formatNumber(num10) }}</div></td>
                <td><div class="cell">经营性应收项目的减少（减：增加）</div></td>
                <td><div class="cell2">14</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">收到的其他与投资活动有关的现金</div></td>
                <td><div class="cell2">11</div></td>
                <td><div class="cell2">{{ formatNumber(num11) }}</div></td>
                <td><div class="cell">经营性应付项目的增加（减：减少）</div></td>
                <td><div class="cell2">15</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell3">现金流入小计</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(tol_num3) }}</div></td>
                <td><div class="cell">其他</div></td>
                <td><div class="cell2">16</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">购建固定资产、无形资产和其他长期资产所支付的现金</div></td>
                <td><div class="cell2">12</div></td>
                <td><div class="cell2">{{ formatNumber(num12) }}</div></td>
                <td><div class="cell4">经营活动产生的现金流量净额</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">投资所支付的现金</div></td>
                <td><div class="cell2">13</div></td>
                <td><div class="cell2">{{ formatNumber(num13) }}</div></td>
                <td><div class="cell2">2、不涉及现金收支的投资和筹资活动：</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">支付的其他与投资活动有关的现金</div></td>
                <td><div class="cell2">14</div></td>
                <td><div class="cell2">{{ formatNumber(num14) }}</div></td>
                <td><div class="cell">债务转为资本</div></td>
                <td><div class="cell2">17</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell3">现金流入小计</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(tol_num4) }}</div></td>
                <td><div class="cell">一年内到期的可转换公司债券</div></td>
                <td><div class="cell2">18</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell4">投资活动产生的现金流量净额</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell4">{{ formatNumber(net_num2) }}</div></td>
                <td><div class="cell">融资租入固定资产</div></td>
                <td><div class="cell2">19</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell2">三、筹资活动产生的现金流量：</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2">3、现金及现金等价物净增加情况</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">吸收投资所收到的现金</div></td>
                <td><div class="cell2">15</div></td>
                <td><div class="cell2">{{ formatNumber(num15) }}</div></td>
                <td><div class="cell">现金的期未余额</div></td>
                <td><div class="cell2">20</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">取得借款所收到的现金</div></td>
                <td><div class="cell2">16</div></td>
                <td><div class="cell2">{{ formatNumber(num16) }}</div></td>
                <td><div class="cell">减：现金的期初余额</div></td>
                <td><div class="cell2">21</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">收到的其他与筹资活动有关的现金</div></td>
                <td><div class="cell2">17</div></td>
                <td><div class="cell2">{{ formatNumber(num17) }}</div></td>
                <td><div class="cell">加：现金等价物的期未余额</div></td>
                <td><div class="cell2">22</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell3">现金流入小计</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(tol_num5) }}</div></td>
                <td><div class="cell">减：现金等价物的期初余额</div></td>
                <td><div class="cell2">23</div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">偿还债务所支付的现金</div></td>
                <td><div class="cell2">18</div></td>
                <td><div class="cell2">{{ formatNumber(num18) }}</div></td>
                <td><div class="cell4">现金及现金等价物净增加额</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">分配股利、利润和偿付利息所支付的现金</div></td>
                <td><div class="cell2">19</div></td>
                <td><div class="cell2">{{ formatNumber(num19) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell">支付的其他与筹资活动有关的现金</div></td>
                <td><div class="cell2">20</div></td>
                <td><div class="cell2">{{ formatNumber(num20) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell3">现金流出小计</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(tol_num6) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell4">筹资活动产生的现金流量净额</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell4">{{ formatNumber(net_num3) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell2">四、汇率变动对现金的影响</div></td>
                <td><div class="cell2">21</div></td>
                <td><div class="cell2">{{ formatNumber(num21) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell2">五、现金及现金等价物净增加额</div></td>
                <td><div class="cell2">22</div></td>
                <td><div class="cell3">{{ formatNumber(num22) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell2">加:期初现金余额</div></td>
                <td><div class="cell2">23</div></td>
                <td><div class="cell2">{{ formatNumber(num23) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
              <tr>
                <td><div class="cell2">期末现金余额</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell3">{{ formatNumber(total_num) }}</div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"></div></td>
                <td><div class="cell2"> </div></td>
              </tr>
            </table>
          </div>
        </view>
      </el-main>
      <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
        <div style="width:100%; height: calc(100% - 10px); ">
          <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
        </div>
      </el-drawer>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "EntryCashFlowReport",
  data () {
    return {
      search: {
        recordMonth: "",
        organOrDep: "",
        s_organid: "",
        s_depid: "",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      num8: "",
      num9: "",
      num10: "",
      num11: "",
      num12: "",
      num13: "",
      num14: "",
      num15: "",
      num16: "",
      num17: "",
      num18: "",
      num19: "",
      num20: "",
      num21: "",
      num22: "",
      num23: "",
      tol_num1:"",
      tol_num2:"",
      tol_num3:"",
      tol_num4:"",
      tol_num5:"",
      tol_num6:"",
      net_num1:"",
      net_num2:"",
      net_num3:"",
      total_num:"",
      pdffile:"",
      drawerVisi:false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadCode()
      this.loadData()
    })
  },
  methods: {
    current(d) {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate()
      };
      if(d === "month")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month);
      else if(d === "day")
        return  date.year + "-" + (date.month >= 10 ? date.month : "0" + date.month) + "-" + (date.day >= 10 ? date.day : "0" + date.day);
    },
    toRefresh() {
      this.search.recordMonth = this.current("month")
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.search.s_organid = ""
      this.search.s_depid = ""
      this.loadData("refresh")
    },
    formatNumber(value) {
      if(value === 0 || value === ""){
        return ""
      }else{
        return value.toFixed(2).replace(/\.00$/,'')
      }
    },
    loadCode() {
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },
    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/entryreport/loadentryreportdata", this.search)
          .then((response) => {
            if (response.data.success) {
              var num = response.data.result
              this.num1 = num.num1
              this.num2 = num.num2
              this.num3 = num.num3
              this.num4 = num.num4
              this.num5 = num.num5
              this.num6 = num.num6
              this.num7 = num.num7
              this.num8 = num.num8
              this.num9 = num.num9
              this.num10 = num.num10
              this.num11 = num.num11
              this.num12 = num.num12
              this.num13 = num.num13
              this.num14 = num.num14
              this.num15 = num.num15
              this.num16 = num.num16
              this.num17 = num.num17
              this.num18 = num.num18
              this.num19 = num.num19
              this.num20 = num.num20
              this.tol_num1 = num.num1 + num.num2 + num.num3
              this.tol_num2 = num.num4 + num.num5 + num.num6+ num.num7
              this.tol_num3 = num.num8 + num.num9 + num.num10 + num.num11
              this.tol_num4 = num.num12 + num.num13 + num.num14
              this.tol_num5 = num.num15 + num.num16 + num.num17
              this.tol_num6 = num.num18 + num.num19 + num.num20
              this.net_num1 = this.tol_num1 - this.tol_num2
              this.net_num2 = this.tol_num3 - this.tol_num4
              this.net_num3 = this.tol_num5 - this.tol_num6
              this.num21 = ""
              this.num22 = this.net_num1 + this.net_num2 + this.net_num3
              this.num23 = num.num23
              this.total_num = this.num22 + this.num23
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取现金流量数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取现金流量数据出错")
          })
    },
    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/entryreport/printentryreportdata?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid
    },
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  margin-top: 10px;
  border-bottom: 1px solid #ebeef5;
}
.table {
  width: calc(100% - 0);
  text-align: center;
  background-color: white;
  height:  100%;
  overflow-y: auto;
}
.table table {
  width: 100%;
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
  border-spacing: 0;
  border-collapse: collapse
}
.table table td {
  border-left: 1px solid#ebeef5;
  border-top: 1px solid #ebeef5;
  text-align: center;
  font-size: 12px;

  border-right: 1px solid #ebeef5;
}
.table table tr th {
  position: sticky;
  top: 0;
  background-color: #F5F7FA;
  height: 40px;
}
.table table tr td {
  height: 40px;
}

.cell1 {
  line-height: 23px;
  padding: 0 12px;
  color: #909399;
  font-size: 14px;
  font-weight: bold;
}
.cell {
  line-height: 23px;
  padding: 0 12px;
  color: #606266;
  font-size: 14px;
}
.cell2 {
  line-height: 23px;
  padding: 0 12px;
  color: #606266;
  font-size: 14px;
  font-weight: bold;
}
.cell3 {
  line-height: 23px;
  padding: 0 12px;
  color: #a41930;
  font-size: 14px;
  font-weight: bold;
}
.cell4 {
  line-height: 23px;
  padding: 0 12px;
  color: #134ca9;
  font-size: 14px;
  font-weight: bold;
}
</style>
