<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>绩效管理</el-breadcrumb-item>
        <el-breadcrumb-item>工资及绩效管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main>
        <el-row style="margin-bottom: 10px;">
          <el-col :span="2">
            <el-button type="success" @click="toAddEagesData()">
              <el-icon><Plus /></el-icon><span class="icon-right">新建</span>
            </el-button>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.month"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年 MM月"
                @change="loadWagesData()"
            />
          </el-col>
          <el-col :span="4" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadWagesData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-input v-model="search.username" placeholder="请输入姓名" @keyup.enter="loadWagesData" @clear="loadWagesData" clearable size="default"></el-input>
          </el-col>
          <el-col :span="11" class="input-search">
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport" :loading="exportTip !== ''">
              <span><i class="iconfont icon-daochu" style="font-size: 15px"></i>导出</span>
            </el-button>
            <el-button type="success" @click="showProvision()">
              <el-icon><Finished /></el-icon><span class="icon-right">工资计提</span>
            </el-button>
            <el-button type="success" @click="doCarryForward" style="margin-left: 10px;">
              <span><i class="iconfont icon-qimojiezhuan-normal" style="font-size: 18px"></i>工资结转</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            :span-method="objectSpanMethod"
            :data="wagesListData"
            border
            :cell-style="cellStyleMethod">
          <el-table-column
              prop="DEPARTMENT_NAME"
              label="部门名称"
              show-overflow-tooltip
              align="center"
              min-width="100px"
              fixed
          >
          </el-table-column>
          <el-table-column
              prop="USER_NAME"
              label="姓名"
              align="center"
              show-overflow-tooltip
              min-width="80px"
              fixed
          >
          </el-table-column>
          <el-table-column
              prop="BASIC_SALARY"
              label="基本工资"
              align="center"
              show-overflow-tooltip
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="RANK_SALARY"
              label="职级工资"
              align="center"
              show-overflow-tooltip
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="COMMISSION_SALARY"
              label="提成工资"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column
              prop="TOTAL_SALARY"
              label="基本工资合计"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column label="全勤考核" align="center">
            <el-table-column
                prop="ATTENDANCE_DAYS"
                label="出勤天数"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>

            <el-table-column
                prop="ATTENDANCE_SALARY"
                label="出勤工资"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column label="病事假扣款" align="center">
            <el-table-column
                prop="LATE_NUM"
                label="迟到次数"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>
            <el-table-column
                prop="LATE_DEDUCT"
                label="迟到扣款"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>
            <el-table-column
                prop="PERSONAL_LEAVE"
                label="事假天数"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>
            <el-table-column
                prop="SICK_LEAVE"
                label="病假天数"
                show-overflow-tooltip
                align="center"
                min-width="100px"
            >
            </el-table-column>
            <el-table-column
                prop="SICK_LEAVE_DEDUCT"
                label="病事假扣款"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
              prop="PAYABLE_SALARY"
              label="应发工资"
              show-overflow-tooltip
              align="center"
              min-width="100px"
          >
          </el-table-column>
          <el-table-column label="其他扣款" align="center">
            <el-table-column
                prop="PENSION_RETIREMENT"
                label="个人养老保险"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="PENSION_MEDICAL"
                label="个人医疗保险"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="PENSION_UNEMPLOYMENT"
                label="个人失业保险"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="INDIVIDUAL_TAX_DEDUCT"
                label="个人所得税"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="NET_SALARY"
                label="实发工资"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
              prop="COMPANY_RETIREMENT"
              label="公司养老保险"
              show-overflow-tooltip
              align="center"
              min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="COMPANY_MEDICAL"
                label="公司医疗保险"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="COMPANY_UNEMPLOYMENT"
                label="公司失业保险"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="COMPANY_INJURY_BIRTH"
                label="公司生育、工伤保险"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>
            <el-table-column
                prop="COMPANY_HOUSING_FUND"
                label="公司住房公积金"
                show-overflow-tooltip
                align="center"
                min-width="80px"
            >
            </el-table-column>

          </el-table-column>
          <el-table-column
              prop="cz"
              fixed="right"
              label="操作"
              min-width="140px"
              align="center">
            <template #default="scope">
              <el-button class="table-button"
                         size="small"
                         type="primary"
                         v-if="scope.row.DEPARTMENT !== '汇总' && scope.row.ORGAN_ID.toString() === this.organ_organid.toString()"
                         @click="toEdit(scope.row)">编辑</el-button>
              <el-button class="table-button"
                         size="small"
                         type="danger"
                         v-if="scope.row.DEPARTMENT !== '汇总' && scope.row.ORGAN_ID.toString() === this.organ_organid.toString()"
                         @click="toDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog
            width="1000px"
            :title="wagesTitle"
            v-model="dailVisi"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-scrollbar height="400px">
            <el-form
                :inline="true"
                :model="wagesFromData"
                :rules="rules"
                ref="wagesFrom"
                label-width="130px"
            >
              <el-form-item label="部门名称" prop="departmentId" class="form-col">
                <el-select v-model="wagesFromData.departmentId" placeholder="请选择部门">
                  <el-option
                      v-for="item in departmentlist"
                      :key="item.DEPARTMENT_ID"
                      :label="item.DEPARTMENT_NAME"
                      :value="item.DEPARTMENT_ID">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="员工姓名" prop="userName" class="form-col">
                <el-input v-model="wagesFromData.userName" placeholder="请输入姓名"/>
<!--                <el-select v-model="wagesFromData.userId" filterable placeholder="请选择姓名" :disabled="disabled">-->
<!--                  <el-option-->
<!--                      v-for="item in userlist"-->
<!--                      :key="item.USER_ID"-->
<!--                      :label="item.USER_NAME"-->
<!--                      :value="item.USER_ID">-->
<!--                  </el-option>-->
<!--                </el-select>-->
              </el-form-item>
              <el-form-item label="基本工资:" prop="basicSalary" class="form-col">
                <el-input v-model="wagesFromData.basicSalary" @change="getTotalSalary()">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="职级工资:" prop="rankSalary" class="form-col">
                <el-input v-model="wagesFromData.rankSalary" @change="getTotalSalary()">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="提成工资:" prop="commissionSalary" class="form-col">
                <el-input v-model="wagesFromData.commissionSalary" @change="getTotalSalary()">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="所属年月:" prop="yearMonth" class="form-col">
                <el-date-picker
                    v-model="wagesFromData.yearMonth"
                    type="month"
                    placeholder="选择年月"
                    value-format="YYYY-MM"
                    style="width: 100%"
                />
              </el-form-item>
              <el-form-item label="基本工资合计:" prop="totalSalary" class="form-col">
                <span>{{ this.wagesFromData.totalSalary }} 元</span>
              </el-form-item>
              <el-divider>全勤考核</el-divider>
              <el-form-item label="出勤天数:" prop="attendanceDays" class="form-col">
                <el-input v-model="wagesFromData.attendanceDays">
                  <template #append>天</template>
                </el-input>
              </el-form-item>
              <el-form-item label="出勤工资:" prop="attendanceSalary" class="form-col">
                <el-input v-model="wagesFromData.attendanceSalary" @change="getPayableSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-divider>病事假扣款</el-divider>
              <el-form-item label="迟到次数:" prop="lateNum" class="form-col">
                <el-input v-model="wagesFromData.lateNum">
                  <template #append>次</template>
                </el-input>
              </el-form-item>
              <el-form-item label="迟到扣款:" prop="lateDeduct" class="form-col">
                <el-input v-model="wagesFromData.lateDeduct" @change="getPayableSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="事假天数:" prop="personalLeave" class="form-col">
                <el-input v-model="wagesFromData.personalLeave">
                  <template #append>天</template>
                </el-input>
              </el-form-item>
              <el-form-item label="病假天数:" prop="sickLeave" class="form-col">
                <el-input v-model="wagesFromData.sickLeave">
                  <template #append>天</template>
                </el-input>
              </el-form-item>
              <el-form-item label="病事假扣除:" prop="sickLeaveDeduct" class="form-col">
                <el-input v-model="wagesFromData.sickLeaveDeduct" @change="getPayableSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item class="form-col">
              </el-form-item>
              <el-form-item label="应发工资:" prop="payableSalary" class="form-col">
                <span v-if="wagesFromData.payableSalary !==''">{{ wagesFromData.payableSalary }} 元</span>
              </el-form-item>
              <el-divider>其他扣款</el-divider>
              <el-form-item label="个人养老保险:" prop="pensionRetirement" class="form-col">
                <el-input v-model="wagesFromData.pensionRetirement" @change="getNetSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="个人医疗保险:" prop="pensionMedical" class="form-col">
                <el-input v-model="wagesFromData.pensionMedical" @change="getNetSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="个人失业保险:" prop="pensionUnemployment" class="form-col">
                <el-input v-model="wagesFromData.pensionUnemployment" @change="getNetSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="扣个人所得税:" prop="individualTaxDeduct" class="form-col">
                <el-input v-model="wagesFromData.individualTaxDeduct" @change="getNetSalary">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="实发工资:" prop="netSalary" class="form-col">
                <span v-if="wagesFromData.netSalary !==''">{{ wagesFromData.netSalary }} 元</span>
              </el-form-item>
              <el-divider>公司扣款</el-divider>
              <el-form-item label="公司养老保险:" prop="companyRetirement" class="form-col">
                <el-input v-model="wagesFromData.companyRetirement">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="公司医疗保险:" prop="companyMedical" class="form-col">
                <el-input v-model="wagesFromData.companyMedical">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="公司失业保险:" prop="companyUnemployment" class="form-col">
                <el-input v-model="wagesFromData.companyUnemployment">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="公司工伤、生育保险:" prop="companyInjuryBirth" class="form-col">
                <el-input v-model="wagesFromData.companyInjuryBirth">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
              <el-form-item label="公司住房公积金:" prop="companyHousingFund" class="form-col">
                <el-input v-model="wagesFromData.companyHousingFund">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
            </el-form>
          </el-scrollbar>
          <div class="div-button">
            <el-button type="primary" @click="toSave">保 存</el-button>
          </div>
        </el-dialog>

        <el-dialog
            width="500px"
            title="请选择计提年月"
            v-model="dailVisiProvision"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-date-picker
              v-model="ProvisionMonth"
              type="month"
              placeholder="选择年月"
              style="width: 100%"
              value-format="YYYY-MM"
              format="YYYY年MM月"
          />
          <div class="div-button">
            <el-button type="primary" @click="toProvision" style="margin-right: 20px">确认计提</el-button>
          </div>
        </el-dialog>

        <el-drawer :size="1200"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";
export default {
  name: "WagesMge",
  data () {
    return {
      organ_organid: sessionStorage.getItem("organ-organid"),
      tableHeight: 0,
      search:{
        month: "",
        username:"",
        departmentId:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      wagesListData:[],
      exportTip: "",
      departmentlist:[],
      wagesTitle:"",
      dailVisi: false,
      disabled: true,
      wagesFromData: {
        salaryid:"",
        departmentId:"",
        userName:"",
        basicSalary:"",
        rankSalary:"",
        commissionSalary:"",
        yearMonth:"",
        totalSalary:"",
        attendanceDays:"",
        attendanceSalary:"",
        lateNum:"",
        lateDeduct:"",
        personalLeave:"",
        sickLeave:"",
        sickLeaveDeduct:"",
        payableSalary:"",
        pensionRetirement:"",
        pensionMedical:"",
        pensionUnemployment:"",
        companyRetirement:"",
        companyMedical:"",
        companyUnemployment:"",
        companyInjuryBirth:"",
        individualTaxDeduct:"",
        companyHousingFund:"",
        netSalary:"",
      },
      dailVisiProvision:false,
      ProvisionMonth:"",
      rules: {
        departmentId: [{required: true, message: "请选择部门名称", trigger: "blur"}],
        userName: [{required: true, message: "请填写员工姓名", trigger: "blur"}],
        basicSalary: [{required: true, message: "请填写基础工资", trigger: "blur"}],
        yearMonth: [{required: true, message: "请选择所属年月", trigger: "blur"}],
      },
      pdffile:"",
      drawerVisi:false,
    }
  },
  computed:{

    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.month = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadWagesData()
      this.getDepartment()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 120
    },
    getDepartment() {
      this.$axios.get("/api/wages/getdepartment")
          .then((response) => {
            if (response.data.success) {
              this.departmentlist = response.data.result
            } else {
              this.$alert(response.data.result, "获取部门列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取部门列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    loadWagesData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/wages/loadwagesdata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.wagesListData = response.data.result
              this.formatData(this.wagesListData)
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取工资及绩效列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取工资及绩效列表出错")
          })
    },
    getTotalSalary(){
      if(this.wagesFromData.basicSalary !== ""){
        let totalSalary = Number(this.wagesFromData.basicSalary)
        if(this.wagesFromData.rankSalary !== ""){
          totalSalary += Number(this.wagesFromData.rankSalary)
        }
        if(this.wagesFromData.commissionSalary !== ""){
          totalSalary += Number(this.wagesFromData.commissionSalary)
        }
        this.wagesFromData.totalSalary = totalSalary.toString()
        this.getPayableSalary()
      }
    },
    getPayableSalary(){
      if(this.wagesFromData.totalSalary !== ""){
        const payableSalary = Number(this.wagesFromData.totalSalary) + Number(this.wagesFromData.attendanceSalary) - Number(this.wagesFromData.lateDeduct) - Number(this.wagesFromData.sickLeaveDeduct)
        this.wagesFromData.payableSalary = payableSalary.toString()
        this.getNetSalary()
      }
    },
    getNetSalary(){
      if(this.wagesFromData.payableSalary !== ""){
        const netSalary = Number(this.wagesFromData.payableSalary) - Number(this.wagesFromData.pensionRetirement) - Number(this.wagesFromData.pensionMedical) - Number(this.wagesFromData.pensionUnemployment) - Number(this.wagesFromData.individualTaxDeduct)
        this.wagesFromData.netSalary = netSalary.toString()
      }
    },
    formatData(info) {
      let data = JSON.parse(JSON.stringify(info));
      let temp = {};
      let pos = 0
      let BASIC_SALARY = 0
      let RANK_SALARY = 0
      let COMMISSION_SALARY = 0
      let TOTAL_SALARY = 0
      let ATTENDANCE_DAYS = 0
      let ATTENDANCE_SALARY = 0
      let LATE_NUM = 0
      let LATE_DEDUCT = 0
      let SICK_LEAVE = 0
      let SICK_LEAVE_DEDUCT = 0
      let PAYABLE_SALARY = 0
      let PENSION_RETIREMENT = 0
      let PENSION_MEDICAL = 0
      let PENSION_UNEMPLOYMENT = 0
      let COMPANY_RETIREMENT = 0
      let COMPANY_MEDICAL = 0
      let COMPANY_UNEMPLOYMENT = 0
      let COMPANY_INJURY_BIRTH = 0
      let INDIVIDUAL_TAX_DEDUCT = 0
      let PERSONAL_LEAVE = 0
      let NET_SALARY = 0
      let COMPANY_HOUSING_FUND = 0
      for (let i = 0; i < data.length; i++) {
        if(data[i].BASIC_SALARY == null || data[i].BASIC_SALARY === "")
          data[i].BASIC_SALARY = 0
        if(data[i].RANK_SALARY == null || data[i].RANK_SALARY === "")
          data[i].RANK_SALARY = 0
        if(data[i].COMMISSION_SALARY == null || data[i].COMMISSION_SALARY === "")
          data[i].COMMISSION_SALARY = 0
        if(data[i].TOTAL_SALARY == null || data[i].TOTAL_SALARY === "")
          data[i].TOTAL_SALARY = 0
        if(data[i].ATTENDANCE_DAYS == null || data[i].ATTENDANCE_DAYS === "")
          data[i].ATTENDANCE_DAYS = 0
        if(data[i].ATTENDANCE_SALARY == null || data[i].ATTENDANCE_SALARY === "")
          data[i].ATTENDANCE_SALARY = 0
        if(data[i].LATE_NUM == null || data[i].LATE_NUM === "")
          data[i].LATE_NUM = 0
        if(data[i].LATE_DEDUCT == null || data[i].LATE_DEDUCT === "")
          data[i].LATE_DEDUCT = 0
        if(data[i].SICK_LEAVE == null || data[i].SICK_LEAVE === "")
          data[i].SICK_LEAVE = 0
        if(data[i].SICK_LEAVE_DEDUCT == null || data[i].SICK_LEAVE_DEDUCT === "")
          data[i].SICK_LEAVE_DEDUCT = 0
        if(data[i].PAYABLE_SALARY == null || data[i].PAYABLE_SALARY === "")
          data[i].PAYABLE_SALARY = 0
        if(data[i].PENSION_RETIREMENT == null || data[i].PENSION_RETIREMENT === "")
          data[i].PENSION_RETIREMENT = 0
        if(data[i].PENSION_MEDICAL == null || data[i].PENSION_MEDICAL === "")
          data[i].PENSION_MEDICAL = 0
        if(data[i].PENSION_UNEMPLOYMENT == null || data[i].PENSION_UNEMPLOYMENT === "")
          data[i].PENSION_UNEMPLOYMENT = 0

        if(data[i].COMPANY_RETIREMENT == null || data[i].COMPANY_RETIREMENT === "")
          data[i].COMPANY_RETIREMENT = 0
        if(data[i].COMPANY_MEDICAL == null || data[i].COMPANY_MEDICAL === "")
          data[i].COMPANY_MEDICAL = 0
        if(data[i].COMPANY_UNEMPLOYMENT == null || data[i].COMPANY_UNEMPLOYMENT === "")
          data[i].COMPANY_UNEMPLOYMENT = 0
        if(data[i].COMPANY_INJURY_BIRTH == null || data[i].COMPANY_INJURY_BIRTH === "")
          data[i].COMPANY_INJURY_BIRTH = 0
        if(data[i].PERSONAL_LEAVE == null || data[i].PERSONAL_LEAVE === "")
          data[i].PERSONAL_LEAVE = 0
        if(data[i].INDIVIDUAL_TAX_DEDUCT == null || data[i].INDIVIDUAL_TAX_DEDUCT === "")
          data[i].INDIVIDUAL_TAX_DEDUCT = 0

        if(data[i].NET_SALARY == null || data[i].NET_SALARY === "")
          data[i].NET_SALARY = 0
        if(data[i].COMPANY_HOUSING_FUND == null || data[i].COMPANY_HOUSING_FUND === "")
          data[i].COMPANY_HOUSING_FUND = 0
        BASIC_SALARY += parseFloat(data[i].BASIC_SALARY);
        RANK_SALARY += parseFloat(data[i].RANK_SALARY);
        COMMISSION_SALARY += parseFloat(data[i].COMMISSION_SALARY);
        TOTAL_SALARY += parseFloat(data[i].TOTAL_SALARY);
        ATTENDANCE_DAYS += parseFloat(data[i].ATTENDANCE_DAYS);
        ATTENDANCE_SALARY += parseFloat(data[i].ATTENDANCE_SALARY);
        LATE_NUM += parseFloat(data[i].LATE_NUM);
        LATE_DEDUCT += parseFloat(data[i].LATE_DEDUCT);
        SICK_LEAVE += parseFloat(data[i].SICK_LEAVE);
        SICK_LEAVE_DEDUCT += parseFloat(data[i].SICK_LEAVE_DEDUCT);
        PAYABLE_SALARY += parseFloat(data[i].PAYABLE_SALARY);
        PENSION_RETIREMENT += parseFloat(data[i].PENSION_RETIREMENT);
        PENSION_MEDICAL += parseFloat(data[i].PENSION_MEDICAL);
        PENSION_UNEMPLOYMENT += parseFloat(data[i].PENSION_UNEMPLOYMENT);
        COMPANY_RETIREMENT += parseFloat(data[i].COMPANY_RETIREMENT);
        COMPANY_MEDICAL += parseFloat(data[i].COMPANY_MEDICAL);
        COMPANY_UNEMPLOYMENT += parseFloat(data[i].COMPANY_UNEMPLOYMENT);
        COMPANY_INJURY_BIRTH += parseFloat(data[i].COMPANY_INJURY_BIRTH);
        INDIVIDUAL_TAX_DEDUCT += parseFloat(data[i].INDIVIDUAL_TAX_DEDUCT);
        PERSONAL_LEAVE += parseFloat(data[i].PERSONAL_LEAVE);
        NET_SALARY += parseFloat(data[i].NET_SALARY);
        COMPANY_HOUSING_FUND += parseFloat(data[i].COMPANY_HOUSING_FUND);
        if (temp[data[i].DEPARTMENT_ID]) {
          temp[data[i].DEPARTMENT_ID]['BASIC_SALARY'] += parseFloat(data[i].BASIC_SALARY);
          temp[data[i].DEPARTMENT_ID]['RANK_SALARY'] += parseFloat(data[i].RANK_SALARY);
          temp[data[i].DEPARTMENT_ID]['COMMISSION_SALARY'] += parseFloat(data[i].COMMISSION_SALARY);
          temp[data[i].DEPARTMENT_ID]['TOTAL_SALARY'] += parseFloat(data[i].TOTAL_SALARY);
          temp[data[i].DEPARTMENT_ID]['ATTENDANCE_DAYS'] += parseFloat(data[i].ATTENDANCE_DAYS);
          temp[data[i].DEPARTMENT_ID]['ATTENDANCE_SALARY'] += parseFloat(data[i].ATTENDANCE_SALARY);
          temp[data[i].DEPARTMENT_ID]['LATE_NUM'] += parseFloat(data[i].LATE_NUM);
          temp[data[i].DEPARTMENT_ID]['LATE_DEDUCT'] += parseFloat(data[i].LATE_DEDUCT);
          temp[data[i].DEPARTMENT_ID]['SICK_LEAVE'] += parseFloat(data[i].SICK_LEAVE);
          temp[data[i].DEPARTMENT_ID]['SICK_LEAVE_DEDUCT'] += parseFloat(data[i].SICK_LEAVE_DEDUCT);
          temp[data[i].DEPARTMENT_ID]['PAYABLE_SALARY'] += parseFloat(data[i].PAYABLE_SALARY);
          temp[data[i].DEPARTMENT_ID]['PENSION_RETIREMENT'] += parseFloat(data[i].PENSION_RETIREMENT);
          temp[data[i].DEPARTMENT_ID]['PENSION_MEDICAL'] += parseFloat(data[i].PENSION_MEDICAL);
          temp[data[i].DEPARTMENT_ID]['PENSION_UNEMPLOYMENT'] += parseFloat(data[i].PENSION_UNEMPLOYMENT);
          temp[data[i].DEPARTMENT_ID]['COMPANY_RETIREMENT'] += parseFloat(data[i].COMPANY_RETIREMENT);
          temp[data[i].DEPARTMENT_ID]['COMPANY_MEDICAL'] += parseFloat(data[i].COMPANY_MEDICAL);
          temp[data[i].DEPARTMENT_ID]['COMPANY_UNEMPLOYMENT'] += parseFloat(data[i].COMPANY_UNEMPLOYMENT);
          temp[data[i].DEPARTMENT_ID]['COMPANY_INJURY_BIRTH'] += parseFloat(data[i].COMPANY_INJURY_BIRTH);
          temp[data[i].DEPARTMENT_ID]['INDIVIDUAL_TAX_DEDUCT'] += parseFloat(data[i].INDIVIDUAL_TAX_DEDUCT);
          temp[data[i].DEPARTMENT_ID]['PERSONAL_LEAVE'] += parseFloat(data[i].PERSONAL_LEAVE);
          temp[data[i].DEPARTMENT_ID]['NET_SALARY'] += parseFloat(data[i].NET_SALARY);
          temp[data[i].DEPARTMENT_ID]['COMPANY_HOUSING_FUND'] += parseFloat(data[i].COMPANY_HOUSING_FUND);
        } else {
          temp[data[i].DEPARTMENT_ID] = {};
          temp[data[i].DEPARTMENT_ID]['BASIC_SALARY'] = parseFloat(data[i].BASIC_SALARY);
          temp[data[i].DEPARTMENT_ID]['RANK_SALARY'] = parseFloat(data[i].RANK_SALARY);
          temp[data[i].DEPARTMENT_ID]['COMMISSION_SALARY'] = parseFloat(data[i].COMMISSION_SALARY);
          temp[data[i].DEPARTMENT_ID]['TOTAL_SALARY'] = parseFloat(data[i].TOTAL_SALARY);
          temp[data[i].DEPARTMENT_ID]['ATTENDANCE_DAYS'] = parseFloat(data[i].ATTENDANCE_DAYS);
          temp[data[i].DEPARTMENT_ID]['ATTENDANCE_SALARY'] = parseFloat(data[i].ATTENDANCE_SALARY);
          temp[data[i].DEPARTMENT_ID]['LATE_NUM'] = parseFloat(data[i].LATE_NUM);
          temp[data[i].DEPARTMENT_ID]['LATE_DEDUCT'] = parseFloat(data[i].LATE_DEDUCT);
          temp[data[i].DEPARTMENT_ID]['SICK_LEAVE'] = parseFloat(data[i].SICK_LEAVE);
          temp[data[i].DEPARTMENT_ID]['SICK_LEAVE_DEDUCT'] = parseFloat(data[i].SICK_LEAVE_DEDUCT);
          temp[data[i].DEPARTMENT_ID]['PAYABLE_SALARY'] = parseFloat(data[i].PAYABLE_SALARY);
          temp[data[i].DEPARTMENT_ID]['PENSION_RETIREMENT'] = parseFloat(data[i].PENSION_RETIREMENT);
          temp[data[i].DEPARTMENT_ID]['PENSION_MEDICAL'] = parseFloat(data[i].PENSION_MEDICAL);
          temp[data[i].DEPARTMENT_ID]['PENSION_UNEMPLOYMENT'] = parseFloat(data[i].PENSION_UNEMPLOYMENT);
          temp[data[i].DEPARTMENT_ID]['COMPANY_RETIREMENT'] = parseFloat(data[i].COMPANY_RETIREMENT);
          temp[data[i].DEPARTMENT_ID]['COMPANY_MEDICAL'] = parseFloat(data[i].COMPANY_MEDICAL);
          temp[data[i].DEPARTMENT_ID]['COMPANY_UNEMPLOYMENT'] = parseFloat(data[i].COMPANY_UNEMPLOYMENT);
          temp[data[i].DEPARTMENT_ID]['COMPANY_INJURY_BIRTH'] = parseFloat(data[i].COMPANY_INJURY_BIRTH);
          temp[data[i].DEPARTMENT_ID]['INDIVIDUAL_TAX_DEDUCT'] = parseFloat(data[i].INDIVIDUAL_TAX_DEDUCT);
          temp[data[i].DEPARTMENT_ID]['PERSONAL_LEAVE'] = parseFloat(data[i].PERSONAL_LEAVE);
          temp[data[i].DEPARTMENT_ID]['NET_SALARY'] = parseFloat(data[i].NET_SALARY);
          temp[data[i].DEPARTMENT_ID]['COMPANY_HOUSING_FUND'] = parseFloat(data[i].COMPANY_HOUSING_FUND);
        }
        if (i > 0 && data[i].DEPARTMENT_ID !== data[i - 1].DEPARTMENT_ID) {
          info.splice(i+pos, 0, {
            DEPARTMENT: '汇总',
            DEPARTMENT_NAME: data[i - 1].DEPARTMENT_NAME+'汇总',
            BASIC_SALARY: temp[data[i - 1].DEPARTMENT_ID]['BASIC_SALARY'].toFixed(2).replace(/\.00$/,''),
            RANK_SALARY: temp[data[i - 1].DEPARTMENT_ID]['RANK_SALARY'].toFixed(2).replace(/\.00$/,''),
            COMMISSION_SALARY: temp[data[i - 1].DEPARTMENT_ID]['COMMISSION_SALARY'].toFixed(2).replace(/\.00$/,''),
            TOTAL_SALARY: temp[data[i - 1].DEPARTMENT_ID]['TOTAL_SALARY'].toFixed(2).replace(/\.00$/,''),
            ATTENDANCE_DAYS: temp[data[i - 1].DEPARTMENT_ID]['ATTENDANCE_DAYS'].toFixed(2).replace(/\.00$/,''),
            ATTENDANCE_SALARY: temp[data[i - 1].DEPARTMENT_ID]['ATTENDANCE_SALARY'].toFixed(2).replace(/\.00$/,''),
            LATE_NUM: temp[data[i - 1].DEPARTMENT_ID]['LATE_NUM'].toFixed(2).replace(/\.00$/,''),
            LATE_DEDUCT: temp[data[i - 1].DEPARTMENT_ID]['LATE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
            SICK_LEAVE: temp[data[i - 1].DEPARTMENT_ID]['SICK_LEAVE'].toFixed(2).replace(/\.00$/,''),
            SICK_LEAVE_DEDUCT: temp[data[i - 1].DEPARTMENT_ID]['SICK_LEAVE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
            PAYABLE_SALARY: temp[data[i - 1].DEPARTMENT_ID]['PAYABLE_SALARY'].toFixed(2).replace(/\.00$/,''),
            PENSION_RETIREMENT: temp[data[i - 1].DEPARTMENT_ID]['PENSION_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
            PENSION_MEDICAL: temp[data[i - 1].DEPARTMENT_ID]['PENSION_MEDICAL'].toFixed(2).replace(/\.00$/,''),
            PENSION_UNEMPLOYMENT: temp[data[i - 1].DEPARTMENT_ID]['PENSION_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
            COMPANY_RETIREMENT: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
            COMPANY_MEDICAL: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_MEDICAL'].toFixed(2).replace(/\.00$/,''),
            COMPANY_UNEMPLOYMENT: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
            COMPANY_INJURY_BIRTH: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_INJURY_BIRTH'].toFixed(2).replace(/\.00$/,''),
            INDIVIDUAL_TAX_DEDUCT: temp[data[i - 1].DEPARTMENT_ID]['INDIVIDUAL_TAX_DEDUCT'].toFixed(2).replace(/\.00$/,''),
            PERSONAL_LEAVE: temp[data[i - 1].DEPARTMENT_ID]['PERSONAL_LEAVE'].toFixed(2).replace(/\.00$/,''),
            NET_SALARY: temp[data[i - 1].DEPARTMENT_ID]['NET_SALARY'].toFixed(2).replace(/\.00$/,''),
            COMPANY_HOUSING_FUND: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_HOUSING_FUND'].toFixed(2).replace(/\.00$/,''),
          });
          if(i === data.length - 1){
            info.push({
              DEPARTMENT: '汇总',
              DEPARTMENT_NAME: data[i].DEPARTMENT_NAME+'汇总',
              BASIC_SALARY: temp[data[i].DEPARTMENT_ID]['BASIC_SALARY'].toFixed(2).replace(/\.00$/,''),
              RANK_SALARY: temp[data[i].DEPARTMENT_ID]['RANK_SALARY'].toFixed(2).replace(/\.00$/,''),
              COMMISSION_SALARY: temp[data[i].DEPARTMENT_ID]['COMMISSION_SALARY'].toFixed(2).replace(/\.00$/,''),
              TOTAL_SALARY: temp[data[i].DEPARTMENT_ID]['TOTAL_SALARY'].toFixed(2).replace(/\.00$/,''),
              ATTENDANCE_DAYS: temp[data[i].DEPARTMENT_ID]['ATTENDANCE_DAYS'].toFixed(2).replace(/\.00$/,''),
              ATTENDANCE_SALARY: temp[data[i].DEPARTMENT_ID]['ATTENDANCE_SALARY'].toFixed(2).replace(/\.00$/,''),
              LATE_NUM: temp[data[i].DEPARTMENT_ID]['LATE_NUM'].toFixed(2).replace(/\.00$/,''),
              LATE_DEDUCT: temp[data[i].DEPARTMENT_ID]['LATE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              SICK_LEAVE: temp[data[i].DEPARTMENT_ID]['SICK_LEAVE'].toFixed(2).replace(/\.00$/,''),
              SICK_LEAVE_DEDUCT: temp[data[i].DEPARTMENT_ID]['SICK_LEAVE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              PAYABLE_SALARY: temp[data[i].DEPARTMENT_ID]['PAYABLE_SALARY'].toFixed(2).replace(/\.00$/,''),
              PENSION_RETIREMENT: temp[data[i].DEPARTMENT_ID]['PENSION_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
              PENSION_MEDICAL: temp[data[i].DEPARTMENT_ID]['PENSION_MEDICAL'].toFixed(2).replace(/\.00$/,''),
              PENSION_UNEMPLOYMENT: temp[data[i].DEPARTMENT_ID]['PENSION_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_RETIREMENT: temp[data[i].DEPARTMENT_ID]['COMPANY_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_MEDICAL: temp[data[i].DEPARTMENT_ID]['COMPANY_MEDICAL'].toFixed(2).replace(/\.00$/,''),
              COMPANY_UNEMPLOYMENT: temp[data[i].DEPARTMENT_ID]['COMPANY_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_INJURY_BIRTH: temp[data[i].DEPARTMENT_ID]['COMPANY_INJURY_BIRTH'].toFixed(2).replace(/\.00$/,''),
              INDIVIDUAL_TAX_DEDUCT: temp[data[i].DEPARTMENT_ID]['INDIVIDUAL_TAX_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              PERSONAL_LEAVE: temp[data[i].DEPARTMENT_ID]['PERSONAL_LEAVE'].toFixed(2).replace(/\.00$/,''),
              NET_SALARY: temp[data[i].DEPARTMENT_ID]['NET_SALARY'].toFixed(2).replace(/\.00$/,''),
              COMPANY_HOUSING_FUND: temp[data[i].DEPARTMENT_ID]['COMPANY_HOUSING_FUND'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
        }else if (i === data.length - 1) {
          if(i === 0){
            info.push({
              DEPARTMENT: '汇总',
              DEPARTMENT_NAME: data[i].DEPARTMENT_NAME+'汇总',
              BASIC_SALARY: temp[data[i].DEPARTMENT_ID]['BASIC_SALARY'].toFixed(2).replace(/\.00$/,''),
              RANK_SALARY: temp[data[i].DEPARTMENT_ID]['RANK_SALARY'].toFixed(2).replace(/\.00$/,''),
              COMMISSION_SALARY: temp[data[i].DEPARTMENT_ID]['COMMISSION_SALARY'].toFixed(2).replace(/\.00$/,''),
              TOTAL_SALARY: temp[data[i].DEPARTMENT_ID]['TOTAL_SALARY'].toFixed(2).replace(/\.00$/,''),
              ATTENDANCE_DAYS: temp[data[i].DEPARTMENT_ID]['ATTENDANCE_DAYS'].toFixed(2).replace(/\.00$/,''),
              ATTENDANCE_SALARY: temp[data[i].DEPARTMENT_ID]['ATTENDANCE_SALARY'].toFixed(2).replace(/\.00$/,''),
              LATE_NUM: temp[data[i].DEPARTMENT_ID]['LATE_NUM'].toFixed(2).replace(/\.00$/,''),
              LATE_DEDUCT: temp[data[i].DEPARTMENT_ID]['LATE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              SICK_LEAVE: temp[data[i].DEPARTMENT_ID]['SICK_LEAVE'].toFixed(2).replace(/\.00$/,''),
              SICK_LEAVE_DEDUCT: temp[data[i].DEPARTMENT_ID]['SICK_LEAVE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              PAYABLE_SALARY: temp[data[i].DEPARTMENT_ID]['PAYABLE_SALARY'].toFixed(2).replace(/\.00$/,''),
              PENSION_RETIREMENT: temp[data[i].DEPARTMENT_ID]['PENSION_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
              PENSION_MEDICAL: temp[data[i].DEPARTMENT_ID]['PENSION_MEDICAL'].toFixed(2).replace(/\.00$/,''),
              PENSION_UNEMPLOYMENT: temp[data[i].DEPARTMENT_ID]['PENSION_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_RETIREMENT: temp[data[i].DEPARTMENT_ID]['COMPANY_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_MEDICAL: temp[data[i].DEPARTMENT_ID]['COMPANY_MEDICAL'].toFixed(2).replace(/\.00$/,''),
              COMPANY_UNEMPLOYMENT: temp[data[i].DEPARTMENT_ID]['COMPANY_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_INJURY_BIRTH: temp[data[i].DEPARTMENT_ID]['COMPANY_INJURY_BIRTH'].toFixed(2).replace(/\.00$/,''),
              INDIVIDUAL_TAX_DEDUCT: temp[data[i].DEPARTMENT_ID]['INDIVIDUAL_TAX_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              PERSONAL_LEAVE: temp[data[i].DEPARTMENT_ID]['PERSONAL_LEAVE'].toFixed(2).replace(/\.00$/,''),
              NET_SALARY: temp[data[i].DEPARTMENT_ID]['NET_SALARY'].toFixed(2).replace(/\.00$/,''),
              COMPANY_HOUSING_FUND: temp[data[i].DEPARTMENT_ID]['COMPANY_HOUSING_FUND'].toFixed(2).replace(/\.00$/,''),
            });
          }else{
            info.push({
              DEPARTMENT: '汇总',
              DEPARTMENT_NAME: data[i - 1].DEPARTMENT_NAME+'汇总',
              BASIC_SALARY: temp[data[i - 1].DEPARTMENT_ID]['BASIC_SALARY'].toFixed(2).replace(/\.00$/,''),
              RANK_SALARY: temp[data[i - 1].DEPARTMENT_ID]['RANK_SALARY'].toFixed(2).replace(/\.00$/,''),
              COMMISSION_SALARY: temp[data[i - 1].DEPARTMENT_ID]['COMMISSION_SALARY'].toFixed(2).replace(/\.00$/,''),
              TOTAL_SALARY: temp[data[i - 1].DEPARTMENT_ID]['TOTAL_SALARY'].toFixed(2).replace(/\.00$/,''),
              ATTENDANCE_DAYS: temp[data[i - 1].DEPARTMENT_ID]['ATTENDANCE_DAYS'].toFixed(2).replace(/\.00$/,''),
              ATTENDANCE_SALARY: temp[data[i - 1].DEPARTMENT_ID]['ATTENDANCE_SALARY'].toFixed(2).replace(/\.00$/,''),
              LATE_NUM: temp[data[i - 1].DEPARTMENT_ID]['LATE_NUM'].toFixed(2).replace(/\.00$/,''),
              LATE_DEDUCT: temp[data[i - 1].DEPARTMENT_ID]['LATE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              SICK_LEAVE: temp[data[i - 1].DEPARTMENT_ID]['SICK_LEAVE'].toFixed(2).replace(/\.00$/,''),
              SICK_LEAVE_DEDUCT: temp[data[i - 1].DEPARTMENT_ID]['SICK_LEAVE_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              PAYABLE_SALARY: temp[data[i - 1].DEPARTMENT_ID]['PAYABLE_SALARY'].toFixed(2).replace(/\.00$/,''),
              PENSION_RETIREMENT: temp[data[i - 1].DEPARTMENT_ID]['PENSION_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
              PENSION_MEDICAL: temp[data[i - 1].DEPARTMENT_ID]['PENSION_MEDICAL'].toFixed(2).replace(/\.00$/,''),
              PENSION_UNEMPLOYMENT: temp[data[i - 1].DEPARTMENT_ID]['PENSION_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_RETIREMENT: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_RETIREMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_MEDICAL: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_MEDICAL'].toFixed(2).replace(/\.00$/,''),
              COMPANY_UNEMPLOYMENT: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_UNEMPLOYMENT'].toFixed(2).replace(/\.00$/,''),
              COMPANY_INJURY_BIRTH: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_INJURY_BIRTH'].toFixed(2).replace(/\.00$/,''),
              INDIVIDUAL_TAX_DEDUCT: temp[data[i - 1].DEPARTMENT_ID]['INDIVIDUAL_TAX_DEDUCT'].toFixed(2).replace(/\.00$/,''),
              PERSONAL_LEAVE: temp[data[i - 1].DEPARTMENT_ID]['PERSONAL_LEAVE'].toFixed(2).replace(/\.00$/,''),
              NET_SALARY: temp[data[i - 1].DEPARTMENT_ID]['NET_SALARY'].toFixed(2).replace(/\.00$/,''),
              COMPANY_HOUSING_FUND: temp[data[i - 1].DEPARTMENT_ID]['COMPANY_HOUSING_FUND'].toFixed(2).replace(/\.00$/,''),
            });
          }
          pos++
        }
      }
      info.push({
        DEPARTMENT: '汇总',
        DEPARTMENT_NAME: '总计',
        BASIC_SALARY: BASIC_SALARY,
        RANK_SALARY: RANK_SALARY,
        COMMISSION_SALARY: COMMISSION_SALARY,
        TOTAL_SALARY: TOTAL_SALARY,
        ATTENDANCE_DAYS: ATTENDANCE_DAYS,
        ATTENDANCE_SALARY: ATTENDANCE_SALARY,
        LATE_NUM: LATE_NUM,
        LATE_DEDUCT: LATE_DEDUCT,
        SICK_LEAVE: SICK_LEAVE,
        SICK_LEAVE_DEDUCT: SICK_LEAVE_DEDUCT,
        PAYABLE_SALARY: PAYABLE_SALARY,
        PENSION_RETIREMENT: PENSION_RETIREMENT,
        PENSION_MEDICAL: PENSION_MEDICAL,
        PENSION_UNEMPLOYMENT: PENSION_UNEMPLOYMENT,
        COMPANY_RETIREMENT: COMPANY_RETIREMENT,
        COMPANY_MEDICAL: COMPANY_MEDICAL,
        COMPANY_UNEMPLOYMENT: COMPANY_UNEMPLOYMENT,
        COMPANY_INJURY_BIRTH: COMPANY_INJURY_BIRTH,
        INDIVIDUAL_TAX_DEDUCT: INDIVIDUAL_TAX_DEDUCT,
        PERSONAL_LEAVE: PERSONAL_LEAVE,
        NET_SALARY: NET_SALARY,
        COMPANY_HOUSING_FUND: COMPANY_HOUSING_FUND,
      });
    },
    cellStyleMethod({ row, columnIndex }) {
      if (row.DEPARTMENT === '汇总') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }
      if(columnIndex === 0 ){
        return { 'background':'#f5f7fa'};
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.DEPARTMENT_ID=== arr[index - 1].DEPARTMENT_ID) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.DEPARTMENT === '汇总') {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.wagesListData)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },
    toRefresh() {
      this.search.username = ""
      this.search.departmentId = ""
      this.loadWagesData("refresh")
    },
    toAddEagesData (){
      this.wagesTitle = "新增工资及绩效"
      this.dailVisi = true
      this.disabled = true
      this.wagesFromData.salaryid = ""
      this.wagesFromData.departmentId = ""
      this.wagesFromData.userName = ""
      this.wagesFromData.basicSalary = ""
      this.wagesFromData.rankSalary = ""
      this.wagesFromData.commissionSalary = ""
      this.wagesFromData.yearMonth = this.getNowTime+""
      this.wagesFromData.totalSalary = ""
      this.wagesFromData.attendanceDays = ""
      this.wagesFromData.attendanceSalary = ""
      this.wagesFromData.lateNum = ""
      this.wagesFromData.lateDeduct = ""
      this.wagesFromData.personalLeave = ""
      this.wagesFromData.sickLeave = ""
      this.wagesFromData.sickLeaveDeduct = ""
      this.wagesFromData.payableSalary = ""
      this.wagesFromData.pensionRetirement = ""
      this.wagesFromData.pensionMedical = ""
      this.wagesFromData.pensionUnemployment = ""
      this.wagesFromData.companyRetirement = ""
      this.wagesFromData.companyMedical = ""
      this.wagesFromData.companyUnemployment = ""
      this.wagesFromData.companyInjuryBirth = ""
      this.wagesFromData.individualTaxDeduct = ""
      this.wagesFromData.companyHousingFund = ""
      this.wagesFromData.netSalary = ""
      if (this.$refs.wagesFrom)
        this.$refs.wagesFrom.clearValidate()
    },
    // getUserforDepartment(){
    //   this.disabled = false
    //   this.$axios.post("/api/wages/getuserfordepartment",{"departmentId":this.wagesFromData.departmentId})
    //       .then((response) => {
    //         if (response.data.success) {
    //           this.userlist = response.data.result
    //         } else {
    //           this.$alert(response.data.result, "获取对应部门员工出错")
    //         }
    //       })
    //       .catch((error) => {
    //         this.$alert(error, "获取对应部门员工出错")
    //       })
    // },
    toSave() {
      this.$refs["wagesFrom"].validate((valid) => {
        if (valid) {
          this.$axios.post("/api/wages/savewagesdata", this.wagesFromData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "保存工资绩效数据成功", type: "success"})
                  this.dailVisi = false
                  this.loadWagesData()
                } else {
                  this.$alert(response.data.result, "保存工资绩效数据出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "保存工资绩效数据出错")
              })
        }
        else
          return false
      })
    },

    toEdit(data) {
      this.wagesTitle = "编辑工资及绩效"
      this.dailVisi = true
      this.disabled = false
      this.wagesFromData.salaryid = data.SALARY_ID
      this.wagesFromData.departmentId = data.DEPARTMENT_ID
      this.wagesFromData.userName = data.USER_NAME
      this.wagesFromData.basicSalary = data.BASIC_SALARY
      this.wagesFromData.rankSalary = data.RANK_SALARY
      this.wagesFromData.commissionSalary = data.COMMISSION_SALARY
      this.wagesFromData.yearMonth = data.YEARMONTH
      this.wagesFromData.totalSalary = data.TOTAL_SALARY
      this.wagesFromData.attendanceDays = data.ATTENDANCE_DAYS
      this.wagesFromData.attendanceSalary = data.ATTENDANCE_SALARY
      this.wagesFromData.lateNum = data.LATE_NUM
      this.wagesFromData.lateDeduct = data.LATE_DEDUCT
      this.wagesFromData.personalLeave = data.PERSONAL_LEAVE
      this.wagesFromData.sickLeave = data.SICK_LEAVE
      this.wagesFromData.sickLeaveDeduct = data.SICK_LEAVE_DEDUCT
      this.wagesFromData.payableSalary = data.PAYABLE_SALARY
      this.wagesFromData.pensionRetirement = data.PENSION_RETIREMENT
      this.wagesFromData.pensionMedical = data.PENSION_MEDICAL
      this.wagesFromData.pensionUnemployment = data.PENSION_UNEMPLOYMENT
      this.wagesFromData.companyRetirement = data.COMPANY_RETIREMENT
      this.wagesFromData.companyMedical = data.COMPANY_MEDICAL
      this.wagesFromData.companyUnemployment = data.COMPANY_UNEMPLOYMENT
      this.wagesFromData.companyInjuryBirth = data.COMPANY_INJURY_BIRTH
      this.wagesFromData.individualTaxDeduct = data.INDIVIDUAL_TAX_DEDUCT
      this.wagesFromData.companyHousingFund = data.COMPANY_HOUSING_FUND
      this.wagesFromData.netSalary = data.NET_SALARY
      if (this.$refs.wagesFrom)
        this.$refs.wagesFrom.clearValidate()
    },
    toDel(data) {
      this.$confirm("确认删除此工资及绩效吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/wages/delwagesdata", {salaryId: data.SALARY_ID})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "删除成功", type: "success"})
                this.loadWagesData()
              }
              else {
                this.$alert(response.data.result, "删除出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "删除出错")
            })
      })
    },
    //导出
    doExport () {
      this.exportTip = "工资数据导出Excel中..."
      this.$axios.post("/api/wages/exportwagesdata", {data: this.wagesListData, recordMonth:this.search.month}, {
        responseType: "blob"
      })
          .then((response) => {
            this.exportTip = ""
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "工资数据导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "工资数据导出出错")
          })
    },
    showProvision(){
      this.dailVisiProvision =true
    },
    toProvision(){
      if(this.ProvisionMonth !== ""){
        this.$axios.post("/api/wages/wagesProvision", {"provisionMonth":this.ProvisionMonth})
            .then((response) => {
              if (response.data.success) {
                this.dailVisiProvision =false
                this.$message({message: "工资计提成功", type: "success"})
              }
              else {
                this.$alert(response.data.result, "工资计提出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "工资计提出错")
            })
      }
    },
    // 结转
    doCarryForward(){
      this.$axios.post("/api/wages/carryForward", this.search)
          .then((response) => {
            if (response.data.success) {
              this.$message({message: "工资结转成功", type: "success"})
            }
            else {
              this.$alert(response.data.result, "工资结转出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "工资结转出错")
          })
    },
    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.pdffile = apiURL+"/api/wages/printwagesdata?month="+this.search.month +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid
    },
  }
}
</script>

<style scoped>

</style>
