<template>
  <div style="text-align: right;">
    <el-popover placement="left" title="列筛选" trigger="click" >
      <el-checkbox-group v-model="checkList" @change="filter" style="max-width:1500px;">
        <el-checkbox
            v-for="(item, index) in tableList"
            :key="index"
            :label="item.value"
        ></el-checkbox>
      </el-checkbox-group>
      <template #reference>
        <el-icon size="16" color="#606266"><Filter /></el-icon>
      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "TableScreen",
  data() {
    return {
      tableList: [],
      checkList: [],
      result: [],
      list: [],
    };
  },
  props:{
    datas:{

    }
  },
  created(){
    console.log(this.datas)
    this.tableList = this.datas
  },
  mounted(){
    this.tableList.forEach((item)=>{
      this.checkList.push(item.value)
    })
  },
  methods:{
    filter(val) {
      this.tableList.forEach((item)=>{
        this.list.push(item.value)
      })
      this.result = this.list.filter(number => !val.includes(number))
      if(this.result.length>=1){
        this.result.forEach((item)=>{
          this.tableList.forEach((items)=>{
            if(items.value === item){
              items.isTrue = false
            }
          })
        })
      }
      val.forEach((item)=>{
        this.tableList.forEach((items)=>{
          if(items.value === item){
            items.isTrue = true
          }
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
